import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import { URL_PREFIX } from 'config';

// render - sample page
const OverviewPage = Loadable(lazy(() => import('pages/overview')));
const Error404 = Loadable(lazy(() => import('pages/error-404')));

// render - collaborate pages
const FindJobsPage = Loadable(lazy(() => import('pages/collaborate/find-jobs')));
const FindCandidatesPage = Loadable(lazy(() => import('pages/collaborate/find-candidates')));

// render - manage pages
const JobBoardPage = Loadable(lazy(() => import('pages/manage/job-board')));
const ReviewApplicantsPage = Loadable(lazy(() => import('pages/manage/review-applicants')));
const ApplicationsPage = Loadable(lazy(() => import('pages/manage/applications')));
const EmployeesPage = Loadable(lazy(() => import('pages/manage/employees')));
const MatchesPage = Loadable(lazy(() => import('pages/manage/matches')));
const ProjectsPage = Loadable(lazy(() => import('pages/manage/projects')));
const DocumentsPage = Loadable(lazy(() => import('pages/manage/documents')));

// render - other pages
const ToolsPage = Loadable(lazy(() => import('pages/other/tools')));
const FavouritesPage = Loadable(lazy(() => import('pages/other/favourites')));

// render - help/support page
const HelpPage = Loadable(lazy(() => import('pages/help')));

// render - account-settings page
const AccountSettingsPage = Loadable(lazy(() => import('pages/account-settings')));

// render - notifications page
const NotificationsPage = Loadable(lazy(() => import('pages/notifications')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: `${URL_PREFIX}/`,
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: `${URL_PREFIX}/`,
      element: <Error404 />
    },
    { path: '*', element: <Error404 /> },
    {
      path: `cv-build/m/:id`,
      element: <OverviewPage />
    },
    {
      path: `jd-build/m/:id`,
      element: <OverviewPage />
    },
    {
      path: 'help',
      element: <HelpPage />
    },
    {
      path: 'account-settings',
      element: <AccountSettingsPage />
    },
    {
      path: 'notifications',
      element: <NotificationsPage />
    },
    {
      path: 'overview',
      element: <OverviewPage />
    },
    {
      path: 'find-jobs',
      element: <FindJobsPage />
    },
    {
      path: 'find-candidates',
      element: <FindCandidatesPage />
    },
    {
      path: 'jobboard',
      element: <JobBoardPage />
    },
    {
      path: 'applicants',
      element: <ReviewApplicantsPage />
    },
    {
      path: 'employees',
      element: <EmployeesPage />
    },
    {
      path: 'employees/applications',
      element: <ApplicationsPage />
    },
    {
      path: 'employees/matches',
      element: <MatchesPage />
    },
    {
      path: 'employees/projects',
      element: <ProjectsPage />
    },
    {
      path: 'documents',
      element: <DocumentsPage />
    },
    {
      path: 'tools',
      element: <ToolsPage />
    },
    {
      path: 'favourites',
      element: <FavouritesPage />
    }
  ]
};

export default MainRoutes;
