// material-ui
import { useTheme } from '@mui/material/styles';
import { createFilterOptions, Autocomplete, Box, Button, Grid, InputAdornment, Stack, TextField } from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import { editQualification } from 'store/reducers/cv-builder';
import { useDispatch, useSelector } from 'store';
import { addDegree } from 'store/reducers/master';
import trimFc from 'utils/trimFc';

// assets
import { ArrowDown2, Calendar } from 'iconsax-react';

// types
import { CVQualificationProps } from 'types/cv-builder';

// // @ts-ignore
// const diffMonths = (d1: Date, d2) => {
//   d1 = new Date(d1);
//   d2 = new Date(d2);

//   var months;
//   months = (d2.getFullYear() - d1.getFullYear()) * 12;
//   months -= d1.getMonth();
//   months += d2.getMonth();
//   return months <= 0 ? 0 : months;
// };

const filter = createFilterOptions<string>();

const validationSchema = yup.object({
  degree: yup
    .string()
    .trim()
    .required('The degree is required')
    .max(50, 'The degree description can not exceed the length of 50 characters'),
  institute: yup
    .string()
    .trim()
    .matches(
      /^[ A-Za-zäöüÄÖÜß0-9_'"/)(?&-.]*$/,
      `Only alphanumeric characters (also ÄäÖöÜüß) and special characters such as (_'"/)(?&-.) are allowed.`
    )
    .max(50, 'The length of the Institute can not exceed 50 characters')
    .required('The Insitute Name is required'),
  endDate: yup.date().required('The completion date is required')
});

type Props = {
  toggleQualification: () => void;
  qualification: CVQualificationProps;
};

const AddQualification = ({ toggleQualification, qualification }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { degrees } = useSelector((state) => state.master);
  const { id } = useSelector((state) => state.cv);

  const formik = useFormik({
    initialValues: {
      id: qualification.id,
      degree: qualification.degree,
      institute: qualification.institute,
      startDate: qualification.endDate,
      endDate: qualification.endDate
      // submit: null
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const castValues = validationSchema.cast(values);
      const formData: CVQualificationProps = {
        id: values.id,
        degree: castValues.degree!,
        institute: castValues.institute!,
        startDate: values.endDate,
        endDate: values.endDate
      };

      !formData.id && delete formData.id;
      dispatch(editQualification({ qualification: formData, id }));
      setSubmitting(false);
      resetForm();
      if (id) {
        toggleQualification();
      }
    }
  });

  let startMaxDate = new Date();
  // startMaxDate.setMonth(startMaxDate.getMonth() - 1);

  // let endMaxDate = new Date();

  const handleAddDegree = (jobName: string) => {
    dispatch(addDegree(jobName));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={formik.handleSubmit} id="validation-forms">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={formik.values.degree}
              disableClearable
              onChange={(event, newValue) => {
                const jobExist = degrees.includes(newValue);
                if (!jobExist) {
                  const matchData = newValue.match(/"((?:\\.|[^"\\])*)"/);
                  formik.setFieldValue('degree', matchData && matchData[1]);
                  if (matchData && matchData[1]) handleAddDegree(matchData[1]);
                } else {
                  formik.setFieldValue('degree', newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                  filtered.push(`Add "${inputValue}"`);
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              autoHighlight
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={degrees}
              getOptionLabel={(option) => {
                let value = option;
                const jobExist = degrees.includes(option);
                if (!jobExist) {
                  const matchData = option.match(/"((?:\\.|[^"\\])*)"/);
                  if (matchData && matchData[1]) value = matchData && matchData[1];
                }
                return value;
              }}
              renderOption={(props, option) => {
                return (
                  <Box component="li" {...props}>
                    {option}
                  </Box>
                );
              }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="degree"
                  error={formik.touched.degree && Boolean(formik.errors.degree)}
                  helperText={formik.touched.degree && formik.errors.degree && formik.errors.degree}
                  placeholder="Select Degree"
                  InputProps={{
                    ...params.InputProps,
                    sx: { bgcolor: 'grey.0' },
                    endAdornment: (
                      <InputAdornment style={{ pointerEvents: 'none', position: 'absolute', right: '10px' }} position="end">
                        <ArrowDown2 size={16} color={theme.palette.secondary.main} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="institute"
              name="institute"
              placeholder="Institute Name"
              value={formik.values.institute}
              onChange={trimFc(formik)}
              onBlur={formik.handleBlur}
              error={formik.touched.institute && Boolean(formik.errors.institute)}
              helperText={formik.touched.institute && formik.errors.institute}
              fullWidth
              InputProps={{
                sx: { bgcolor: 'grey.0' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container rowSpacing={{ xs: 2.5, sm: 4 }} columnSpacing={3}>
              {/* <Grid item xs={12} md={6}>
                <MobileDateTimePicker
                  value={formik.values.startDate}
                  inputFormat="MM/yyyy"
                  views={['year', 'month']}
                  maxDate={startMaxDate}
                  defaultCalendarMonth={startMaxDate}
                  onChange={(date) => formik.setFieldValue('startDate', date)}
                  showToolbar={false}
                  DialogProps={{
                    sx: { '& .MuiPaper-root': { p: 1.25 } }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="cal-start-date"
                      name="startDate"
                      placeholder="Completion Date"
                      fullWidth
                      error={Boolean(formik.touched.startDate && formik.errors.startDate)}
                      helperText={formik.touched.startDate && formik.errors.startDate}
                      InputProps={{
                        sx: { bgcolor: 'grey.0' },
                        endAdornment: (
                          <InputAdornment position="end">
                            <Calendar size={16} color={theme.palette.secondary.main} />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid> */}
              <Grid item xs={12}>
                <MobileDateTimePicker
                  value={formik.values.endDate}
                  inputFormat="MM/yyyy"
                  views={['year', 'month']}
                  onChange={(date) => formik.setFieldValue('endDate', date)}
                  showToolbar={false}
                  maxDate={startMaxDate}
                  defaultCalendarMonth={startMaxDate}
                  DialogProps={{
                    sx: { '& .MuiPaper-root': { p: 1.25 } }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="cal-end-date"
                      name="endDate"
                      placeholder="Completion Date"
                      fullWidth
                      error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                      helperText={formik.touched.endDate && formik.errors.endDate}
                      InputProps={{
                        sx: { bgcolor: 'grey.0' },
                        endAdornment: (
                          <InputAdornment style={{ pointerEvents: 'none', position: 'absolute', right: '10px' }} position="end">
                            <Calendar size={16} color={theme.palette.secondary.main} />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between">
              <Button sx={{ mr: 2 }} size="small" color="error" onClick={() => formik.resetForm()}>
                Reset
              </Button>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  variant="dashed"
                  size="small"
                  color="error"
                  onClick={() => {
                    formik.resetForm();
                    toggleQualification();
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" size="small" disableElevation disabled={formik.isSubmitting} type="submit">
                  Submit
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  );
};

export default AddQualification;
