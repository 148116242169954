import * as React from 'react';

// third-party
import { getGeocode } from 'use-places-autocomplete';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Box, Grid, InputAdornment, TextField, Typography } from '@mui/material';

// project import
import { GOOGLE_MAPS_API_KEY } from 'config';

// assets
import { ArrowDown2, Location } from 'iconsax-react';

function loadScript(src: string, position: HTMLElement | null, id: string) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}
interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
  description: string;
  structured_formatting?: StructuredFormatting;
}

const GoogleMaps = ({ formik }: any) => {
  const theme = useTheme();

  const [value, setValue] = React.useState<PlaceType | null>(formik.values.location);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request: { input: string }, callback: (results?: readonly PlaceType[]) => void) => {
        (autocompleteService.current as any).getPlacePredictions({ ...request, types: ['country', 'postal_town', 'locality'] }, callback); // componentRestrictions: { country: 'uk' },
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (window as any).google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      noOptionsText={'Please select a city or a country from the options provided…'}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      disableClearable
      fullWidth
      autoHighlight
      includeInputInList
      filterSelectedOptions
      value={value!}
      onChange={(event: any, newValue: PlaceType | null) => {
        setOptions(newValue ? [newValue, ...options] : options);
        // setValue(newValue);
        getGeocode({ address: newValue?.description }).then((results: any) => {
          results[0].address_components.reverse().filter((locData: any) => {
            if (locData.types[0] === 'postal_town' || locData.types[0] === 'locality' || locData.types[0] === 'country') {
              if (locData.long_name !== undefined) {
                setValue(locData.long_name);
                formik.setFieldValue('location', locData.long_name);
              }
            }
            return false;
          });
        });
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name="location"
          error={formik.touched.location && Boolean(formik.errors.location)}
          helperText={formik.touched.location && formik.errors.location && formik.errors.location}
          placeholder="Search location"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <InputAdornment position="end" sx={{ mr: -3.5 }}>
                <ArrowDown2 size={16} color={theme.palette.secondary.main} />
              </InputAdornment>
            )
          }}
        />
      )}
      renderOption={(props, option) => {
        const matches = option.structured_formatting!.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting!.main_text,
          matches.map((match: any) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box component={Location} size={14} sx={{ color: 'text.secondary', mr: 1, mt: 0.5 }} />
              </Grid>
              <Grid item xs>
                {parts.map((part: any, index: number) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400
                    }}
                  >
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="text.secondary">
                  {option?.structured_formatting?.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
};

export default GoogleMaps;
