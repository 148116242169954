// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - TAB ||============================== //

export default function Tab(theme: Theme) {
  return {
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 46,
          color: theme.palette.primary[300],
          borderRadius: 4,
          '&.Mui-selected': {
            color: theme.palette.primary[600]
          },
          '&:hover': {
            backgroundColor: theme.palette.primary.lighter + 60,
            color: theme.palette.primary[400]
          },
          '&:focus-visible': {
            borderRadius: 4,
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: -3
          }
        }
      }
    }
  };
}
