// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { Axios } from 'utils/axios';
import { dispatch } from '../index';
import { BE_URL } from '../../config';

//types
import { UserProps, UserData, CompanyData } from 'types/user';
import { GetStartedDataProps } from 'sections/auth/auth-forms/register/GetStarted';

const initialState: UserProps = {
  user: null,
  error: null,
  company: null,
  feedbackSend: false,
  sessionLogin: false,
  companyDataSend: false
};

// ==============================|| SLICE - user-signup ||============================== //

const user = createSlice({
  name: 'userSignup',
  initialState,
  reducers: {
    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    setUser(state, action) {
      const user = action.payload;
      user.avatar = { name: '', url: user.avatar };
      state.user = user;
    },

    setCompany(state, action) {
      state.company = action.payload;
    },

    setUserSessionLogin(state, action) {
      state.sessionLogin = action.payload;
    },

    setUserFeedbackSend(state, action) {
      state.feedbackSend = action.payload;
    },
    setUserCompanyDataSend(state, action) {
      state.companyDataSend = action.payload.success;
      state.user = action.payload.data;
      state.company = action.payload.data;
    }
  }
});

export default user.reducer;

export function getSignUpUser() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/users/me/?signup=1`);
      response.data.success && dispatch(user.actions.setUser(response.data.data));
      dispatch(user.actions.hasError(null));
      return response.data.data;
    } catch (error) {}
  };
}
export function getSignUpUserCompany() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/users/me/company/?signup=1`);
      response.data.success && dispatch(user.actions.setCompany(response.data.data));
      dispatch(user.actions.hasError(null));

      return response.data.data;
    } catch (error) {
      // dispatch(user.actions.hasError(error));
    }
  };
}

export function userSessionLogin(token: string) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/users/sessionLogin`, { idToken: token });
      response.data.success && dispatch(user.actions.setUserSessionLogin(response.data.success));
      dispatch(user.actions.hasError(null));

      return response.data.success;
    } catch (error) {}
  };
}

export function createSignUpUser(userData: UserData) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/users/?signup=1`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        avatar: userData.avatar?.base64
      });
      response.data.success && dispatch(user.actions.setUser(response.data.data));
      dispatch(user.actions.hasError(null));

      return response.data;
    } catch (error) {
      dispatch(user.actions.hasError(error));
    }
  };
}
export function updateSignUpUser(userData: UserData) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/users/update/?signup=1`, {
        firstName: userData.firstName,
        lastName: userData.lastName,
        avatar: userData.avatar?.base64
      });
      response.data.success && dispatch(user.actions.setUser(response.data.data));
      dispatch(user.actions.hasError(null));

      return response.data;
    } catch (error) {
      dispatch(user.actions.hasError(error));
    }
  };
}

export function userSignUpFeedback(feedbackData: GetStartedDataProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/users/feedback/?signup=1`, feedbackData);
      response.data.success && dispatch(user.actions.setUserFeedbackSend(response.data.success));
      dispatch(user.actions.hasError(null));
    } catch (error) {
      dispatch(user.actions.hasError(error));
    }
  };
}

export function updateUserSignUpCompany(company: CompanyData) {
  return async () => {
    try {
      const data: any = company;
      delete data.files;
      const response = await Axios.post(`${BE_URL}/users/company/?signup=1`, data);
      response.data.success && dispatch(user.actions.setUserCompanyDataSend(response.data));
      dispatch(user.actions.hasError(null));
    } catch (error) {}
  };
}
