import { lazy } from 'react';

// project import
import GuestGuard from 'utils/route-guard/GuestGuard';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import { FORGOT_URL, LOGIN_URL, SIGNUP_URL, URL_PREFIX } from 'config';

// render - login
const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));

// render waiting list page
const AuthWaiting = Loadable(lazy(() => import('pages/auth/waiting-list')));
const Error404 = Loadable(lazy(() => import('pages/front-404')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: (
    <GuestGuard>
      <CommonLayout />
    </GuestGuard>
  ),
  children: [
    { path: '*', element: <Error404 /> },
    {
      path: '/',
      element: <AuthLogin />
    },
    {
      path: SIGNUP_URL,
      element: <AuthRegister />
    },
    {
      path: 'waitinglist',
      element: <AuthWaiting />
    },
    {
      path: URL_PREFIX,
      children: [
        {
          path: '*',
          element: <Error404 />
        },
        {
          path: LOGIN_URL,
          element: <AuthLogin />
        },
        {
          path: FORGOT_URL,
          element: <AuthForgotPassword />
        },
        {
          path: `reset-password`,
          element: <AuthResetPassword />
        }
      ]
    }
  ]
};

export default LoginRoutes;
