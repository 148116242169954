import { useState, forwardRef } from 'react';
import { useLocation } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Fab, Grid, Dialog, DialogContent, Slide, SlideProps, Typography, Stack, TextField, FormHelperText, Box } from '@mui/material';
import { Add, Dislike, Like1, Message2, Send2 } from 'iconsax-react';
import IconButton from 'components/@extended/IconButton';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project import
import { BE_URL } from 'config';
import { dispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import trimFc from 'utils/trimFc';
import { Axios } from 'utils/axios';

const validationSchema = yup.object({
  message: yup.string().trim().max(500, 'Feedback must be at most 500 characters').required('Feedback message is required.')
});

// animation
const Transition = forwardRef((props: SlideProps, ref) => <Slide direction="left" ref={ref} {...props} />);

// ==============================|| LIVE CUSTOMIZATION ||============================== //

const LeaveFeedback = () => {
  const theme = useTheme();
  const { pathname } = useLocation();

  // drawer on/off
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const formik = useFormik({
    initialValues: {
      message: '',
      isLike: null
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      const castValues = validationSchema.cast(values);
      await Axios.post(`${BE_URL}/users/feedback`, {
        rating: values.isLike ? 5 : 0,
        review: `${pathname} - ${castValues.message}`
      }).then((response) => {
        if (response.data && response.data.success) {
          dispatch(
            openSnackbar({
              open: true,
              message: 'Feedback submitted successfully.',
              variant: 'alert',
              alert: {
                color: 'success'
              },
              close: false
            })
          );
          resetForm();
          handleToggle();
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: 'Server Error, try again',
              variant: 'alert',
              alert: {
                color: 'error'
              },
              close: false
            })
          );
        }
      });
      setSubmitting(false);
    }
  });

  return (
    <>
      {/* toggle button */}
      <Fab
        component="div"
        onClick={handleToggle}
        size="large"
        variant="extended"
        color="warning"
        sx={{
          fontSize: '14px',
          fontWeight: 500,
          letterSpacing: '1.2px',
          borderRadius: 0,
          borderTopRightRadius: '16px',
          borderTopLeftRadius: '16px',
          top: '50%',
          position: 'fixed',
          right: -40,
          zIndex: 1301,
          boxShadow: theme.customShadows.secondary,
          transform: 'rotate(-90deg)'
        }}
      >
        <Message2 style={{ marginRight: 8 }} /> Feedback
      </Fab>

      <Dialog
        open={open}
        onClose={handleToggle}
        TransitionComponent={Transition}
        keepMounted
        sx={{
          zIndex: 1302,
          '& .MuiDialog-container': {
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            '& .MuiPaper-root': {
              mr: 0,
              borderRadius: '12px 0px 0px 12px',
              maxWidth: 328,
              bgcolor: 'warning.lighter',
              overflowY: 'visible'
            }
          }
        }}
      >
        <DialogContent sx={{ p: 3, overflowY: 'visible' }}>
          <form onSubmit={formik.handleSubmit} id="validation-forms">
            <Grid container spacing={4.25}>
              <Grid item xs={12}>
                <Stack spacing={1.75}>
                  <Typography variant="body2">How are you finding this page?</Typography>
                  <Stack direction="row" spacing={1.5} alignItems="center" justifyContent="flex-start">
                    <IconButton
                      variant={formik.values.isLike ? 'contained' : 'outlined'}
                      size="large"
                      color={formik.values.isLike ? 'warning' : 'secondary'}
                      sx={{ ...(formik.values.isLike !== true && { bgcolor: '#fff' }) }}
                      onClick={() =>
                        formik.setFieldValue('isLike', formik.values.isLike === null || formik.values.isLike === false ? true : null)
                      }
                    >
                      <Like1 />
                    </IconButton>
                    <IconButton
                      variant={formik.values.isLike === false ? 'contained' : 'outlined'}
                      size="large"
                      color={formik.values.isLike === false ? 'warning' : 'secondary'}
                      sx={{ ...(formik.values.isLike !== false && { bgcolor: '#fff' }) }}
                      onClick={() =>
                        formik.setFieldValue('isLike', formik.values.isLike === null || formik.values.isLike === true ? false : null)
                      }
                    >
                      <Dislike />
                    </IconButton>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={0.5}>
                  <Typography variant="body2">Report a bug or a problem</Typography>
                  <Box sx={{ position: 'relative' }}>
                    <TextField
                      id="message"
                      name="message"
                      value={formik.values.message}
                      onChange={trimFc(formik)}
                      onBlur={formik.handleBlur}
                      error={formik.touched.message && Boolean(formik.errors.message)}
                      multiline
                      rows={3}
                      placeholder="Write your feedback here"
                      fullWidth
                      sx={{ '& .MuiOutlinedInput-root': { bgcolor: '#fff', pb: 5 } }}
                    />
                    <IconButton
                      color="secondary"
                      sx={{ position: 'absolute', bottom: 1, right: 1 }}
                      disabled={formik.isSubmitting}
                      type="submit"
                    >
                      <Send2 size={18} />
                    </IconButton>
                  </Box>
                  {formik.touched.message && formik.errors.message && <FormHelperText error>{formik.errors.message}</FormHelperText>}
                </Stack>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <IconButton
          onClick={handleToggle}
          color="secondary"
          size="large"
          sx={{
            position: 'absolute',
            top: '100%',
            right: 0,
            bgcolor: 'warning.lighter',
            zIndex: 99,
            borderRadius: '0 0 0 16px'
          }}
        >
          <Add style={{ transform: 'rotate(45deg)' }} />
        </IconButton>
      </Dialog>
    </>
  );
};

export default LeaveFeedback;
