import { forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';
import { activeItem } from 'store/reducers/menu';
import useConfig from 'hooks/useConfig';
import { URL_PREFIX } from 'config';

// types
import { LinkTarget, NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';

// ==============================|| NAVIGATION - LIST COLLAPSE ||============================== //

interface Props {
  menu: NavItemType;
  level: number;
}

const NavCollapse = ({ menu, level }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const menuState = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen, openItem } = menuState;
  const { borderRadius } = useConfig();

  // set menu item active on page reload
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    if (pathname && menu.url && pathname === `${URL_PREFIX}/${menu.url}`) {
      dispatch(activeItem({ openItem: [menu.id] }));
    }

    // eslint-disable-next-line
  }, [pathname]);

  const selected = openItem.findIndex((id) => id === menu.id) > -1;

  // menu sub items
  const navCollapse = menu.children?.map((item) => {
    switch (item.type) {
      case 'collapse':
        return <NavCollapse key={item.id} menu={item} level={level + 1} />;
      case 'item':
        return <NavItem key={item.id} item={item} level={level + 1} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Fix - Collapse or Item
          </Typography>
        );
    }
  });

  // set icons
  const Icon = menu.icon!;
  const menuIcon = menu.icon ? <Icon size={20} color={selected ? theme.palette.primary.main : theme.palette.secondary.main} /> : false;

  // set routing and redirect link
  let menuTarget: LinkTarget = '_self';

  let listItemProps: {
    component?: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = {};

  if (menu.url) {
    if (menu.target) {
      menuTarget = '_blank';
    }

    listItemProps = {
      component: forwardRef((props, ref) => <Link {...props} to={`${URL_PREFIX}/${menu.url!}`} target={menuTarget} ref={ref} />)
    };
    if (menu?.external) {
      listItemProps = { component: 'a', href: menu.url, target: menuTarget };
    }
  }

  return (
    <>
      <ListItemButton
        {...listItemProps}
        selected={selected}
        sx={{
          height: 44,
          mb: 0.5,
          mx: 2.5,
          borderRadius: `${borderRadius}px`,
          '&:hover': {
            bgcolor: 'primary.lighter'
          },
          '&.Mui-selected': {
            bgcolor: 'primary.lighter',
            '&:hover': {
              bgcolor: 'primary.100'
            }
          }
        }}
      >
        {/* menu item - icon */}
        <ListItemIcon
          sx={{
            minWidth: 28,
            ...(!drawerOpen && {
              width: 52,
              alignItems: 'center'
            })
          }}
        >
          {menuIcon}
        </ListItemIcon>

        {/* menu item - text */}
        <ListItemText
          primary={
            drawerOpen && (
              <Typography variant={level > 1 ? 'body1' : 'body2'} color={selected ? 'primary.dark' : 'secondary.dark'}>
                {menu.title}
              </Typography>
            )
          }
        />
      </ListItemButton>
      <List sx={{ p: 0 }}>{navCollapse}</List>
    </>
  );
};

export default NavCollapse;
