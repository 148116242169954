import { BE_URL } from 'config';
// types
import { createSlice } from '@reduxjs/toolkit';
import {
  JDBuilderProps,
  JDEditCalculateProps,
  JDEditJobProps,
  JDEditProfileProps,
  JDEditSkillsProps,
  JDProfileProps
} from 'types/jd-builder';

// project imports
import { Axios } from 'utils/axios';
import { dispatch } from 'store';

const initialState: JDBuilderProps = {
  error: null,
  uploadedJDFile: null,
  step: 0,
  id: '',
  status: '',
  profile: {
    jobTitle: '',
    jobCompany: '',
    jobDescription: '',
    companyDescription: '',
    experience: ''
  },
  jobInfo: {
    capacity: '',
    workLocation: '',
    location: '',
    startDate: '',
    endDate: '',
    jobTags: []
  },
  skills: {
    essentialTags: [],
    desirableTags: [],
    culturalTags: []
  },
  calculate: {
    rate: 0,
    timeSlot: 'Daily'
  },
  calculatedRate: {
    lowest: 0,
    highest: 0,
    recommended: 0
  }
};

// ==============================|| SLICE - JD BUILDER ||============================== //

const jd = createSlice({
  name: 'jd-builder',
  initialState,
  reducers: {
    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    // upload jd automatically
    uploadJD(state, action) {
      state.uploadedJDFile = action.payload;
    },

    updateStep(state, action) {
      state.step = action.payload.step;
    },

    setProfile(state, action) {
      const data = action.payload.profile;
      const profile = {
        jobTitle: data.jobTitle,
        jobCompany: data.jobCompany,
        jobDescription: data.jobDescription,
        companyDescription: data.companyDescription,
        experience: data.experience
      };
      state.profile = profile;
      state.id = action.payload.id;
    },

    setJobInfo(state, action) {
      const data = action.payload.jobInfo;
      const jobInfo = {
        capacity: data.capacity,
        workLocation: data.workLocation,
        location: data.location,
        startDate: data.startDate,
        endDate: data.endDate,
        jobTags: data.jobTags
      };
      state.jobInfo = jobInfo;
    },

    setSkills(state, action) {
      const data = action.payload.skills;
      const skills = {
        essentialTags: data.essentialTags,
        desirableTags: data.desirableTags,
        culturalTags: data.culturalTags
      };
      state.skills = skills;
    },

    setCalulate(state, action) {
      const calculateData = action.payload;
      const calculate = {
        rate: calculateData.rate,
        timeSlot: calculateData.timeSlot
      };
      state.calculate = calculate;
    },

    setCalculatedRate(state, action) {
      state.calculatedRate = action.payload;
    },

    resetCalculatedRate(state) {
      state.calculatedRate = initialState.calculatedRate;
    },

    setStore(state, action) {
      state.id = action.payload.id;
      state.status = action.payload.status;
      const data = action.payload;
      const profile = {
        jobTitle: data.jobTitle,
        jobCompany: data.jobCompany,
        jobDescription: data.jobDescription,
        companyDescription: data.companyDescription,
        experience: data.experience
      };
      state.profile = profile;
      const jobInfo = {
        capacity: data.capacity ? data.capacity : state.jobInfo.capacity,
        workLocation: data.workLocation ? data.workLocation : state.jobInfo.workLocation,
        location: data.location ? data.location : state.jobInfo.location,
        startDate: data.startDate ? data.startDate : state.jobInfo.startDate,
        endDate: data.endDate ? data.endDate : state.jobInfo.endDate,
        jobTags: data.jobTags ? data.jobTags : []
      };
      state.jobInfo = jobInfo;
      const skills = {
        essentialTags: data.essentialTags ? data.essentialTags : [],
        desirableTags: data.desirableTags ? data.desirableTags : [],
        culturalTags: data.culturalTags ? data.culturalTags : []
      };
      state.skills = skills;
      const calculate = {
        rate: data.rate ? data.rate : state.calculate.rate,
        timeSlot: data.timeSlot ? data.timeSlot : state.calculate.timeSlot
      };
      state.calculate = calculate;
    },

    resetStore(state) {
      state.error = initialState.error;
      state.step = initialState.step;
      state.id = initialState.id;
      state.profile = initialState.profile;
      state.jobInfo = initialState.jobInfo;
      state.skills = initialState.skills;
      state.calculate = initialState.calculate;
    }
  }
});

export default jd.reducer;

export const { updateStep, resetStore, resetCalculatedRate } = jd.actions;

export function setStore(id: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jd/${id}`);
      dispatch(jd.actions.setStore(response.data.data));
    } catch (error) {
      dispatch(jd.actions.hasError(error));
    }
  };
}

export function uploadJD(file: any) {
  return async () => {
    try {
      const data = new FormData();
      data.append('file', file);
      const response = await Axios.post(`${BE_URL}/upload?type=JD`, data);
      dispatch(jd.actions.uploadJD(response.data.data));
      return response.data.success;
    } catch (error) {
      dispatch(jd.actions.hasError(error));
    }
  };
}

export function addProfile(profile: JDProfileProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/jd`, profile);
      dispatch(jd.actions.setProfile({ profile: response.data.data, id: response.data.data.id }));
      return response.data.success;
    } catch (error) {
      dispatch(jd.actions.hasError(error));
    }
  };
}

export function editProfile(profileData: JDEditProfileProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/jd`, { ...profileData.profile, id: profileData.id });
      dispatch(jd.actions.setProfile({ profile: response.data.data, id: response.data.data.id }));
      return response.data.success;
    } catch (error) {
      dispatch(jd.actions.hasError(error));
    }
  };
}

export function editJobInfo(jobData: JDEditJobProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/jd`, { ...jobData.jobInfo, id: jobData.id });
      dispatch(jd.actions.setJobInfo({ jobInfo: response.data.data }));
      return response.data.success;
    } catch (error) {
      dispatch(jd.actions.hasError(error));
    }
  };
}

export function editSkills(skillsData: JDEditSkillsProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/jd`, { ...skillsData.skills, id: skillsData.id });
      dispatch(jd.actions.setSkills({ skills: response.data.data }));
      return response.data.success;
    } catch (error) {
      dispatch(jd.actions.hasError(error));
    }
  };
}

export function getCalculateRate(id: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jd/${id}/calculate`);
      dispatch(jd.actions.setCalculatedRate(response.data.data));
    } catch (error: any) {
      dispatch(jd.actions.setCalculatedRate({ lowest: '0', highest: '0', recommended: '0' }));
    }
  };
}

export function editCalulate(calculate: JDEditCalculateProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/jd`, { ...calculate.calculate, id: calculate.id });
      dispatch(jd.actions.setCalulate(response.data.data));
      return response.data.success;
    } catch (error) {
      dispatch(jd.actions.hasError(error));
    }
  };
}
export function changeStatus(status: string, id: string) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/jd`, { status, id });
      dispatch(jd.actions.setStore(response.data.data));
      return response.data.success;
    } catch (error) {
      dispatch(jd.actions.hasError(error));
    }
  };
}
