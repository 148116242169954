import { useState, useRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, CardMedia, Divider, Grid, Modal, Stack, Typography } from '@mui/material';

// project import
import { uploadCV } from 'store/reducers/cv-builder';
import { uploadJD } from 'store/reducers/jd-builder';
import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import SimpleBar from 'components/third-party/SimpleBar';
import UploadSuccessPopup from 'components/cards/UploadSuccessPopup';
import { setModal, resetModal } from 'store/reducers/master';
import { dispatch, useSelector } from 'store';

// assets
import { Add } from 'iconsax-react';
import CVCard from 'assets/images/business/cv-card.svg';
import JDCard from 'assets/images/business/jd-card.svg';
import { ModalType } from 'types/master';

// ==============================|| HEADER CONTENT - CV/JD BUILDER SECTION ||============================== //

const Publish = () => {
  const theme = useTheme();

  const { modal } = useSelector((state) => state.master);
  const id = modal && modal?.id;

  const handleClose = () => {
    dispatch(resetModal());
  };

  const [uploadedModal, setUploadedModal] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);
  const [type, setType] = useState<string | null>(null);
  const inputCVRef = useRef<any>(null);
  const inputJDRef = useRef<any>(null);

  let title: string = '';
  let message: string = '';

  if (type === 'cv') {
    title = 'Your CV has been successfully uploaded';
    message = 'We will notify you when your CV card has been created and is ready for your confirmation';
  }

  if (type === 'jd') {
    title = 'Your JD has been successfully uploaded';
    message = 'We will notify you when your JD card has been created and is ready for your confirmation';
  }

  const handleCloseModal = () => {
    setType(null);
    setUploadedModal(false);
    if (!failed) {
      dispatch(resetModal());
    }
  };

  // const handleCVClick = () => {
  //   setType('cv');
  //   // open file input box on click of upload cv button
  //   inputCVRef?.current?.click();
  // };

  // const handleJDClick = () => {
  //   setType('jd');
  //   // open file input box on click of upload jd button
  //   inputJDRef?.current?.click();
  // };

  // cv file upload handler
  const handleCVFileChange = async (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (event.target.files[0].name.match(/.(pdf|doc|docx)$/i)) {
      if (fileObj) {
        const success = await dispatch(uploadCV(fileObj));
        if (success) {
          setUploadedModal(true);
          inputCVRef.current.value = null;
        }
      }
    }
    if (!fileObj) {
      setFailed(true);
      setUploadedModal(true);
    }
  };
  // jd file upload handler
  const handleJDFileChange = async (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (event.target.files[0].name.match(/.(pdf|doc|docx)$/i)) {
      if (fileObj) {
        const success = await dispatch(uploadJD(fileObj));
        if (success) {
          setUploadedModal(true);
          inputJDRef.current.value = null;
        }
      }
    }
    if (!fileObj) {
      setFailed(true);
      setUploadedModal(true);
    }
  };

  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-jd-cv-title"
        aria-describedby="modal-jd-cv-description"
        sx={{
          '& .MuiPaper-root:focus': {
            outline: 'none'
          }
        }}
      >
        <MainCard
          sx={{ width: `calc(100% - 48px)`, minWidth: 340, maxWidth: 880, height: 'auto', maxHeight: 'calc(100vh - 48px)' }}
          modal
          content={false}
        >
          <SimpleBar
            sx={{
              maxHeight: `calc(100vh - 48px)`,
              '& .simplebar-content': {
                display: 'flex',
                flexDirection: 'column'
              }
            }}
          >
            <Box sx={{ p: { xs: 3, sm: 5 } }}>
              <Grid container rowSpacing={{ xs: 2.5, sm: 4 }}>
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ width: 1 }}>
                    {/* need box for put title in center */}
                    <Box />
                    <Typography align="center" variant="h5">
                      Publish Now
                    </Typography>
                    <IconButton color="secondary" size="extraSmall" onClick={handleClose} sx={{ p: 0 }}>
                      <Add color={theme.palette.text.primary} style={{ transform: 'rotate(45deg)' }} />
                    </IconButton>
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {id !== 'jd' && (
                      <Grid item xs={12} sm={id === 'cv' ? 12 : 6}>
                        <MainCard
                          sx={{
                            height: 1,
                            '& .MuiCardContent-root': { height: 1, display: 'flex', flexDirection: 'column', maxHeight: 1 },
                            bgcolor: 'secondary.lighter',
                            overflow: 'visible'
                          }}
                        >
                          <Grid container rowSpacing={3} sx={{ height: 'calc(100% + 24px)' }}>
                            <Grid item xs={12}>
                              <CardMedia
                                component="img"
                                src={CVCard}
                                alt="CV Card"
                                sx={{ width: { xs: 264, sm: 282 }, height: { xs: 264, sm: 282 }, margin: 'auto' }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1}>
                                <Typography variant="h5">Loan Your Employee</Typography>
                                <Typography color="secondary.dark">
                                  Loan your employees and assign them to exciting external projects
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 'auto', mb: 0 }}>
                              <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                {/* <ButtonBase disableRipple onClick={() => dispatch(setModal({ type: ModalType.cvBuild }))}>
                                  <Typography variant="body2" color="primary">
                                    Add CV Manually
                                  </Typography>
                                </ButtonBase> */}

                                <Button variant="contained" size="small" onClick={() => dispatch(setModal({ type: ModalType.cvBuild }))}>
                                  Add CV Manually
                                </Button>

                                {/* hidden input element */}
                                <input
                                  style={{ display: 'none' }}
                                  ref={inputCVRef}
                                  type="file"
                                  accept=".pdf, .doc, .docx"
                                  onChange={handleCVFileChange}
                                />

                                {/* <Button
                                  variant="contained"
                                  size="small"
                                  startIcon={<LogoutCurve size="18" style={{ transform: 'rotate(90deg)' }} />}
                                  onClick={handleCVClick}
                                >
                                  Upload CV
                                </Button> */}
                              </Stack>
                            </Grid>
                          </Grid>
                        </MainCard>
                      </Grid>
                    )}
                    {id !== 'cv' && (
                      <Grid item xs={12} sm={id === 'jd' ? 12 : 6}>
                        <MainCard sx={{ bgcolor: 'secondary.lighter', overflow: 'visible', '& .MuiCardContent-root': { maxHeight: 1 } }}>
                          <Grid container rowSpacing={3} sx={{ height: 'calc(100% + 24px)' }}>
                            <Grid item xs={12}>
                              <CardMedia
                                component="img"
                                src={JDCard}
                                alt="JD Card"
                                sx={{ width: { xs: 264, sm: 282 }, height: { xs: 264, sm: 282 }, margin: 'auto' }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Stack spacing={1}>
                                <Typography variant="h5">Publish Your Jobs</Typography>
                                <Typography color="secondary.dark">
                                  Publish job descriptions and find high quality talent that suit your project budget and capacity
                                </Typography>
                              </Stack>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 'auto', mb: 0 }}>
                              <Stack direction="row" alignItems="center" justifyContent="flex-end">
                                {/* <ButtonBase disableRipple onClick={() => dispatch(setModal({ type: ModalType.jdBuild }))}>
                                  <Typography variant="body2" color="primary">
                                    Add JD Manually
                                  </Typography>
                                </ButtonBase> */}

                                <Button variant="contained" size="small" onClick={() => dispatch(setModal({ type: ModalType.jdBuild }))}>
                                  Add JD Manually
                                </Button>

                                {/* hidden input element */}
                                <input
                                  style={{ display: 'none' }}
                                  ref={inputJDRef}
                                  type="file"
                                  name="upload_file"
                                  accept=".pdf, .doc, .docx"
                                  onChange={handleJDFileChange}
                                />

                                {/* <Button
                                  variant="contained"
                                  size="small"
                                  startIcon={<LogoutCurve size="18" style={{ transform: 'rotate(90deg)' }} />}
                                  onClick={handleJDClick}
                                >
                                  Upload JD
                                </Button> */}
                              </Stack>
                            </Grid>
                          </Grid>
                        </MainCard>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </SimpleBar>
        </MainCard>
      </Modal>
      <UploadSuccessPopup
        open={uploadedModal}
        handleClose={handleCloseModal}
        failed={failed}
        title={failed ? 'Failed' : title}
        message={failed ? 'Try again' : message}
      />
    </>
  );
};

export default Publish;
