// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { BE_URL } from 'config';
import { Axios } from 'utils/axios';
import { dispatch } from '../index';

// types
import { EmployeesProps, EmployeeStateProps, MatchesProps } from 'types/employees';

const initialState: EmployeeStateProps = {
  error: null,
  employees: [],
  applicationEmployees: [],
  matchEmployees: [],
  projectEmployees: [],
  employee: null,
  searchedEmployees: [],
  initialEmployees: [],
  filteredEmployees: [],
  filter: {
    limit: 0,
    offset: 0,
    search: '',
    sortField: 'createdAt',
    sortOrder: '',
    capacity: '',
    rate: '',
    workLocation: '',
    experience: '',
    daysAvailable: '30'
  }
};

// ==============================|| SLICE - EMPLOYEES ||============================== //

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    // get employee
    getEmployee(state, action) {
      state.employee = action.payload;
    },

    // get employees
    getEmployeesSuccess(state, action) {
      state.employees = action.payload;
    },

    // get match employees
    getApplicationEmployeesSuccess(state, action) {
      state.applicationEmployees = action.payload;
    },

    // get match employees
    getMatchEmployeesSuccess(state, action) {
      state.matchEmployees = action.payload;
    },

    // get project employees
    getProjectEmployeesSuccess(state, action) {
      state.projectEmployees = action.payload;
    },

    // search employees
    searchEmployeesSuccess(state, action) {
      state.searchedEmployees = action.payload;
    },

    // filter employees
    filterEmployeesSuccess(state, action) {
      state.initialEmployees = action.payload;
      state.filteredEmployees = action.payload;
    },

    setLocalFilter(state, action) {
      let newFilter;

      if (action.payload.search !== undefined && action.payload.search !== null) {
        newFilter = state.initialEmployees.filter((row: any) => {
          let matches = true;

          const properties = [
            'firstName',
            'lastName',
            'jobTitle',
            'jobCompany',
            'biography',
            'capacity',
            'workLocation',
            'location',
            'jobTags',
            'rate'
          ];

          let containsQuery = false;
          properties.forEach((property) => {
            if (row[property]?.toString().toLowerCase().includes(action.payload.search!.toString().toLowerCase())) {
              containsQuery = true;
            }
          });

          if (!containsQuery) {
            matches = false;
          }
          return matches;
        });
      } else {
        newFilter = state.initialEmployees;
      }

      state.filteredEmployees = newFilter;
    },

    setFilter(state, action) {
      state.filter = {
        ...state.filter,
        ...action.payload
      };
    },

    // delete/remove employee
    removeEmployee(state, action) {
      const { id } = action.payload;
      const removeEmployee = state.employees.filter((employee) => employee.id !== id);
      state.employees = removeEmployee;
    },

    // reset employee
    resetEmployee(state) {
      state.employee = initialState.employee;
    }
  }
});

// reducer
export default slice.reducer;

export const { resetEmployee, setFilter, setLocalFilter } = slice.actions;

export function getEmployee(id: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/cv/${id}`);
      dispatch(slice.actions.getEmployee(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEmployees() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/cv/my`);
      dispatch(slice.actions.getEmployeesSuccess(response.data.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProject() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/cv/projects`);
      dispatch(slice.actions.getProjectEmployeesSuccess(response.data.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMatches() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/cv/matches`);
      let employees = response.data.data.data;
      let newEmployee: EmployeesProps[] = [];
      employees.map((item: EmployeesProps) => {
        if (item && item.matches && item.matches.length > 0) {
          if (item.matches.length > 1) {
            item.matches.map((match: MatchesProps) => {
              newEmployee = newEmployee.length > 0 ? [...newEmployee, { ...item, matches: [match] }] : [{ ...item, matches: [match] }];
              return match;
            });
          } else {
            newEmployee = newEmployee.length > 0 ? [...newEmployee, item] : [item];
          }
        }
        return item;
      });

      dispatch(slice.actions.getMatchEmployeesSuccess(newEmployee));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getApplications() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/cv/applications`);
      let employees = response.data.data.data;
      let newEmployee: EmployeesProps[] = [];
      employees.map((item: EmployeesProps) => {
        if (item && item.matches && item.matches.length > 0) {
          if (item.matches.length > 1) {
            item.matches.map((match: MatchesProps) => {
              newEmployee = newEmployee.length > 0 ? [...newEmployee, { ...item, matches: [match] }] : [{ ...item, matches: [match] }];
              return match;
            });
          } else {
            newEmployee = newEmployee.length > 0 ? [...newEmployee, item] : [item];
          }
        }
        return item;
      });

      dispatch(slice.actions.getApplicationEmployeesSuccess(newEmployee));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function rejectApplication(id: string) {
  return async () => {
    try {
      await Axios.post(`${BE_URL}/match/reject`, { id });
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function rejectProject(id: string) {
  return async () => {
    try {
      await Axios.post(`${BE_URL}/match/cancel`, { id });
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function startProject(id: string) {
  return async () => {
    try {
      await Axios.post(`${BE_URL}/project/start`, { matchId: id });
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function acceptApplicationRequest(id: string) {
  return async () => {
    try {
      await Axios.post(`${BE_URL}/match/accept`, { id });
      return true;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFavouritesEmployees() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/cv/favourites`);
      dispatch(slice.actions.getEmployeesSuccess(response.data.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setFavouritesEmployee(id: string, type: boolean) {
  return async () => {
    try {
      if (type) {
        await Axios.post(`${BE_URL}/cv/favourites/add`, { id });
      } else {
        await Axios.post(`${BE_URL}/cv/favourites/remove`, { id });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMatchEmployees(jdId: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/cv/my/active?jdId=${jdId}`);
      dispatch(slice.actions.getMatchEmployeesSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchEmployees(searchString: string) {
  return async () => {
    try {
      if (searchString.length !== 0) {
        const response = await Axios.get(`${BE_URL}/cv/?search=${searchString}`);
        dispatch(slice.actions.searchEmployeesSuccess(response.data.data.data));
      } else {
        dispatch(slice.actions.searchEmployeesSuccess([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterEmployees(filterString: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/cv/?${filterString}`);
      dispatch(slice.actions.filterEmployeesSuccess(response.data.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function employeeInvite(id: string, jobId: string) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/match`, { cvId: id, jdId: jobId });
      dispatch(slice.actions.hasError(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeEmployee(id: string) {
  return async () => {
    try {
      await Axios.post(`${BE_URL}/cv/delete`, { id });
      dispatch(slice.actions.removeEmployee({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
