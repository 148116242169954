import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Chip, Divider, Fade, Grid, Link, Menu, MenuItem, Modal, Stack, Typography } from '@mui/material';

import { PDFDownloadLink } from '@react-pdf/renderer';

// project import
import { dispatch, useSelector } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import IconButton from 'components/@extended/IconButton';
import JobsCard from 'components/cards/JobsCard';
import MainCard from 'components/MainCard';
import SimpleBar from 'components/third-party/SimpleBar';
import config, { URL_PREFIX } from 'config';
import { getJob, resetJob } from 'store/reducers/jobs';
import { resetStore } from 'store/reducers/jd-builder';
import { resetModal } from 'store/reducers/master';

// assets
import { Add, ArrowDown2, ArrowUp2, Copy } from 'iconsax-react';
import whatsapp from 'assets/images/icons/whatsapp.svg';
import email from 'assets/images/icons/email.svg';
import linkedIn from 'assets/images/icons/linked-in.svg';
import twitter from 'assets/images/icons/twitter.svg';
import facebook from 'assets/images/icons/facebook.svg';
import ExportPDFView from 'sections/job-board/export-pdf-view';
import useAuth from 'hooks/useAuth';

// ==============================|| JD EXPORT/SHARE ||============================== //

const JDShareModal = () => {
  const theme = useTheme();

  const { user } = useAuth();
  const { modal } = useSelector((state) => state.master);
  const id = modal && modal?.id;

  const [anchorEle, setAnchorEle] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
  const shareMenu = Boolean(anchorEle);

  const handleShareMenu = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
    setAnchorEle(event?.currentTarget);
  };

  const handleCloseShareMenu = () => {
    setAnchorEle(null);
  };

  let shareURL = `${window.location.origin}/${config.baseName}${URL_PREFIX}/jd-build/m/${id}`;
  if (config.baseName?.trim() === '') {
    shareURL = `${window.location.origin}${URL_PREFIX}/jd-build/m/${id}`;
  }

  const copyLinkHanlder = () => {
    navigator.clipboard.writeText(shareURL);
    dispatch(
      openSnackbar({
        open: true,
        message: 'Copied to clipboard!',
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
  };

  useEffect(() => {
    if (id) dispatch(getJob(id));
    // eslint-disable-next-line
  }, []);

  const { job } = useSelector((state) => state.jobs);

  const handleClose = () => {
    dispatch(resetJob());
    dispatch(resetStore());
    dispatch(resetModal());
  };

  const disabledButtonStyle = {
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[300],
    justifyContent: 'flex-start',
    '& .MuiButton-startIcon': { mr: 1.5 },
    '&.Mui-disabled': {
      color: 'inherit',
      bgcolor: 'inherit'
    },
    opacity: 0.4
  };

  const buttonStyle = {
    color: theme.palette.grey[700],
    borderColor: theme.palette.grey[300],
    justifyContent: 'flex-start',
    '& .MuiButton-startIcon': { mr: 1.5 }
  };

  return (
    <Modal
      open={true}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root:focus': {
          outline: 'none'
        }
      }}
    >
      <MainCard modal sx={{ width: `calc(100% - 48px)`, maxWidth: 880, height: 'auto', maxHeight: 'calc(100vh - 48px)' }} content={false}>
        <SimpleBar
          sx={{
            maxHeight: `calc(100vh - 48px)`,
            '& .simplebar-content': {
              display: 'flex',
              flexDirection: 'column'
            }
          }}
        >
          <Box sx={{ p: { xs: 3, sm: 5 } }}>
            <Grid container rowSpacing={{ xs: 2.5, sm: 4 }}>
              <Grid item xs={12}>
                <Stack spacing={2}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">Share & Export</Typography>
                    <IconButton color="secondary" size="extraSmall" onClick={handleClose}>
                      <Add color={theme.palette.text.primary} style={{ transform: 'rotate(45deg)' }} />
                    </IconButton>
                  </Stack>
                  <Divider />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h6">Share</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      variant="dashed"
                      sx={{ justifyContent: 'flex-start', '& .MuiButton-startIcon': { mr: 1.5 } }}
                      startIcon={<Copy size="18" />}
                      onClick={copyLinkHanlder}
                    >
                      Copy Link
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      component={Link}
                      href={`mailto:?subject=${user?.firstName} ${user?.lastName} shared a JD with you!&body=I have found this JD - ${shareURL}&title=Powered by TheHIVE.`}
                      variant="outlined"
                      color="secondary"
                      sx={buttonStyle}
                      startIcon={<img src={email} alt="email" />}
                    >
                      Email
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ position: 'relative' }}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      sx={disabledButtonStyle}
                      startIcon={<img src={linkedIn} alt="linkedIn" />}
                      disabled
                    >
                      LinkedIn
                    </Button>
                    <Chip
                      label="Coming Soon"
                      color="secondary"
                      size="small"
                      sx={{ bgcolor: 'text.primary', position: 'absolute', right: 10, top: 24 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Button
                      fullWidth
                      component={Link}
                      href={`https://wa.me/?text=${job?.jobTitle}: Here's a great opportunity for loaning your talent: ${shareURL}. Let me know if you're interested, and I'd be happy to arrange for a discussion with our hiring team.`}
                      target="_blank"
                      variant="outlined"
                      color="secondary"
                      sx={buttonStyle}
                      startIcon={<img src={whatsapp} alt="whatsapp" />}
                    >
                      WhatsApp
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ position: 'relative' }}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      sx={disabledButtonStyle}
                      startIcon={<img src={twitter} alt="twitter" />}
                      disabled
                    >
                      Twitter
                    </Button>
                    <Chip
                      label="Coming Soon"
                      color="secondary"
                      size="small"
                      sx={{ bgcolor: 'text.primary', position: 'absolute', right: 10, top: 24 }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} sx={{ position: 'relative' }}>
                    <Button
                      fullWidth
                      variant="outlined"
                      color="secondary"
                      sx={disabledButtonStyle}
                      startIcon={<img src={facebook} alt="facebook" />}
                      disabled
                    >
                      Facebook
                    </Button>
                    <Chip
                      label="Coming Soon"
                      color="secondary"
                      size="small"
                      sx={{ bgcolor: 'text.primary', position: 'absolute', right: 10, top: 24 }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={2.25}>
                  <Grid item xs={12}>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                      <Typography variant="h6">Export</Typography>
                      <Button
                        size="small"
                        variant="dashed"
                        endIcon={shareMenu ? <ArrowUp2 size="16" /> : <ArrowDown2 size="16" />}
                        onClick={handleShareMenu}
                      >
                        Export as
                      </Button>
                      <Menu
                        anchorEl={anchorEle}
                        open={shareMenu}
                        onClose={handleCloseShareMenu}
                        TransitionComponent={Fade}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right'
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right'
                        }}
                        sx={{ '& .MuiList-root': { minWidth: 180 }, '& .MuiPaper-root': { mt: 0.5 } }}
                      >
                        {job && (
                          <PDFDownloadLink
                            document={<ExportPDFView job={job} />}
                            fileName={`JD-${job.jobCompany}-${job.jobTitle}.pdf`}
                            style={{ textDecoration: 'none' }}
                          >
                            <MenuItem onClick={handleCloseShareMenu} sx={{ color: 'text.primary' }}>
                              PDF
                            </MenuItem>
                          </PDFDownloadLink>
                        )}
                        <MenuItem>
                          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
                            <Box sx={{ color: 'text.secondary' }}>DOC</Box>
                            <Chip
                              label="Coming Soon"
                              color="secondary"
                              variant="filled"
                              size="small"
                              sx={{ height: 20, bgcolor: 'text.primary' }}
                            />
                          </Stack>
                        </MenuItem>
                      </Menu>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    {job && <JobsCard jobsData={job} isExportCard={true} />}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </SimpleBar>
      </MainCard>
    </Modal>
  );
};

export default JDShareModal;
