// ==============================|| DROPZONE - FILE INFO ||============================== //

export default function getDropzoneData(file: File | string, index?: number) {
  if (typeof file === 'string') {
    return {
      key: index ? `${file}-${index}` : file,
      preview: file
    };
  }

  return {
    key: index ? `${file.name}-${index}` : file.name,
    name: file.name,
    size: file.size,
    type: file.type,
    lastModified: file.lastModified
  };
}
