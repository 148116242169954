// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { Axios } from 'utils/axios';
import { dispatch } from '../index';

// types
import { JobsStateProps } from 'types/jobs';
import { BE_URL } from 'config';

const initialState: JobsStateProps = {
  error: null,
  jobs: [],
  matchJobs: [],
  applicants: [],
  job: null,
  searchedJobs: [],
  initialJobs: [],
  filteredJobs: [],
  filter: {
    limit: 0,
    offset: 0,
    search: '',
    sortField: 'createdAt',
    sortOrder: '',
    capacity: '',
    rate: '',
    workLocation: '',
    location: '',
    experience: '',
    daysAvailable: '30'
  }
};

// ==============================|| SLICE - EMPLOYEES ||============================== //

const slice = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    // get job
    getJob(state, action) {
      state.job = action.payload;
    },

    // get jobs
    getJobsSuccess(state, action) {
      state.jobs = action.payload;
    },

    // get review applicants
    getReviewApplicantsSuccess(state, action) {
      state.applicants = action.payload;
    },

    // search jobs
    searchJobsSuccess(state, action) {
      state.searchedJobs = action.payload;
    },

    // filter jobs
    filterJobsSuccess(state, action) {
      state.initialJobs = action.payload;
      state.filteredJobs = action.payload;
    },

    setLocalFilter(state, action) {
      let newFilter;
      if (action.payload.search !== undefined && action.payload.search !== null) {
        newFilter = state.initialJobs.filter((row: any) => {
          let matches = true;

          const properties = ['jobTitle', 'jobCompany', 'jobDescription', 'capacity', 'workLocation', 'location', 'jobTags', 'rate'];

          let containsQuery = false;
          properties.forEach((property) => {
            if (row[property]?.toString().toLowerCase().includes(action.payload.search!.toString().toLowerCase())) {
              containsQuery = true;
            }
          });

          if (!containsQuery) {
            matches = false;
          }
          return matches;
        });
      } else {
        newFilter = state.initialJobs;
      }

      state.filteredJobs = newFilter;
    },

    setFilter(state, action) {
      state.filter = {
        ...state.filter,
        ...action.payload
      };
    },

    // get match jobs
    getMatchJobsSuccess(state, action) {
      state.matchJobs = action.payload;
    },

    // delete/remove job
    removeJob(state, action) {
      const { id } = action.payload;
      const removeJob = state.jobs.filter((job) => job.id !== id);
      state.jobs = removeJob;
    },

    resetJob(state) {
      state.job = initialState.job;
    },

    resetError(state) {
      state.error = initialState.error;
    }
  }
});

// reducer
export default slice.reducer;

export const { resetJob, resetError, setFilter, setLocalFilter } = slice.actions;

export function getJob(id: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jd/${id}`);
      dispatch(slice.actions.getJob(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getJobs() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jd/my`);
      dispatch(slice.actions.getJobsSuccess(response.data.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getReviewApplicants() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jd/applicants`);
      dispatch(slice.actions.getReviewApplicantsSuccess(response.data.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFavouritesJobs() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jd/favourites`);
      dispatch(slice.actions.getJobsSuccess(response.data.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setFavouritesJob(id: string, type: boolean) {
  return async () => {
    try {
      if (type) {
        await Axios.post(`${BE_URL}/jd/favourites/add`, { id });
      } else {
        await Axios.post(`${BE_URL}/jd/favourites/remove`, { id });
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMatchJobs(cvId: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jd/my/active?cvId=${cvId}`);
      dispatch(slice.actions.getMatchJobsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterJobs(searchString: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jd/?${searchString}`);
      dispatch(slice.actions.filterJobsSuccess(response.data.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function searchJobs(searchString: string) {
  return async () => {
    try {
      if (searchString.length !== 0) {
        const response = await Axios.get(`${BE_URL}/jd/?search=${searchString}`);
        dispatch(slice.actions.searchJobsSuccess(response.data.data.data));
      } else {
        dispatch(slice.actions.searchJobsSuccess([]));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function jobApply(id: string, employeeId: string) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/match`, { cvId: employeeId, jdId: id });
      dispatch(slice.actions.hasError(response.data));
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeJob(id: string) {
  return async () => {
    try {
      await Axios.post(`${BE_URL}/jd/delete`, { id });
      dispatch(slice.actions.removeJob({ id }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
