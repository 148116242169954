// material-ui
import { Box } from '@mui/material';

// project import
import { dispatch } from 'store';
import { setModal } from 'store/reducers/master';
import IconButton from 'components/@extended/IconButton';

// assets
import { Add } from 'iconsax-react';
import { ModalType } from 'types/master';

// ==============================|| HEADER CONTENT - CV/JD BUILDER SECTION ||============================== //

const CVJDBuilder = () => (
  <Box sx={{ flexShrink: 0, ml: { xs: 0, sm: 1.25, md: 2.5 } }}>
    <IconButton
      color="primary"
      variant="contained"
      shape="rounded"
      size="large"
      aria-label="open profile"
      aria-controls="profile-grow"
      aria-haspopup="true"
      onClick={() => dispatch(setModal({ type: ModalType.publish }))}
    >
      <Add size={20} />
    </IconButton>
  </Box>
);

export default CVJDBuilder;
