import { forwardRef, ReactNode, Ref, isValidElement } from 'react';

// material-ui
import { Box, Button, ButtonBase, Grid, Modal, Stack, Typography } from '@mui/material';
import AnimateButton from 'components/@extended/AnimateButton';

// project import
import MainCard from 'components/MainCard';

// assets
import { Add, CloseCircle, TickCircle } from 'iconsax-react';

interface WarningPopupProps {
  title?: ReactNode | string;
  message?: ReactNode | string;
  closeButton?: ReactNode | string;
  saveButton?: ReactNode | string | false;
  open: boolean;
  handleSave?: () => void | ((id: number) => void);
  handleClose?: () => void;
  silentClose?: () => void;
  isConfirm?: boolean;
}

// ==============================|| MODAL - WARNING POPUP ||============================== //

const WarningPopup = forwardRef(
  (
    { title, message, open, handleSave, handleClose, silentClose, closeButton, saveButton, isConfirm = false }: WarningPopupProps,
    ref: Ref<HTMLDivElement>
  ) => (
    <Modal
      open={open}
      onClose={silentClose}
      aria-labelledby="modal-warning-title"
      aria-describedby="modal-warning-description"
      ref={ref}
      sx={{
        '& .MuiPaper-root:focus': {
          outline: 'none'
        }
      }}
    >
      <MainCard modal sx={{ width: `calc(100% - 48px)`, maxWidth: 384 }}>
        <ButtonBase sx={{ position: 'absolute', right: 12, top: 12 }} onClick={silentClose}>
          <Add style={{ transform: 'rotate(45deg)' }} />
        </ButtonBase>
        <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: 1 }}>
          <Box
            sx={{
              bgcolor: isConfirm ? 'success.main' : 'error.main',
              color: isConfirm ? 'success.lighter' : 'error.lighter',
              borderRadius: '50%',
              width: '48px',
              height: '48px'
            }}
          >
            {isConfirm ? (
              <TickCircle size={60} variant="Bold" style={{ margin: '-6px' }} />
            ) : (
              <CloseCircle size={60} variant="Bold" style={{ margin: '-6px' }} />
            )}
          </Box>
          {isValidElement(title) ? (
            title
          ) : (
            <Typography variant="title2" sx={{ pt: 1.5 }}>
              {title ? title : 'Are you sure to close?'}
            </Typography>
          )}
          {isValidElement(message) ? (
            message
          ) : (
            <Typography color="textSecondary" sx={{ pt: 1.5 }}>
              {message ? { message } : `You haven’t saved the changes`}
            </Typography>
          )}
          <Grid container spacing={1.5} sx={{ pt: 2 }}>
            {saveButton !== false && (
              <Grid item xs={12}>
                <AnimateButton>
                  {isValidElement(saveButton) ? (
                    saveButton
                  ) : (
                    <Button fullWidth variant="contained" onClick={handleSave}>
                      {saveButton ? { saveButton } : 'Save Changes & Close'}
                    </Button>
                  )}
                </AnimateButton>
              </Grid>
            )}
            <Grid item xs={12}>
              <AnimateButton>
                {isValidElement(closeButton) ? (
                  closeButton
                ) : (
                  <Button fullWidth variant="outlined" color="secondary" onClick={handleClose}>
                    {closeButton ? { closeButton } : 'Close Anyway'}
                  </Button>
                )}
              </AnimateButton>
            </Grid>
          </Grid>
        </Stack>
      </MainCard>
    </Modal>
  )
);

export default WarningPopup;
