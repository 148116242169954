import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { Box, CircularProgress, Modal } from '@mui/material';

// project imports
import MainCard from 'components/MainCard';
import UploadSuccessPopup from 'components/cards/UploadSuccessPopup';
import SimpleBar from 'components/third-party/SimpleBar';
import { resetModal } from 'store/reducers/master';
import { dispatch, useSelector } from 'store';

import { getEmployee, resetEmployee } from 'store/reducers/employees';
import { getMatchJobs } from 'store/reducers/jobs';
import FindPreviewPopup from 'sections/employees/FindPreviewPopup';

const CVFindPreviewPopup = () => {
  const navigate = useNavigate();

  const { modal } = useSelector((state) => state.master);
  const id = modal && modal?.id;
  const match = modal && modal?.match;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      dispatch(getEmployee(id)).then(() => dispatch(getMatchJobs(id)).then(() => setLoading(true)));
    }
    // eslint-disable-next-line
  }, []);

  const { employee } = useSelector((state) => state.employees);

  const [confirm, setConfirm] = useState<boolean>(false);
  const [failed, setFailed] = useState<boolean>(false);

  const closeModal = () => {
    if (modal && modal?.closeNavigate) {
      navigate(modal.closeNavigate);
    }
    dispatch(resetModal());
    dispatch(resetEmployee());
  };

  const handleCloseModal = () => {
    setConfirm(false);
    if (!failed) {
      dispatch(resetModal());
      dispatch(resetEmployee());
    }
    setFailed(false);
  };

  return (
    <>
      <Modal
        open={true}
        onClose={closeModal}
        aria-labelledby="modal-cv-title"
        aria-describedby="modal-cv-description"
        sx={{
          '& .MuiPaper-root:focus': {
            outline: 'none'
          }
        }}
      >
        <MainCard
          sx={{ width: `calc(100% - 48px)`, minWidth: 340, maxWidth: 880, height: 'auto', maxHeight: 'calc(100vh - 48px)' }}
          modal
          content={false}
        >
          <SimpleBar
            sx={{
              maxHeight: `calc(100vh - 48px)`,
              '& .simplebar-content': {
                display: 'flex',
                flexDirection: 'column'
              }
            }}
          >
            <Box sx={{ p: 3 }}>
              {loading && employee && Object.keys(employee).length !== 0 ? (
                <FindPreviewPopup employee={employee} match={match} closeModal={closeModal} setConfirm={setConfirm} setFailed={setFailed} />
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </SimpleBar>
        </MainCard>
      </Modal>
      <UploadSuccessPopup
        open={confirm}
        handleClose={handleCloseModal}
        failed={failed}
        title={failed ? 'Failed' : 'Your application has been sent'}
        message={
          failed
            ? 'Please make sure your account is verified. If the problem persists, please contact our team.'
            : 'Now we wait for the host to respond'
        }
      />
    </>
  );
};

export default CVFindPreviewPopup;
