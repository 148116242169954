import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project import
import useAuth from 'hooks/useAuth';
import { dispatch, useSelector } from 'store';
import { resetModal } from 'store/reducers/master';
import { LOGIN_URL, URL_PREFIX } from 'config';

// types
import { GuardProps } from 'types/auth';

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const { modal } = useSelector((state) => state.master);

  useEffect(() => {
    if (!isLoggedIn) {
      if (modal) {
        dispatch(resetModal());
      }
      navigate(`${URL_PREFIX}/${LOGIN_URL}`, {
        state: {
          from: location.pathname
        },
        replace: true
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate, location]);

  return children;
};

export default AuthGuard;
