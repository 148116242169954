/* eslint-disable react/no-array-index-key */
import { Text, View, StyleSheet } from '@react-pdf/renderer';

// third-party
import { format } from 'date-fns';
import { EmployeesProps } from 'types/employees';
import Experience from './Experience';
import Qualifications from './Qualifications';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 16,
    paddingLeft: 15,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0
    }
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24
  },
  subRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  column: {
    flexDirection: 'column'
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: '#496081',
    marginLeft: 8,
    marginRight: 8
  },
  title: {
    color: '#1F2937',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: 6
  },
  subTitle: {
    color: '#496081',
    fontSize: '10px'
  },
  chipTitle: {
    color: '#496081',
    fontSize: '10px',
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 4,
    paddingTop: 4
  },
  paragraph: {
    color: '#1F2937',
    fontSize: '12px'
  },
  card: {
    backgroundColor: '#F5F7F9',
    borderRadius: '12px',
    padding: 12
  },
  dash: {
    marginLeft: 4,
    marginRight: 4
  },
  chip: {
    border: '1px solid #D9E0EA',
    backgroundColor: '#F5F7F9',
    alignItems: 'center',
    borderRadius: '16px',
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8
  }
});

interface Props {
  employee: EmployeesProps;
}

const Content = ({ employee }: Props) => (
  <View style={styles.container}>
    <View style={styles.row}>
      <Text style={styles.subTitle}>{employee.capacity}</Text>
      <View style={styles.dot} />
      <Text style={styles.subTitle}>{employee.workLocation}</Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.paragraph}>{employee.biography}</Text>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Availability</Text>
        <View style={styles.subRow}>
          <View style={styles.chip}>
            <Text style={styles.chipTitle}>{employee.startDate && format(new Date(employee.startDate!), 'dd LLL yyyy')}</Text>
          </View>
          <Text style={styles.dash}>-</Text>
          <View style={styles.chip}>
            <Text style={styles.chipTitle}>{employee.endDate ? format(new Date(employee.endDate!), 'dd LLL yyyy') : 'Present'}</Text>
          </View>
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Rates</Text>
        <View style={styles.chip}>
          <Text style={styles.chipTitle}>
            £
            {employee.timeSlot === 'Hourly'
              ? (Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10) % 1 !== 0
                ? (Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10).toFixed(2)
                : Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10
              : employee.timeSlot === 'Daily'
              ? (Math.round(10 * Number(employee.rate)) / 10) % 1 !== 0
                ? (Math.round(10 * Number(employee.rate)) / 10).toFixed(2)
                : Math.round(10 * Number(employee.rate)) / 10
              : employee.timeSlot === 'Monthly'
              ? (Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10) % 1 !== 0
                ? (Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10).toFixed(2)
                : Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10
              : ''}{' '}
            / day
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Work Location</Text>
        <View style={styles.chip}>
          <Text style={styles.chipTitle}>{employee.location}</Text>
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Job Tags</Text>
        <View style={styles.subRow}>
          {employee.jobTags &&
            employee.jobTags.map((item: string, index: number) => (
              <View style={styles.chip} key={index}>
                <Text style={styles.chipTitle}>{item}</Text>
              </View>
            ))}
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Essential Skills</Text>
        <View style={styles.subRow}>
          {employee.essentialTags &&
            employee.essentialTags.map((item: string, index: number) => (
              <View style={styles.chip} key={index}>
                <Text style={styles.chipTitle}>{item}</Text>
              </View>
            ))}
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Desireable Skills</Text>
        <View style={styles.subRow}>
          {employee.desirableTags &&
            employee.desirableTags.length > 0 &&
            employee.desirableTags.map((item: string, index: number) => (
              <View style={styles.chip} key={index}>
                <Text style={styles.chipTitle}>{item}</Text>
              </View>
            ))}
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Cultural Preference Keywords</Text>
        <View style={styles.subRow}>
          {employee.culturalTags &&
            employee.culturalTags.map((item: string, index: number) => (
              <View style={styles.chip} key={index}>
                <Text style={styles.chipTitle}>{item}</Text>
              </View>
            ))}
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Experiences</Text>
        <View style={styles.subRow}>
          <Experience employee={employee} />
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Qualifications</Text>
        <View style={styles.subRow}>
          <Qualifications employee={employee} />
        </View>
      </View>
    </View>
  </View>
);

export default Content;
