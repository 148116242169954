// material-ui
import { Theme } from '@mui/material/styles';

// assets
import { ArrowRight2 } from 'iconsax-react';

// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AccordionSummary(theme: Theme) {
  return {
    MuiAccordionSummary: {
      defaultProps: {
        expandIcon: <ArrowRight2 size={16} />
      },
      styleOverrides: {
        root: {
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3),
          borderRadius: '12px',
          minHeight: 46
        },
        expandIconWrapper: {
          '&.Mui-expanded': {
            transform: 'rotate(-180deg)'
          }
        }
      }
    }
  };
}
