import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  createFilterOptions,
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project import
import { useDispatch, useSelector } from 'store';
import { editJobInfo } from 'store/reducers/jd-builder';

// assets
import { ArrowDown2, Calendar, CloseCircle } from 'iconsax-react';
import { addJobTags, resetModal } from 'store/reducers/master';
import { resetStore } from 'store/reducers/jd-builder';
import GoogleMaps from './GoogleMapAutocompleteCity';

// types

// @ts-ignore
const diffDays = (date: Date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));
const filterJob = createFilterOptions<string>();

const validationSchema = yup.object({
  capacity: yup.string().trim().required('Capacity is required'),
  workLocation: yup.string().trim().required('Work location is required'),
  location: yup
    .string()
    .trim()
    .required('Search location is required')
    .matches(/^[a-z\d\-_\s]+$/i, 'Only alphanumerics are allowed')
    .max(50, 'Search location must be at most 50 characters'),
  endDate: yup
    .date()
    .when('startDate', (start, schema) => start && schema.min(start, 'End date must be later than start date'))
    .test(
      'endDate',
      'End date must be at least 2 weeks after staring date',
      (started, yup) => diffDays(yup.parent.startDate, started) > 13
    ),
  startDate: yup.date().required('Start date is required'),
  jobTags: yup
    .array()
    .of(yup.string().trim().required('Leading spaces found in your tag').max(50, 'Job tag field must be at most 50 characters'))
    .required('Job tag selection is required')
    .min(3, 'Please select at least 3 Job Tags.')
    .max(10, 'Please select a maximum of 10 Job tags.')
});

function compare(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}

// ==============================|| JD BUILDER - JOB DETAILS ||============================== //

interface Props {
  nextHandler: () => void;
}

const Step2 = ({ nextHandler }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [save, setSave] = useState<boolean>(false);

  const { capacities, locations, jobTags } = useSelector((state) => state.master);
  const { jobInfo, id } = useSelector((state) => state.jd);

  const formik = useFormik({
    initialValues: {
      capacity: jobInfo.capacity,
      workLocation: jobInfo.workLocation,
      location: jobInfo.location,
      startDate: jobInfo.startDate,
      endDate: jobInfo.endDate,
      jobTags: jobInfo.jobTags
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const edited = compare(values, formik.initialValues);
      const success = edited ? true : await dispatch(editJobInfo({ jobInfo: values, id }));
      setSubmitting(false);
      if (!save && success) {
        nextHandler();
      }
      if (save && success) {
        dispatch(resetStore());
        dispatch(resetModal());
      }
    }
  });

  let startMinDate = new Date();
  startMinDate.setMonth(startMinDate.getMonth() - 1);

  let startMaxDate = new Date();
  startMaxDate.setMonth(startMaxDate.getMonth() + 12);
  startMaxDate.setDate(startMaxDate.getDate() - 14);

  let endMinDate = new Date();
  endMinDate.setMonth(endMinDate.getMonth() - 1);
  endMinDate.setDate(endMinDate.getDate() + 14);

  let endMaxDate = new Date();
  endMaxDate.setMonth(endMaxDate.getMonth() + 12);

  let jobTagError: boolean | string | undefined = false;
  if (formik.touched.jobTags && typeof formik.errors.jobTags) {
    if (formik.touched.jobTags && typeof formik.errors.jobTags === 'string') {
      jobTagError = formik.errors.jobTags;
    } else {
      formik.errors.jobTags &&
        typeof formik.errors.jobTags !== 'string' &&
        formik.errors.jobTags.map((item) => {
          jobTagError = item;
          return item;
        });
    }
  }
  const handleAddJobTag = (value: string) => {
    value && dispatch(addJobTags(value));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={formik.handleSubmit} id="validation-forms">
        <Grid container rowSpacing={{ xs: 2.5, sm: 4 }} columnSpacing={3}>
          <Grid item xs={12}>
            <InputLabel>Capacity *</InputLabel>
            <Autocomplete
              id="capacity"
              value={formik.values.capacity}
              onChange={(event: any, newValue: string | null) => {
                formik.setFieldValue('capacity', newValue);
              }}
              options={capacities}
              disableClearable
              freeSolo
              autoHighlight
              clearOnBlur
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  name="capacity"
                  placeholder="Select Capacity"
                  error={formik.touched.capacity && Boolean(formik.errors.capacity)}
                  helperText={formik.touched.capacity && formik.errors.capacity}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <ArrowDown2 size={16} color={theme.palette.secondary.main} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container rowSpacing={4} columnSpacing={3}>
              <Grid item xs={12} sm={6}>
                <InputLabel>Flexibility *</InputLabel>
                <Autocomplete
                  id="workLocation"
                  value={formik.values.workLocation}
                  onChange={(event: any, newValue: string | null) => {
                    formik.setFieldValue('workLocation', newValue);
                  }}
                  options={locations}
                  disableClearable
                  freeSolo
                  clearOnBlur
                  autoHighlight
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      name="workLocation"
                      placeholder="Work Location"
                      error={formik.touched.workLocation && Boolean(formik.errors.workLocation)}
                      helperText={formik.touched.workLocation && formik.errors.workLocation}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <InputAdornment position="end">
                            <ArrowDown2 size={16} color={theme.palette.secondary.main} />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel>Location *</InputLabel>
                <GoogleMaps noOptionsText={'Please select a city or a country from the options provided…'} formik={formik} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Availability *</InputLabel>
            <Grid container rowSpacing={4} columnSpacing={3}>
              <Grid item xs={12} sm={6}>
                <MobileDateTimePicker
                  value={formik.values.startDate}
                  inputFormat="dd/MM/yyyy"
                  views={['year', 'month', 'day']}
                  onChange={(date) => formik.setFieldValue('startDate', date)}
                  showToolbar={false}
                  DialogProps={{
                    sx: { '& .MuiPaper-root': { p: 1.25 } }
                  }}
                  minDate={startMinDate}
                  maxDate={startMaxDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="cal-start-date"
                      name="startDate"
                      placeholder="Starting Date"
                      fullWidth
                      error={Boolean(formik.touched.startDate && formik.errors.startDate)}
                      helperText={formik.touched.startDate && formik.errors.startDate}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Calendar size={16} color={theme.palette.secondary.main} />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MobileDateTimePicker
                  value={formik.values.endDate}
                  inputFormat="dd/MM/yyyy"
                  views={['year', 'month', 'day']}
                  onChange={(date) => formik.setFieldValue('endDate', date)}
                  showToolbar={false}
                  DialogProps={{
                    sx: { '& .MuiPaper-root': { p: 1.25 } }
                  }}
                  minDate={endMinDate}
                  maxDate={endMaxDate}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="cal-end-date"
                      name="endDate"
                      placeholder="Finishing Date"
                      fullWidth
                      error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                      helperText={formik.touched.endDate && formik.errors.endDate}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Calendar size={16} color={theme.palette.secondary.main} />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Job Tags *</InputLabel>
            <Autocomplete
              id="job-tags"
              multiple
              fullWidth
              autoHighlight
              freeSolo
              disableCloseOnSelect
              options={jobTags}
              value={formik.values.jobTags}
              onBlur={formik.handleBlur}
              getOptionLabel={(option) => {
                return option;
              }}
              onChange={(event, newValue) => {
                const jobExist = jobTags.includes(newValue[newValue.length - 1]);
                if (!jobExist) {
                  formik.setFieldValue('jobTags', newValue);
                  handleAddJobTag(newValue[newValue.length - 1]);
                } else {
                  formik.setFieldValue('jobTags', newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filterJob(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                  filtered.push(inputValue);
                }

                return filtered;
              }}
              renderOption={(props, option) => (
                <Box component="li" {...props}>
                  {!jobTags.some((v) => option && option.includes(v)) ? `Add "${option}"` : option}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="jobTags"
                  placeholder="Write your tags"
                  error={formik.touched.jobTags && Boolean(formik.errors.jobTags)}
                  helperText={jobTagError}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  let error = false;
                  if (formik.touched.jobTags && formik.errors.jobTags && typeof formik.errors.jobTags !== 'string') {
                    if (typeof formik.errors.jobTags[index] === 'object') error = true;
                  }

                  return (
                    <Chip
                      {...getTagProps({ index })}
                      variant="combined"
                      color={error ? 'error' : 'secondary'}
                      label={
                        <Typography variant="caption2" color="secondary.dark">
                          {option}
                        </Typography>
                      }
                      deleteIcon={<CloseCircle size={14} />}
                      size="small"
                    />
                  );
                })
              }
            />

            <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
              <Typography variant="caption1" color="secondary">
                Suggestion:
              </Typography>
              {jobTags
                .filter((tag) => formik.values.jobTags && !formik.values.jobTags.map((item) => item).includes(tag))
                .slice(0, 5)
                .map((option, index) => (
                  <Chip
                    key={index}
                    variant="combined"
                    onClick={() => formik.setFieldValue('jobTags', [...formik.values.jobTags, option])}
                    label={
                      <Typography variant="caption2" color="secondary.dark">
                        {option}
                      </Typography>
                    }
                    size="small"
                  />
                ))}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between">
              <Box sx={{ display: 'flex' }}>
                {[1, 2, 3, 4].map((item: number, index: number) => (
                  <Skeleton
                    key={index}
                    variant="circular"
                    width={item === 2 ? '80px' : '8px'}
                    height="8px"
                    animation={false}
                    sx={{
                      mr: 1,
                      bgcolor: item === 2 ? 'primary.main' : 'secondary.light',
                      borderRadius: item === 2 ? '6px' : '50%'
                    }}
                  />
                ))}
              </Box>
              <Box sx={{ display: 'flex' }}>
                <Button
                  variant="outlined"
                  sx={{ mr: 2 }}
                  size="small"
                  disabled={formik.isSubmitting}
                  onClick={() => {
                    setSave(true);
                    formik.handleSubmit();
                  }}
                >
                  Save Changes & Close
                </Button>
                <Button variant="contained" size="small" disabled={formik.isSubmitting} type="submit" onClick={() => setSave(false)}>
                  Next
                </Button>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  );
};

export default Step2;
