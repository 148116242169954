// types
import { DefaultConfigProps } from 'types/config';

export const miniDrawerWidth = 92;
export const drawerWidth = 280;
export const toolbarHeight = 80;
export const SAVING_FACTOR = 21.5;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const URL_PREFIX = '/p';
export const BASE_BE_URL = process.env.REACT_APP_BE_URL || 'http://localhost:5000';
export const BE_URL = BASE_BE_URL + '/api';

// export const LOGIN_URL = 'login2022thehive';
// export const SIGNUP_URL = 'signup2022thehive';
// export const FORGOT_URL = 'forgot-password2022thehive';

export const EXP_END_DATE = new Date('01/01/2030').toDateString();

export const LOGIN_URL = 'login';
export const SIGNUP_URL = 'signup';
export const FORGOT_URL = 'forgot-password';

// ==============================|| THEME CONFIG ||============================== //

const config: DefaultConfigProps = {
  defaultPath: `${URL_PREFIX}/overview`,
  fontFamily: `'Roboto', sans-serif`,
  borderRadius: 12,
  i18n: 'en',
  miniDrawer: false,
  container: false,
  mode: 'light',
  presetColor: 'default',
  themeDirection: 'ltr',
  baseName: ''
};

export default config;
