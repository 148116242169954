import React, { createContext, useEffect, useReducer } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// third-party
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

// action - state management
import { INIT_STATE, LOGIN, LOGOUT, REGISTER, UPDATE_USER } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';

// project import
import { BE_URL, FIREBASE_API, SIGNUP_URL } from 'config';
import {
  AuthProps,
  FirebaseContextType,
  FirebaseManualRegisterResponse,
  FirebaseManualLoginResponse,
  FirebaseManualChangePasswordResponse
} from 'types/auth';
import { Axios } from 'utils/axios';
import { dispatch as storeDispatch } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
import { UpdateProfile } from 'types/user';
import Loader from 'components/Loader';

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(FIREBASE_API);
}

// const
const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext<FirebaseContextType | null>(null);

export const FirebaseProvider = ({ children }: { children: React.ReactElement }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged(async (user: any) => {
        if (user) {
          if (!user.emailVerified) {
            firebase.auth().signOut();
            return;
          }
          if (location.pathname !== `/${SIGNUP_URL}`) {
            try {
              const response = await Axios.post(`${BE_URL}/users/sessionLogin`, { idToken: user._delegate.accessToken });
              if (response.data.success) {
                const loginInUser = await Axios.get(`${BE_URL}/users/me`);

                if (loginInUser.data.success && loginInUser.data.data.isConfirmTCPP && loginInUser.data.data.signupComplete) {
                  const userData = { ...loginInUser.data.data, emailVerified: user.emailVerified };
                  dispatch({
                    type: LOGIN,
                    payload: {
                      isLoggedIn: true,
                      user: userData,
                      token: user._delegate.accessToken
                    }
                  });
                } else if (!loginInUser.data.data.isConfirmTCPP || !loginInUser.data.data.signupComplete) {
                  dispatch({
                    type: INIT_STATE
                  });
                  navigate({
                    pathname: '/signup',
                    search: '?update=true'
                  });
                } else {
                  firebase.auth().signOut();
                  dispatch({
                    type: LOGIN,
                    payload: {
                      isLoggedIn: false
                    }
                  });
                }
              } else {
                firebase.auth().signOut();
                dispatch({
                  type: LOGIN,
                  payload: {
                    isLoggedIn: false
                  }
                });
              }
            } catch (error: any) {
              storeDispatch(
                openSnackbar({
                  open: true,
                  message: error.response.data.error,
                  variant: 'alert',
                  alert: {
                    color: 'error'
                  },
                  close: false
                })
              );

              firebase.auth().signOut();
              dispatch({
                type: LOGIN,
                payload: {
                  isLoggedIn: false
                }
              });
            }
          } else {
            dispatch({
              type: REGISTER,
              payload: {
                isLoggedIn: false,
                user: {
                  id: user.id,
                  email: user.email,
                  firstName: user.displayName && user.displayName.split(' ')[0],
                  lastName: user.displayName && user.displayName.split(' ')[1],
                  emailVerified: user.emailVerified,
                  signupComplete: user.signupComplete
                },
                token: user._delegate.accessToken
              }
            });
          }
        } else {
          dispatch({
            type: LOGOUT
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const firebaseEmailPasswordSignIn = (email: string, password: string) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(
        (response) => {
          if (!response.user?.emailVerified) {
            if (location.pathname !== `/${SIGNUP_URL}`) {
              let resp: FirebaseManualLoginResponse = {
                result: false,
                code: 'ALREADY_EMAIL',
                message: 'Email address not verified'
              };
              return resp;
            } else {
              response.user?.sendEmailVerification();
              let resp: FirebaseManualLoginResponse = {
                result: false,
                message: 'We transfer verification email to verify account'
              };
              return resp;
            }
          }
          let resp: FirebaseManualLoginResponse = {
            result: true
          };
          return resp;
        },
        (error) => {
          let resp: FirebaseManualLoginResponse = {
            result: false,
            message: error.message
          };

          return resp;
        }
      );

  const firebaseVerificationEmail = (email: string, password: string) =>
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((response) => {
        response.user?.sendEmailVerification();
        let resp: FirebaseManualLoginResponse = {
          result: false,
          message: 'We transfer verification email to verify account'
        };
        return resp;
      });

  const firebaseResetPassword = (newPassword: string, actionCode: string) =>
    // Verify the password reset code is valid.
    firebase
      .auth()
      .verifyPasswordResetCode(actionCode)
      .then(async (email) => {
        const resp = await firebase
          .auth()
          .confirmPasswordReset(actionCode, newPassword)
          .then(() => {
            let resp: FirebaseManualLoginResponse = {
              result: true
            };
            return resp;
          })
          .catch((error) => {
            let resp: FirebaseManualLoginResponse = {
              result: false,
              message: error.message
            };
            return resp;
          });
        return resp;
      })
      .catch((error) => {
        let resp: FirebaseManualLoginResponse = {
          result: false,
          message: error.message
        };
        return resp;
      });

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseGoogleSignUp = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const firebaseRegister = async (email: string, password: string) =>
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(
        (response: any) => {
          firebase.auth().signOut();
          response.user?.sendEmailVerification();
          let resp: FirebaseManualRegisterResponse = {
            result: true,
            token: response.user._delegate.accessToken
          };
          return resp;
        },
        async (error) => {
          let resp: FirebaseManualRegisterResponse = {
            result: false
          };
          if (error.code === 'auth/email-already-in-use') {
            resp = {
              result: false,
              message: 'The email address is already in use.'
            };
            await firebase
              .auth()
              .signInWithEmailAndPassword(email, password)
              .then(
                (response: any) => {
                  if (response.user) {
                    resp = {
                      result: true,
                      token: response.user._delegate.accessToken
                    };
                  } else {
                    resp = {
                      result: false,
                      message: error.message
                    };
                  }
                },
                (error) => {
                  if (error.code === 'auth/wrong-password') {
                    resp = {
                      result: false,
                      message: 'The email address is already in use.'
                    };
                    firebase.auth().signOut();
                  } else {
                    resp = {
                      result: false,
                      message: error.message
                    };
                  }
                  return resp;
                }
              );
            return resp;
          } else {
            resp = {
              result: false,
              message: error.message
            };
            return resp;
          }
        }
      );

  const logout = () => firebase.auth().signOut();

  const resetPassword = async (email: string) => {
    await firebase.auth().sendPasswordResetEmail(email);
  };

  const updateProfile = async (updateProfile: UpdateProfile) => {
    await Axios.post(`${BE_URL}/users/update/profile`, updateProfile).then(async (response) => {
      const loginInUser = await Axios.get(`${BE_URL}/users/me`);
      if (response.data.success && loginInUser.data.success) {
        dispatch({
          type: UPDATE_USER,
          payload: {
            isLoggedIn: true,
            user: loginInUser.data.data
          }
        });
      } else {
        storeDispatch(
          openSnackbar({
            open: true,
            message: response.data.error,
            variant: 'alert',
            alert: {
              color: 'error'
            },
            close: false
          })
        );
      }
    });
  };

  const reauthenticate = (currentPassword: string) => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(user?.email!, currentPassword);
    // @ts-ignore
    return user.reauthenticateWithCredential(cred);
  };

  const updatePassword = (currentPassword: string, newPassword: string) => {
    let resp: FirebaseManualChangePasswordResponse = {
      result: false,
      message: 'Firebase: Unauthorized user request'
    };

    const authPromise = reauthenticate(currentPassword);
    const user = firebase.auth().currentUser;
    // @ts-ignore
    const updatePromise = user.updatePassword(newPassword);

    return Promise.all([authPromise, updatePromise]).then(
      () => {
        resp = {
          result: true,
          message: 'Password updated successfully.'
        };
        return resp;
      },
      (error) => {
        resp = {
          result: false,
          message: error.message
        };
        return resp;
      }
    );
  };

  const deleteUser = async () => {
    await Axios.delete(`${BE_URL}/users`).then(async () => {
      await firebase.auth().currentUser?.delete();
    });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        firebaseRegister,
        firebaseEmailPasswordSignIn,
        firebaseVerificationEmail,
        login: () => {},
        firebaseGoogleSignIn,
        logout,
        deleteUser,
        resetPassword,
        updateProfile,
        updatePassword,
        firebaseGoogleSignUp,
        firebaseResetPassword
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContext;
