// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project import
import employees from './employees';
import documents from './documents';
import jobs from './jobs';
import menu from './menu';
import notifications from './notifications';
import snackbar from './snackbar';
import cv from './cv-builder';
import jd from './jd-builder';
import master from './master';
import userSignup from './user-signup';
import tools from './tools';
import waitingList from './waiting-list';

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
  menu,
  snackbar,
  employees,
  notifications,
  documents,
  jobs,
  cv,
  jd,
  userSignup,
  tools,
  waitingList,
  master: persistReducer(
    {
      key: 'master',
      storage,
      keyPrefix: 'thv-'
    },
    master
  )
});

export default reducers;
