import { useState } from 'react';

// material-ui
import { ButtonBase, Typography, TypographyProps } from '@mui/material';
import { ColorProps } from 'types/extended';

interface LinkProps {
  color?: ColorProps;
  labelMore?: string;
  labelLess?: string;
}

interface Props extends TypographyProps {
  children: string;
  isReadMore?: boolean;
  limit?: number;
  color?: ColorProps;
  link?: LinkProps;
}

const initialLink: LinkProps = {
  color: 'primary',
  labelMore: 'See more',
  labelLess: 'See less'
};

const ReadMore = ({ children, isReadMore = false, limit = 150, link = initialLink, ...others }: Props) => {
  const [more, setMore] = useState(isReadMore);

  if (children.length <= limit) {
    return <Typography {...others}>{children}</Typography>;
  }

  let subChildren = children;
  if (!more && children.length > limit) {
    subChildren = `${subChildren.slice(0, limit)}`;
  }

  return (
    <Typography {...others}>
      {subChildren}
      {' ... '}
      {!more && (
        <ButtonBase onClick={() => setMore(true)}>
          <Typography color={link.color}>{link.labelMore}</Typography>
        </ButtonBase>
      )}
      {more && (
        <ButtonBase onClick={() => setMore(false)}>
          <Typography color={link.color}>{link.labelLess}</Typography>
        </ButtonBase>
      )}
    </Typography>
  );
};

export default ReadMore;
