import { useCallback, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';

// third-party
import { format } from 'date-fns';
import { PDFDownloadLink } from '@react-pdf/renderer';

// project imports
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import ReadMore from 'components/@extended/ReamMore';
import Dot from 'components/@extended/Dot';
import { changeStatus } from 'store/reducers/jd-builder';
import { setModal } from 'store/reducers/master';
import { dispatch } from 'store';

// aseets
import { Add, DocumentDownload, Edit, ExportCurve, Heart, Link1, Profile, Element3 } from 'iconsax-react';
import { JobStatus } from 'types/jobs';
import { validateJD } from 'utils/validateJD';
import { ModalType } from 'types/master';
import ExportPDFView from 'sections/job-board/export-pdf-view';

import { JDBuilderProps } from 'types/jd-builder';
import { JobsProps } from 'types/jobs';
import { setFavouritesJob } from 'store/reducers/jobs';
import debounce from 'utils/debounce';
import useAuth from 'hooks/useAuth';
import { EmployeeStatus, MatchesProps, ReviewApplicantStatus } from 'types/employees';
import { ColorProps } from 'types/extended';
import CurrencyFormat from 'react-currency-format';
import { UserRole } from 'types/user';
import { openSnackbar } from 'store/reducers/snackbar';

interface Props {
  job: JobsProps;
  jd: JDBuilderProps;
  matches?: MatchesProps[] | false;
  closeModal: () => void;
}

const PreviewPopup = ({ job, jd, matches, closeModal }: Props) => {
  const theme = useTheme();

  const { user } = useAuth();
  const [valid, setValid] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [isFavourite, setIsFavourite] = useState<boolean>(job && job?.favourite !== undefined ? job.favourite : false);
  const [iconHover, setIconHover] = useState<boolean>(false);

  const isCreator = user?.id === job.userId || user?.role === UserRole.admin;
  const isAccess = isCreator || job.status === JobStatus.CONTRACTED;

  const { profile, calculate, jobInfo, skills } = jd;
  if (jobInfo.endDate && jobInfo.startDate) {
    validateJD(profile, calculate, jobInfo, skills).then((res) => {
      setValid(res);
    });
  }

  const handleChange = (isFavourite: boolean, id: string) => {
    dispatch(setFavouritesJob(id, isFavourite));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(handleChange), []);

  const handlePublishBtn = async () => {
    if (job.id) {
      setIsSubmited(true);
      await dispatch(changeStatus(JobStatus.PUBLISHED, job.id)).then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Your JD card is now published and searchable',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        setIsSubmited(false);
        closeModal();
      });
    }
  };
  const handleDraftBtn = async () => {
    if (job.id) {
      setIsSubmited(true);
      await dispatch(changeStatus(JobStatus.DRAFT, job.id)).then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Your JD card is now moved to Drafts (not published and searchable anymore)',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        setIsSubmited(false);
        closeModal();
      });
    }
  };

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <Stack spacing={1} alignItems="center" justifyContent="center">
          <Typography align="center" variant="h5">
            {job.jobTitle}
          </Typography>
          <Box sx={{ position: 'absolute', top: 24, right: 24 }}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <IconButton
                color="secondary"
                onMouseEnter={() => setIconHover(!iconHover)}
                onMouseLeave={() => setIconHover(!iconHover)}
                onClick={() => {
                  optimizedFn(!isFavourite, job.id);
                  setIsFavourite(!isFavourite);
                }}
                sx={{ padding: 0, width: 32, height: 32 }}
              >
                <Heart
                  variant={isFavourite ? 'Bold' : 'Outline'}
                  size={20}
                  color={isFavourite || iconHover ? theme.palette.primary.main : theme.palette.text.primary}
                />
              </IconButton>
              <IconButton
                sx={{ padding: 0, width: 32, height: 32 }}
                color="secondary"
                onClick={() => dispatch(setModal({ type: ModalType.jdShare, id: job.id }))}
              >
                <ExportCurve size={20} color={theme.palette.text.primary} />
              </IconButton>
              <PDFDownloadLink document={<ExportPDFView job={job} />} fileName={`JD-${job.jobCompany}-${job.jobTitle}.pdf`}>
                <IconButton sx={{ padding: 0, width: 32, height: 32 }} color="secondary">
                  <DocumentDownload size={20} color={theme.palette.text.primary} />
                </IconButton>
              </PDFDownloadLink>
              <IconButton sx={{ padding: 0, width: 32, height: 32 }} color="secondary" onClick={closeModal}>
                <Add size={20} color={theme.palette.text.primary} style={{ transform: 'rotate(45deg)' }} />
              </IconButton>
            </Stack>
          </Box>
          <Typography align="center" color="secondary">
            Please ensure that your information is clear and easy to understand for the candidates
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
          <List disablePadding>
            <ListItem sx={{ p: 0 }}>
              <ListItemAvatar sx={{ mr: 2 }}>
                <Avatar variant="rounded" size="lg" color="secondary">
                  <Element3 size={40} variant="Bold" color="black" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ m: 0 }}
                primary={<Typography variant="h6">{job.jobTitle}</Typography>}
                secondary={
                  <>
                    {isAccess ? (
                      <Typography variant="subtitle1" color="secondary">
                        {job.jobCompany}
                      </Typography>
                    ) : (
                      <Skeleton width={120} height={24} animation={false} />
                    )}
                  </>
                }
              />
            </ListItem>
          </List>

          {user?.id === job.userId && (
            <Button
              variant="dashed"
              size="small"
              startIcon={<Edit size={18} />}
              onClick={() => dispatch(setModal({ type: ModalType.jdBuild }))}
            >
              Edit Fields
            </Button>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {matches && (
        <Grid item xs={12}>
          <Typography variant="body3">Review Applicants</Typography>
          <Grid container spacing={2.5} sx={{ pt: 1 }}>
            {matches.map((match, index) => {
              let matchStateLabel: string = '';
              let matchColor: ColorProps = 'primary';
              switch (match.status) {
                case ReviewApplicantStatus.PROJECT:
                  matchStateLabel = 'Contracted';
                  matchColor = 'success';
                  break;
                case ReviewApplicantStatus.CANCELLED:
                  matchStateLabel = 'Cancelled';
                  matchColor = 'error';
                  break;
                case ReviewApplicantStatus.REJECTED:
                  matchStateLabel = 'Rejected';
                  matchColor = 'error';
                  break;
                case ReviewApplicantStatus.ACCEPTED:
                  matchStateLabel = 'Accepted';
                  matchColor = 'success';
                  break;
                case ReviewApplicantStatus.INVITED:
                  matchStateLabel = 'Invited';
                  matchColor = 'info';
                  break;
                case ReviewApplicantStatus.REVIEW:
                  matchStateLabel = 'Applied';
                  matchColor = 'warning';
                  break;
                case ReviewApplicantStatus.NEW:
                  matchStateLabel = 'New';
                  matchColor = 'primary';
                  break;
                default:
                  matchStateLabel = 'Available';
                  matchColor = 'success';
                  break;
              }

              const newModalType = match.status === ReviewApplicantStatus.NEW ? ModalType.cvAppPreview : ModalType.cvPreview;
              if (job.status === JobStatus.CONTRACTED && ReviewApplicantStatus.PROJECT !== match.status) return false;

              const isEmployeeAccess = match.status === ReviewApplicantStatus.PROJECT && match.cv.status === EmployeeStatus.CONTRACTED;

              return (
                <Grid item xs={12} md={6}>
                  <MainCard
                    border
                    content={false}
                    sx={{ borderRadius: 2, cursor: 'pointer' }}
                    onClick={() => {
                      dispatch(
                        setModal({
                          type: match.status === ReviewApplicantStatus.ACCEPTED ? ModalType.cvMatchPreview : newModalType,
                          id: match.cvId,
                          ...((match.status === ReviewApplicantStatus.ACCEPTED || match.status === ReviewApplicantStatus.NEW) && { match })
                        })
                      );
                    }}
                  >
                    <Box sx={{ p: 2 }}>
                      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                        <Stack direction="row" alignItems="center" spacing={1}>
                          <Avatar
                            color="secondary"
                            {...(isEmployeeAccess && match.cv.image && { alt: match.cv.firstName, src: match.cv.image })}
                          >
                            <Profile variant="Bold" color="black" />
                          </Avatar>
                          <Stack>
                            {isEmployeeAccess ? (
                              <Typography variant="body3">{`${match.cv.firstName} ${match.cv.lastName}`}</Typography>
                            ) : (
                              <Skeleton width={120} height={20} animation={false} />
                            )}
                            <Typography variant="caption1" color="secondary">
                              {isEmployeeAccess ? match.cv.jobCompany : match.cv.jobTitle}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Chip label={matchStateLabel} sx={{ height: 24, mr: 1.5 }} color={matchColor} />
                        <Link1 size={18} />
                      </Stack>
                    </Box>
                  </MainCard>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      )}
      <Grid item xs={12}>
        <Stack spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="secondary.dark">{job.capacity}</Typography>
            <Dot color="secondary" size={6} />
            <Typography color="secondary.dark">{job.workLocation}</Typography>
          </Stack>
          <ReadMore variant="subtitle1">{job?.jobDescription!}</ReadMore>
        </Stack>
      </Grid>
      {job.companyDescription && (
        <Grid item xs={12}>
          <MainCard content={false} sx={{ bgcolor: 'secondary.lighter', overflow: 'visible' }}>
            <Box sx={{ p: 2 }}>
              <Stack spacing={1}>
                <Typography variant="body3">Company Description</Typography>
                <ReadMore variant="subtitle1">{job.companyDescription}</ReadMore>
              </Stack>
            </Box>
          </MainCard>
        </Grid>
      )}

      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Availability</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Chip
              variant="combined"
              label={
                <Typography variant="body2" color="secondary.dark">
                  {job.startDate && format(new Date(job.startDate!), 'dd LLL yyyy')}
                </Typography>
              }
              size="small"
            />
            <Typography color="secondary.dark">-</Typography>
            <Chip
              variant="combined"
              label={
                <Typography variant="body2" color="secondary.dark">
                  {job.endDate ? format(new Date(job.endDate!), 'dd LLL yyyy') : 'Present'}
                </Typography>
              }
              size="small"
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Rates</Typography>
          <Box>
            <Chip
              variant="combined"
              label={
                <Typography variant="body2" color="secondary.dark">
                  {job.rate ? (
                    <>
                      <CurrencyFormat
                        fixedDecimalScale
                        value={
                          job.timeSlot === 'Hourly'
                            ? (Math.round(10 * (parseFloat(job.rate) * 7.5)) / 10) % 1 !== 0
                              ? (Math.round(10 * (parseFloat(job.rate) * 7.5)) / 10).toFixed(2)
                              : Math.round(10 * (parseFloat(job.rate) * 7.5)) / 10
                            : job.timeSlot === 'Daily'
                            ? (Math.round(10 * Number(job.rate)) / 10) % 1 !== 0
                              ? (Math.round(10 * Number(job.rate)) / 10).toFixed(2)
                              : Math.round(10 * Number(job.rate)) / 10
                            : job.timeSlot === 'Monthly'
                            ? (Math.round(10 * (parseFloat(job.rate) / 21.5)) / 10) % 1 !== 0
                              ? (Math.round(10 * (parseFloat(job.rate) / 21.5)) / 10).toFixed(2)
                              : Math.round(10 * (parseFloat(job.rate) / 21.5)) / 10
                            : ''
                        }
                        displayType="text"
                        thousandSeparator
                        prefix="£"
                      />{' '}
                      / day
                    </>
                  ) : (
                    '-'
                  )}
                </Typography>
              }
              size="small"
            />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Work Location</Typography>
          <Box>
            <Chip
              variant="combined"
              label={
                <Typography variant="body2" color="secondary.dark">
                  {job.location}
                </Typography>
              }
              size="small"
            />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Job Tags</Typography>
          <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
            {job.jobTags &&
              job.jobTags.map((item: string, index: number) => (
                <Chip
                  key={index}
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {item}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Essential Skills</Typography>
          <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
            {job.essentialTags &&
              job.essentialTags.map((item: string, index: number) => (
                <Chip
                  key={index}
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {item}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Desireable Skills</Typography>
          <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
            {job.desirableTags &&
              job.desirableTags.length > 0 &&
              job.desirableTags.map((item: string, index: number) => (
                <Chip
                  key={index}
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {item}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Cultural Preference Keywords</Typography>
          <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
            {job.culturalTags &&
              job.culturalTags.map((item: string, index: number) => (
                <Chip
                  key={index}
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {item}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center" justifyContent={!valid && user?.id === job.userId ? 'space-between' : 'flex-end'}>
          {!valid && user?.id === job.userId && (
            <Alert color="error" variant="border" icon={false} sx={{ color: 'error.main' }}>
              Please fill out the JD to Publish
            </Alert>
          )}
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
            {job.status === JobStatus.PUBLISHED && (
              <Button variant="outlined" color="secondary" onClick={() => handleDraftBtn()} disabled={!valid || isSubmited}>
                Move to Draft
              </Button>
            )}
            <Button
              variant={job.status === JobStatus.PUBLISHED ? 'contained' : 'outlined'}
              color={job.status === JobStatus.PUBLISHED ? 'primary' : 'secondary'}
              onClick={closeModal}
            >
              {user?.id === job.userId ? 'Save Changes' : 'Close'}
            </Button>

            {job.status === JobStatus.DRAFT && (
              <Button variant="contained" onClick={() => handlePublishBtn()} disabled={!valid || isSubmited}>
                Publish This JD
              </Button>
            )}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PreviewPopup;
