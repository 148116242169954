import { CustomFile } from './dropzone';
import { BusinessTypeCoverProps } from './master';

export type UserProps = {
  user: UserProfile | null;
  company: CompanyData | null;
  error: object | string | null;
  sessionLogin: boolean;
  feedbackSend: boolean;
  companyDataSend: boolean;
};

export enum UserStatus {
  verified = 'VERIFIED',
  unverified = 'UNVERIFIED'
}

export enum UserRole {
  user = 'USER',
  admin = 'ADMIN'
}

export type UserProfile = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  signupComplete: boolean;
  firebaseUid?: string;
  createdAt?: string;
  updatedAt?: string;
  avatar?: CustomFile | null;
  biography?: string;
  company?: CompanyData;
  companyId?: string;
  deleted?: string;
  role?: string;
  status?: UserStatus;
  tier?: string;
  emailVerified: boolean;
  isConfirmTCPP?: boolean;
};

export type UserData = {
  avatar?: CustomFile | null;
  firstName: string;
  lastName: string;
  email?: string;
  biography?: string;
  password?: string;
  confirmPassword?: string;
};

export type CompanyData = {
  logo?: CustomFile | null;
  companyName?: string;
  phoneCode?: string | null;
  phone?: string;
  businessName?: string;
  crnNumber?: string;
  website?: string;
  decisionMaker?: boolean;
  address1?: string;
  address2?: string;
  city?: string | null;
  county?: string | null;
  country?: string | null;
  postCode?: string;
  business?: BusinessTypeCoverProps[];
  toHire?: boolean;
  loanStaff?: boolean;
  otherAim?: boolean;
  aim?: string;
};

export type UpdateProfile = {
  businessCard: UserData;
  companyCard: CompanyData;
};
