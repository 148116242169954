import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Divider,
  Grid,
  Modal,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

// third-party
import { formatDistance, format } from 'date-fns';

// project imports
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import SimpleBar from 'components/third-party/SimpleBar';
import { resetModal, setModal } from 'store/reducers/master';
import { dispatch, useSelector } from 'store';

// aseets
import { Add, ArrowDown2, Link1, Profile, Profile2User, Tag, Global, ArrowUp2, Call, Element3 } from 'iconsax-react';
import { getEmployee, rejectProject, startProject, resetEmployee } from 'store/reducers/employees';
import { ModalType } from 'types/master';
import CurrencyFormat from 'react-currency-format';
import useAuth from 'hooks/useAuth';
import { openSnackbar } from 'store/reducers/snackbar';
import { EmployeeStatus, ReviewApplicantStatus } from 'types/employees';
import { UserRole } from 'types/user';

// @ts-ignore
const diffDates = (d1: Date, d2) => {
  d1 = new Date(d1);
  d2 = new Date(d2);

  return formatDistance(d1, d2);
};

// function getColor(match: number) {
//   if (match >= 80) return 'success.main';
//   if (match >= 50) return 'warning.main';
//   return 'error';
// }

const CVMatchPreviewPopup = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useAuth();
  const { modal } = useSelector((state) => state.master);
  const id = modal && modal?.id;
  const match = modal && modal?.match;

  useEffect(() => {
    if (id) {
      dispatch(getEmployee(id));
    }
    // eslint-disable-next-line
  }, []);

  const { employee } = useSelector((state) => state.employees);

  const [checked, setChecked] = useState<boolean>(true);

  const closeModal = () => {
    if (modal && modal?.closeNavigate) {
      navigate(modal.closeNavigate);
    }
    dispatch(resetModal());
    dispatch(resetEmployee());
  };

  const isEmployeeAccess = user?.role === UserRole.admin || employee?.status === EmployeeStatus.CONTRACTED || employee?.userId === user?.id;
  const isJobAccess =
    user?.role === UserRole.admin || employee?.status === EmployeeStatus.CONTRACTED || (match && match.jd.userId === user?.id);

  return (
    <>
      <Modal
        open={true}
        onClose={closeModal}
        aria-labelledby="modal-cv-title"
        aria-describedby="modal-cv-description"
        sx={{
          '& .MuiPaper-root:focus': {
            outline: 'none'
          }
        }}
      >
        <MainCard
          sx={{ width: `calc(100% - 48px)`, minWidth: 340, maxWidth: 880, height: 'auto', maxHeight: 'calc(100vh - 48px)' }}
          modal
          content={false}
        >
          <SimpleBar
            sx={{
              maxHeight: `calc(100vh - 48px)`,
              '& .simplebar-content': {
                display: 'flex',
                flexDirection: 'column'
              }
            }}
          >
            <Box sx={{ p: 3 }}>
              {match && employee && Object.keys(employee).length !== 0 ? (
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <Stack
                      spacing={1}
                      justifyContent="space-between"
                      alignItems={{ xs: 'flex-start', sm: 'center' }}
                      direction={{ xs: 'column', sm: 'row' }}
                    >
                      <Stack>
                        <Typography variant="h5">{match.cvProject && match.cvProject ? 'Project Card' : 'Match Card'}</Typography>
                        <Typography color="secondary">
                          Your {match.cvProject && match.cvProject ? 'project' : 'matches'} detail aggreement
                        </Typography>
                      </Stack>
                      <IconButton sx={{ padding: 0, width: 32, height: 32 }} color="secondary" onClick={closeModal}>
                        <Add size={20} color={theme.palette.text.primary} style={{ transform: 'rotate(45deg)' }} />
                      </IconButton>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <Typography variant="body3">Project Title</Typography>
                      <Box>
                        <Chip
                          variant="combined"
                          label={
                            <Typography variant="body2" color="secondary.dark">
                              {match.jd.jobTitle}
                            </Typography>
                          }
                          size="small"
                        />
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <Typography variant="body3">Reference Number</Typography>
                      <Box>
                        {match && (
                          <Chip
                            variant="combined"
                            label={
                              <Typography variant="body2" color="secondary.dark">
                                {match.id}
                              </Typography>
                            }
                            size="small"
                          />
                        )}
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <Typography variant="body3">My Employee</Typography>
                      <MainCard
                        border
                        content={false}
                        sx={{ borderRadius: 2, cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(setModal({ type: ModalType.cvPreview, id: employee.id }));
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar
                                size="sm"
                                color="secondary"
                                {...(isEmployeeAccess && employee.image && { alt: employee.firstName, src: employee.image })}
                              >
                                <Profile size={20} color="black" variant="Bold" />
                              </Avatar>
                              <Stack>
                                {isEmployeeAccess ? (
                                  <Typography variant="body3">
                                    {employee.firstName} {employee.lastName}
                                  </Typography>
                                ) : (
                                  <Skeleton width={120} height={20} animation={false} />
                                )}
                                <Typography variant="caption1" color="secondary">
                                  {isEmployeeAccess ? employee.jobCompany : employee.jobTitle}
                                </Typography>
                              </Stack>
                            </Stack>
                            <Link1 size={18} />
                          </Stack>
                        </Box>
                      </MainCard>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Stack spacing={1}>
                      <Typography variant="body3">Job Description</Typography>
                      <MainCard
                        border
                        content={false}
                        sx={{ borderRadius: 2, cursor: 'pointer' }}
                        onClick={() => {
                          dispatch(setModal({ type: ModalType.jdPreview, id: match.jd.id }));
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Avatar
                                size="sm"
                                color="secondary"
                                variant="rounded"
                                {...(isJobAccess && match.jd.logo && { alt: match.jd.jobTitle, src: match.jd.logo })}
                              >
                                <Element3 size={20} variant="Bold" color="black" />
                              </Avatar>
                              <Stack>
                                <Typography variant="body3">{match.jd.jobTitle}</Typography>
                                {isJobAccess ? (
                                  <Typography variant="caption1" color="secondary">
                                    {match.jd.jobCompany}
                                  </Typography>
                                ) : (
                                  <Skeleton width={120} height={16} animation={false} />
                                )}
                              </Stack>
                            </Stack>
                            <Link1 size={18} />
                          </Stack>
                        </Box>
                      </MainCard>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <MainCard content={false} border sx={{ borderColor: theme.palette.success.lighter }}>
                      <Box sx={{ bgcolor: 'success.lighter', p: 2 }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Profile2User color={theme.palette.success.main} />
                            <Typography variant="title2" color="success.main">
                              Start Negotiations
                            </Typography>
                          </Stack>
                          <Typography variant="subtitle2" color="success.main">
                            {employee.startDate && format(new Date(employee.startDate), 'dd LLL')}
                          </Typography>
                        </Stack>
                      </Box>
                      <Box sx={{ ml: 5, p: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              sx={{ cursor: 'pointer' }}
                              onClick={() => setChecked(!checked)}
                            >
                              <Stack>
                                <Typography variant="title2">Send Your Offer</Typography>
                                <Typography color="secondary">
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim at id a bibendum accumsan. Phasellus in
                                  dictum.
                                </Typography>
                              </Stack>
                              {checked ? <ArrowUp2 size={20} /> : <ArrowDown2 size={20} />}
                            </Stack>
                          </Grid>
                          <Grid item xs={12}>
                            <Collapse in={checked}>
                              <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Detail</TableCell>
                                      <TableCell>Offering Terms</TableCell>
                                      <TableCell>Asking Terms</TableCell>
                                      <TableCell>Action</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell component="th" scope="row">
                                        <Stack direction="row" alignItems="center" spacing={1.5}>
                                          <Tag size={20} />
                                          <Typography variant="body2">{employee.timeSlot} Rate</Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2" color="secondary">
                                          {employee.rate ? (
                                            <>
                                              <CurrencyFormat
                                                fixedDecimalScale
                                                value={
                                                  employee.timeSlot === 'Hourly'
                                                    ? (Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10) % 1 !== 0
                                                      ? (Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10).toFixed(2)
                                                      : Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10
                                                    : employee.timeSlot === 'Daily'
                                                    ? (Math.round(10 * Number(employee.rate)) / 10) % 1 !== 0
                                                      ? (Math.round(10 * Number(employee.rate)) / 10).toFixed(2)
                                                      : Math.round(10 * Number(employee.rate)) / 10
                                                    : employee.timeSlot === 'Monthly'
                                                    ? (Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10) % 1 !== 0
                                                      ? (Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10).toFixed(2)
                                                      : Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10
                                                    : ''
                                                }
                                                displayType="text"
                                                thousandSeparator
                                                prefix="£"
                                              />{' '}
                                              / day
                                            </>
                                          ) : (
                                            ''
                                          )}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>-</TableCell>
                                      <TableCell>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell component="th" scope="row">
                                        <Stack direction="row" alignItems="center" spacing={1.5}>
                                          <Global size={20} />
                                          <Typography variant="body2">Work Location</Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2" color="secondary">
                                          {employee.workLocation}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>-</TableCell>
                                      <TableCell>-</TableCell>
                                    </TableRow>
                                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                      <TableCell component="th" scope="row">
                                        <Stack direction="row" alignItems="center" spacing={1.5}>
                                          <Global size={20} />
                                          <Typography variant="body2">Duration</Typography>
                                        </Stack>
                                      </TableCell>
                                      <TableCell>
                                        <Typography variant="body2" color="secondary">
                                          {diffDates(new Date(employee.startDate!), employee.endDate)}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>-</TableCell>
                                      <TableCell>-</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Collapse>
                          </Grid>
                        </Grid>
                      </Box>
                    </MainCard>
                  </Grid>

                  <Grid item xs={12}>
                    <MainCard content={false} border sx={{ borderColor: theme.palette.info[100], overflow: 'visible' }}>
                      <Box sx={{ bgcolor: 'info.100', p: 2, borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}>
                        <Stack direction="row" alignItems="center" spacing={2}>
                          <Call color={theme.palette.info.main} />
                          <Typography variant="title2" color="info.600">
                            You can arrange a meeting with applicant and schedule a call
                          </Typography>
                        </Stack>
                      </Box>
                      <Box sx={{ ml: 5, p: 2 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Stack
                              direction={{ xs: 'column', sm: 'row' }}
                              alignItems={{ xs: 'flex-start', sm: 'center' }}
                              justifyContent="space-between"
                              spacing={2}
                            >
                              <Stack>
                                <Typography variant="title2">Introduction Call</Typography>
                                <Typography color="secondary">
                                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Enim at id a bibendum accumsan. Phasellus in
                                  dictum.
                                </Typography>
                              </Stack>
                              <Box sx={{ width: 172 }}>
                                <Button variant="contained" sx={{ width: 172 }}>
                                  Arrange Meeting
                                </Button>
                              </Box>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Box>
                    </MainCard>
                  </Grid>

                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-between">
                      <Box>
                        {match.status !== ReviewApplicantStatus.PROJECT && (
                          <>
                            {((match.cvProject && user?.id !== employee.userId) || (match.jdProject && user?.id === employee.userId)) && (
                              <Typography variant="body2">Your match has agreed to start this project.</Typography>
                            )}
                            {((match.cvProject && user?.id === employee.userId) || (match.jdProject && user?.id !== employee.userId)) && (
                              <Typography variant="body2">You already started this project.</Typography>
                            )}
                          </>
                        )}
                      </Box>
                      <Stack direction="row" spacing={1.25} alignItems="center" justifyContent="flex-end">
                        <Button color="secondary" variant="outlined" onClick={closeModal}>
                          Close
                        </Button>
                        {match.status !== ReviewApplicantStatus.PROJECT && (
                          <Button
                            color="error"
                            variant="dashed"
                            onClick={() =>
                              dispatch(rejectProject(match.id)).then(() => {
                                dispatch(
                                  openSnackbar({
                                    open: true,
                                    message: 'Project rejected successfully',
                                    variant: 'alert',
                                    alert: {
                                      color: 'error'
                                    },
                                    close: false
                                  })
                                );
                                closeModal();
                              })
                            }
                          >
                            Cancel Match
                          </Button>
                        )}
                        {match.status !== ReviewApplicantStatus.PROJECT && (
                          <Button
                            color="primary"
                            variant="contained"
                            disabled={
                              (match.jdProject && user?.id !== employee.userId) || (match.cvProject && user?.id === employee.userId)
                            }
                            onClick={() =>
                              dispatch(startProject(match.id)).then(() => {
                                dispatch(
                                  openSnackbar({
                                    open: true,
                                    message: 'Project started successfully',
                                    variant: 'alert',
                                    alert: {
                                      color: 'success'
                                    },
                                    close: false
                                  })
                                );
                                closeModal();
                              })
                            }
                          >
                            Start this project
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress />
                </Box>
              )}
            </Box>
          </SimpleBar>
        </MainCard>
      </Modal>
    </>
  );
};

export default CVMatchPreviewPopup;
