import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  createFilterOptions,
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  InputLabel,
  Skeleton,
  Stack,
  TextField,
  Typography,
  Tooltip
} from '@mui/material';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project import
import { useDispatch, useSelector } from 'store';
import { editSkills, resetStore } from 'store/reducers/jd-builder';

// assets
import { CloseCircle, InfoCircle } from 'iconsax-react';
import { addCulturalPeferences, addDesirableSkills, addEssentialSkills, resetModal } from 'store/reducers/master';
// import { JobStatus } from 'types/jobs';

// types

const filterEssentialTags = createFilterOptions<string>();
const filterDesirableTags = createFilterOptions<string>();
const filterCulturalPeferences = createFilterOptions<string>();

const validationSchema = yup.object({
  essentialTags: yup
    .array()
    .of(yup.string().trim().required('Leading spaces found in your tag').max(50, 'Essential tag field must be at most 50 characters'))
    .required('Essential tag selection is required')
    .min(3, 'Essential tags field must have at least 3 items')
    .max(10, 'Please select a maximum of 15 Essential skills.'),
  desirableTags: yup
    .array()
    .of(yup.string().trim().required('Leading spaces found in your tag').max(50, 'Desirable tag field must be at most 50 characters'))
    .max(10, 'Please select a maximum of 10 Desirable skills.'),
  culturalTags: yup
    .array()
    .of(yup.string().trim().required('Leading spaces found in your tag').max(50, 'Cultural Values field must be at most 50 characters'))
    .required('Cultural Values selection is required')
    .min(3, 'Cultural Values field must have at least 3 items')
    .max(7, 'Please select a maximum of 7 Cultural Values.')
});

// const validationSchemaDraft = yup.object({
//   essentialTags: yup
//     .array()
//     .of(
//       yup
//         .string()
//         .trim()
//         .required('Leading spaces found in your tag')
//         .max(50, 'Essential tag field must be at most 50 characters')
//     )
//     .max(15, 'Please select a maximum of 15 Essential skills.'),
//   desirableTags: yup
//     .array()
//     .of(
//       yup
//         .string()
//         .trim()
//         .required('Leading spaces found in your tag')
//         .max(50, 'Desirable tag field must be at most 50 characters')
//     )
//     .max(10, 'Please select a maximum of 10 Desirable skills.'),
//   culturalTags: yup
//     .array()
//     .of(
//       yup
//         .string()
//         .trim()
//         .required('Leading spaces found in your tag')
//         .max(50, 'Cultural preference field must be at most 50 characters')
//     )
//     .max(7, 'Please select a maximum of 7 Cultural preference keywords.')
// });

function compare(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}

// ==============================|| JD BUILDER - SKILLS ||============================== //

interface Props {
  nextHandler: () => void;
}

const Step3 = ({ nextHandler }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [save, setSave] = useState<boolean>(false);

  const { essentialSkills, desirableSkills, culturalPeferences } = useSelector((state) => state.master);
  // const { status, skills, id } = useSelector((state) => state.jd);
  const { skills, id } = useSelector((state) => state.jd);

  const formik = useFormik({
    initialValues: {
      essentialTags: skills.essentialTags,
      desirableTags: skills.desirableTags,
      culturalTags: skills.culturalTags
    },
    // validationSchema: status !== JobStatus.DRAFT && status !== '' ? validationSchema : validationSchemaDraft,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const edited = compare(values, formik.initialValues);
      const success = edited ? true : await dispatch(editSkills({ skills: values, id }));
      setSubmitting(false);
      if (!save && success) {
        nextHandler();
      }
      if (save && success) {
        dispatch(resetStore());
        dispatch(resetModal());
      }
    }
  });

  let essentialTagsError: boolean | string | undefined = false;
  if (formik.touched.essentialTags && typeof formik.errors.essentialTags) {
    if (formik.touched.essentialTags && typeof formik.errors.essentialTags === 'string') {
      essentialTagsError = formik.errors.essentialTags;
    } else {
      formik.errors.essentialTags &&
        typeof formik.errors.essentialTags !== 'string' &&
        formik.errors.essentialTags.map((item) => {
          // @ts-ignore
          if (typeof item === 'object') essentialTagsError = item.label;
          return item;
        });
    }
  }

  let culturalTagsError: boolean | string | undefined = false;
  if (formik.touched.culturalTags && typeof formik.errors.culturalTags) {
    if (formik.touched.culturalTags && typeof formik.errors.culturalTags === 'string') {
      culturalTagsError = formik.errors.culturalTags;
    } else {
      formik.errors.culturalTags &&
        typeof formik.errors.culturalTags !== 'string' &&
        formik.errors.culturalTags.map((item) => {
          // @ts-ignore
          if (typeof item === 'object') culturalTagsError = item.label;
          return item;
        });
    }
  }

  let desirableTagsError: boolean | string | undefined = false;
  if (formik.touched.desirableTags && typeof formik.errors.desirableTags) {
    if (formik.touched.desirableTags && typeof formik.errors.desirableTags === 'string') {
      desirableTagsError = formik.errors.desirableTags;
    } else {
      formik.errors.desirableTags &&
        typeof formik.errors.desirableTags !== 'string' &&
        // @ts-ignore
        formik.errors.desirableTags.map((item) => {
          // @ts-ignore
          if (typeof item === 'object') desirableTagsError = item.label;
          return item;
        });
    }
  }

  const handleAddEssentialSkill = (value: string) => {
    value && dispatch(addEssentialSkills(value));
  };
  const handleAddDescribleSkill = (value: string) => {
    value && dispatch(addDesirableSkills(value));
  };
  const handleAddPreference = (value: string) => {
    value && dispatch(addCulturalPeferences(value));
  };

  return (
    <form onSubmit={formik.handleSubmit} id="validation-forms">
      <Grid container rowSpacing={{ xs: 2.5, sm: 4 }} columnSpacing={3}>
        <Grid item xs={12}>
          <InputLabel>Essential Skills *</InputLabel>
          <Autocomplete
            id="essential-skills"
            multiple
            fullWidth
            autoHighlight
            freeSolo
            disableCloseOnSelect
            options={essentialSkills}
            value={formik.values.essentialTags}
            onBlur={formik.handleBlur}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              const jobExist = essentialSkills.includes(newValue[newValue.length - 1]);
              if (!jobExist) {
                formik.setFieldValue('essentialTags', newValue);
                handleAddEssentialSkill(newValue[newValue.length - 1]);
              } else {
                formik.setFieldValue('essentialTags', newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filterEssentialTags(options, params);
              const { inputValue } = params;
              const isExisting = options.some((option) => inputValue === option);
              if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {!essentialSkills.some((v) => option && option.includes(v)) ? `Add "${option}"` : option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="essentialTags"
                placeholder="Write your essential skills"
                error={formik.touched.essentialTags && Boolean(formik.errors.essentialTags)}
                helperText={essentialTagsError}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                let error = false;
                if (formik.touched.essentialTags && formik.errors.essentialTags && typeof formik.errors.essentialTags !== 'string') {
                  if (typeof formik.errors.essentialTags[index] === 'object') error = true;
                }

                return (
                  <Chip
                    {...getTagProps({ index })}
                    variant="combined"
                    color={error ? 'error' : 'secondary'}
                    label={
                      <Typography variant="caption2" color="secondary.dark">
                        {option}
                      </Typography>
                    }
                    deleteIcon={<CloseCircle size={14} />}
                    size="small"
                  />
                );
              })
            }
          />
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
            <Typography variant="caption1" color="secondary">
              Suggestion:
            </Typography>
            {essentialSkills
              .filter((skill) => formik.values.essentialTags && !formik.values.essentialTags.map((item) => item).includes(skill))
              .slice(0, 5)
              .map((option, index) => (
                <Chip
                  key={index}
                  variant="combined"
                  onClick={() => formik.setFieldValue('essentialTags', [...formik.values.essentialTags, option])}
                  label={
                    <Typography variant="caption2" color="secondary.dark">
                      {option}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            Desirable Skills{' '}
            <Typography component="span" variant="body2" color="secondary.400">
              (Optional)
            </Typography>
          </InputLabel>
          <Autocomplete
            id="desirable-skills"
            multiple
            fullWidth
            autoHighlight
            freeSolo
            disableCloseOnSelect
            options={desirableSkills}
            value={formik.values.desirableTags}
            onBlur={formik.handleBlur}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              const jobExist = desirableSkills.includes(newValue[newValue.length - 1]);
              if (!jobExist) {
                formik.setFieldValue('desirableTags', newValue);
                handleAddDescribleSkill(newValue[newValue.length - 1]);
              } else {
                formik.setFieldValue('desirableTags', newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filterDesirableTags(options, params);
              const { inputValue } = params;
              const isExisting = options.some((option) => inputValue === option);
              if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {!desirableSkills.some((v) => option && option.includes(v)) ? `Add "${option}"` : option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="desirableTags"
                placeholder="Write your desirable skills"
                error={formik.touched.desirableTags && Boolean(formik.errors.desirableTags)}
                helperText={desirableTagsError}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                let error = false;
                if (formik.touched.desirableTags && formik.errors.desirableTags && typeof formik.errors.desirableTags !== 'string') {
                  if (typeof formik.errors.desirableTags[index] === 'object') error = true;
                }

                return (
                  <Chip
                    {...getTagProps({ index })}
                    variant="combined"
                    color={error ? 'error' : 'secondary'}
                    label={
                      <Typography variant="caption2" color="secondary.dark">
                        {option}
                      </Typography>
                    }
                    deleteIcon={<CloseCircle size={14} />}
                    size="small"
                  />
                );
              })
            }
          />
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
            <Typography variant="caption1" color="secondary">
              Suggestion:
            </Typography>
            {desirableSkills
              .filter((skill) => formik.values.desirableTags && !formik.values.desirableTags.map((item) => item).includes(skill))
              .slice(0, 5)
              .map((option, index) => (
                <Chip
                  key={index}
                  variant="combined"
                  onClick={() =>
                    formik.setFieldValue('desirableTags', formik.values.desirableTags ? [...formik.values.desirableTags, option] : [option])
                  }
                  label={
                    <Typography variant="caption2" color="secondary.dark">
                      {option}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <InputLabel>Cultural Values *</InputLabel>
            <Tooltip title="Please provide what cultural values your company promotes for improved matching results">
              <InfoCircle size={20} color={theme.palette.primary.main} style={{ marginBottom: '4px' }} />
            </Tooltip>
          </Stack>
          <Autocomplete
            id="cultural-skills"
            multiple
            fullWidth
            autoHighlight
            freeSolo
            disableCloseOnSelect
            options={culturalPeferences}
            value={formik.values.culturalTags}
            onBlur={formik.handleBlur}
            getOptionLabel={(option) => option}
            onChange={(event, newValue) => {
              const jobExist = culturalPeferences.includes(newValue[newValue.length - 1]);
              if (!jobExist) {
                formik.setFieldValue('culturalTags', newValue);
                handleAddPreference(newValue[newValue.length - 1]);
              } else {
                formik.setFieldValue('culturalTags', newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filterCulturalPeferences(options, params);
              const { inputValue } = params;
              const isExisting = options.some((option) => inputValue === option);
              if (inputValue !== '' && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                {!culturalPeferences.some((v) => option && option.includes(v)) ? `Add "${option}"` : option}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="culturalTags"
                placeholder="Write your cultural preference keywords"
                error={formik.touched.culturalTags && Boolean(formik.errors.culturalTags)}
                helperText={culturalTagsError}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                let error = false;
                if (formik.touched.culturalTags && formik.errors.culturalTags && typeof formik.errors.culturalTags !== 'string') {
                  if (typeof formik.errors.culturalTags[index] === 'object') error = true;
                }

                return (
                  <Chip
                    {...getTagProps({ index })}
                    variant="combined"
                    color={error ? 'error' : 'secondary'}
                    label={
                      <Typography variant="caption2" color="secondary.dark">
                        {option}
                      </Typography>
                    }
                    deleteIcon={<CloseCircle size={14} />}
                    size="small"
                  />
                );
              })
            }
          />
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mt: 1 }}>
            <Typography variant="caption1" color="secondary">
              Suggestion:
            </Typography>
            {culturalPeferences
              .filter((skill) => formik.values.culturalTags && !formik.values.culturalTags.map((item) => item).includes(skill))
              .slice(0, 5)
              .map((option, index) => (
                <Chip
                  key={index}
                  variant="combined"
                  onClick={() => formik.setFieldValue('culturalTags', [...formik.values.culturalTags, option])}
                  label={
                    <Typography variant="caption2" color="secondary.dark">
                      {option}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between">
            <Box sx={{ display: 'flex' }}>
              {[1, 2, 3, 4].map((item: number, index: number) => (
                <Skeleton
                  key={index}
                  variant="circular"
                  width={item === 3 ? '80px' : '8px'}
                  height="8px"
                  animation={false}
                  sx={{
                    mr: 1,
                    bgcolor: item === 3 ? 'primary.main' : 'secondary.light',
                    borderRadius: item === 3 ? '6px' : '50%'
                  }}
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                size="small"
                disabled={formik.isSubmitting}
                onClick={() => {
                  setSave(true);
                  formik.handleSubmit();
                }}
              >
                Save Changes & Close
              </Button>
              <Button variant="contained" size="small" disabled={formik.isSubmitting} type="submit" onClick={() => setSave(false)}>
                Next
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default Step3;
