// material-ui
import { Theme, TypographyVariantsOptions } from '@mui/material/styles';

// types
import { FontFamily } from 'types/config';

// ==============================|| DEFAULT THEME - TYPOGRAPHY  ||============================== //

const Typography = (fontFamily: FontFamily, theme: Theme): TypographyVariantsOptions => ({
  htmlFontSize: 16,
  fontFamily,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  h1: {
    fontWeight: 700,
    fontSize: '40px',
    lineHeight: '48px',
    letterSpacing: '-2%',
    [theme.breakpoints.down('md')]: {
      fontSize: '36px',
      lineHeight: '44px'
    }
  },
  h2: {
    fontWeight: 700,
    fontSize: '36px',
    lineHeight: '44px',
    letterSpacing: '-2%',
    [theme.breakpoints.down('md')]: {
      fontSize: '32px',
      lineHeight: '40px'
    }
  },
  h3: {
    fontWeight: 700,
    fontSize: '32px',
    lineHeight: '40px',
    letterSpacing: '-2%',
    [theme.breakpoints.down('md')]: {
      fontSize: '28px',
      lineHeight: '36px'
    }
  },
  h4: {
    fontWeight: 700,
    fontSize: '28px',
    lineHeight: '36px',
    letterSpacing: '-2%',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      lineHeight: '32px'
    }
  },
  h5: {
    fontWeight: 700,
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '-2%',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '28px'
    }
  },
  h6: {
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '28px',
    letterSpacing: '-2%',
    [theme.breakpoints.down('md')]: {
      fontSize: '18px',
      lineHeight: '24px'
    }
  },

  // Paragraph Large to - Title (font-size - 18px)
  //  font-weight - 400
  title1: {
    fontWeight: 400,
    fontSize: '18px',
    lineHeight: '28px'
  },
  // font-weight - 500
  title2: {
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '28px'
  },
  // font-weight - 600
  title3: {
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '28px'
  },

  // Paragraph Medium to - Subtitle (font-size - 16px)
  //  font-weight - 400
  subtitle1: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px'
  },
  //  font-weight - 500
  subtitle2: {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '24px'
  },
  //  font-weight - 600
  subtitle3: {
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '24px'
  },

  // Paragraph Small to - Body (font-size - 14px)
  //  font-weight - 400
  body1: {
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px'
  },
  //  font-weight - 500
  body2: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px'
  },
  //  font-weight - 600
  body3: {
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px'
  },

  // Paragraph XSmall to - Caption (font-size - 12px)
  //  font-weight - 400
  caption1: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px'
  },
  //  font-weight - 500
  caption2: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '20px'
  },
  //  font-weight - 600
  caption3: {
    fontWeight: 600,
    fontSize: '12px',
    lineHeight: '20px'
  },

  // Paragraph XXSmall to - Default Caption (font-size - 10px)
  caption: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '20px'
  },
  overline: {
    letterSpacing: 1,
    fontWeight: 600,
    lineHeight: '20px'
  },
  button: {
    textTransform: 'capitalize'
  }
});

export default Typography;
