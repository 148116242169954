// material-ui
import { useTheme } from '@mui/material/styles';
import {
  createFilterOptions,
  Autocomplete,
  Box,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import { editExperience } from 'store/reducers/cv-builder';
import { useDispatch, useSelector } from 'store';
import { addJobs } from 'store/reducers/master';
import trimFc from 'utils/trimFc';

// assets
import { ArrowDown2, Calendar } from 'iconsax-react';

// types
import { CVExperienceProps } from 'types/cv-builder';
import { Typography } from '@mui/material';
import { EXP_END_DATE } from 'config';
import { format } from 'date-fns';

const filter = createFilterOptions<string>();

// @ts-ignore
const diffMonths = (d1: Date, d2) => {
  d1 = new Date(d1);
  d2 = new Date(d2);

  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

const validationSchema = yup.object({
  role: yup.string().trim().required('The job title is required').max(50, 'The job title can not exceed the length of 50 characters'),

  company: yup
    .string()
    .trim()
    .matches(
      /^[ A-Za-zäöüÄÖÜß0-9_'"/)(?&-.]*$/,
      `Only alphanumeric characters (also ÄäÖöÜüß) and special characters such as (_'"/)(?&-.) are allowed.`
    )
    .max(50, 'The length of the Company Name can not exceed 50 characters')
    .required('The Company Name is required'),
  startDate: yup.date().required('The start date is required'),
  endDate: yup.date().when('onGoing', {
    is: false,
    then: yup
      .date()
      .when('startDate', (start, schema) => start && schema.min(start, 'The end date must has to come after the start date'))
      .test(
        'endDate',
        'The end date must be at least 1 month apart from the start date',
        (started, yup) => diffMonths(yup.parent.startDate, started) >= 1
      )
  })
});

type Props = {
  toggleExperience: () => void;
  experience: CVExperienceProps;
  auto: boolean;
};

const AddExperience = ({ toggleExperience, experience, auto }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { roles } = useSelector((state) => state.master);
  const { id, profile } = useSelector((state) => state.cv);

  const formik = useFormik({
    initialValues: {
      id: experience.id,
      role: experience.role === '' && auto ? profile.jobTitle : experience.role,
      company: experience.company === '' && auto ? profile.jobCompany : experience.company,
      startDate: experience.startDate,
      endDate:
        experience.endDate && format(new Date(experience.endDate), 'yyyy-MM') === format(new Date(EXP_END_DATE), 'yyyy-MM')
          ? ''
          : experience.endDate,
      onGoing:
        experience.endDate && format(new Date(experience.endDate), 'yyyy-MM') === format(new Date(EXP_END_DATE), 'yyyy-MM') ? true : false
      // submit: null
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      let castValues;
      if (values.onGoing) {
        castValues = values;
      } else {
        castValues = validationSchema.cast(values);
      }

      const formData: CVExperienceProps = {
        id: values.id,
        role: castValues.role!,
        company: castValues.company!,
        startDate: values.startDate,
        endDate: values.onGoing ? EXP_END_DATE : values.endDate
      };

      !formData.id && delete formData.id;
      dispatch(editExperience({ experience: formData, id }));
      setSubmitting(false);
      resetForm();
      if (id) {
        toggleExperience();
      }
    }
  });

  let startMaxDate = new Date();
  startMaxDate.setMonth(startMaxDate.getMonth() - 4);

  let endMaxDate = new Date();
  // endMaxDate.setMonth(endMaxDate.getMonth() - 1);

  const handleAddRole = (jobName: string) => {
    dispatch(addJobs(jobName));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <form onSubmit={formik.handleSubmit} id="validation-forms">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              value={formik.values.role}
              disableClearable
              onChange={(event, newValue) => {
                const jobExist = roles.includes(newValue);
                if (!jobExist) {
                  const matchData = newValue.match(/"((?:\\.|[^"\\])*)"/);
                  formik.setFieldValue('role', matchData && matchData[1]);
                  if (matchData && matchData[1]) handleAddRole(matchData[1]);
                } else {
                  formik.setFieldValue('role', newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                const isExisting = options.some((option) => inputValue === option);
                if (inputValue !== '' && !isExisting) {
                  filtered.push(`Add "${inputValue}"`);
                }
                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              autoHighlight
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={roles}
              getOptionLabel={(option) => {
                let value = option;
                const jobExist = roles.includes(option);
                if (!jobExist) {
                  const matchData = option.match(/"((?:\\.|[^"\\])*)"/);
                  if (matchData && matchData[1]) value = matchData && matchData[1];
                }
                return value;
              }}
              renderOption={(props, option) => {
                return (
                  <Box component="li" {...props}>
                    {option}
                  </Box>
                );
              }}
              freeSolo
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="role"
                  error={formik.touched.role && Boolean(formik.errors.role)}
                  helperText={formik.touched.role && formik.errors.role && formik.errors.role}
                  placeholder="Select Job Title"
                  InputProps={{
                    ...params.InputProps,
                    sx: { bgcolor: 'grey.0' },
                    endAdornment: (
                      <InputAdornment style={{ pointerEvents: 'none', position: 'absolute', right: '10px' }} position="end">
                        <ArrowDown2 size={16} color={theme.palette.secondary.main} />
                      </InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="company"
              name="company"
              placeholder="Company Name"
              value={formik.values.company}
              onChange={trimFc(formik)}
              onBlur={formik.handleBlur}
              error={formik.touched.company && Boolean(formik.errors.company)}
              helperText={formik.touched.company && formik.errors.company}
              fullWidth
              InputProps={{
                sx: { bgcolor: 'grey.0' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container rowSpacing={{ xs: 2.5, sm: 4 }} columnSpacing={3}>
              <Grid item xs={12} md={6}>
                <MobileDateTimePicker
                  value={formik.values.startDate}
                  inputFormat="MM/yyyy"
                  views={['year', 'month']}
                  maxDate={startMaxDate}
                  defaultCalendarMonth={startMaxDate}
                  onChange={(date) => formik.setFieldValue('startDate', date)}
                  showToolbar={false}
                  DialogProps={{
                    sx: { '& .MuiPaper-root': { p: 1.25 } }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="cal-start-date"
                      name="startDate"
                      placeholder="Starting Date"
                      fullWidth
                      error={Boolean(formik.touched.startDate && formik.errors.startDate)}
                      helperText={formik.touched.startDate && formik.errors.startDate}
                      InputProps={{
                        sx: { bgcolor: 'grey.0' },
                        endAdornment: (
                          <InputAdornment style={{ pointerEvents: 'none', position: 'absolute', right: '10px' }} position="end">
                            <Calendar size={16} color={theme.palette.secondary.main} />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <MobileDateTimePicker
                    disabled={formik.values.onGoing}
                    value={formik.values.endDate}
                    inputFormat="MM/yyyy"
                    views={['year', 'month']}
                    onChange={(date) => formik.setFieldValue('endDate', date)}
                    showToolbar={false}
                    maxDate={endMaxDate}
                    defaultCalendarMonth={endMaxDate}
                    DialogProps={{
                      sx: { '& .MuiPaper-root': { p: 1.25 } }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="cal-end-date"
                        name="endDate"
                        placeholder="Finishing Date"
                        fullWidth
                        error={Boolean(formik.touched.endDate && formik.errors.endDate)}
                        helperText={formik.touched.endDate && formik.errors.endDate}
                        InputProps={{
                          sx: { bgcolor: 'grey.0' },
                          endAdornment: (
                            <InputAdornment style={{ pointerEvents: 'none', position: 'absolute', right: '10px' }} position="end">
                              <Calendar size={16} color={theme.palette.secondary.main} />
                            </InputAdornment>
                          )
                        }}
                      />
                    )}
                  />
                  <Box sx={{ bgcolor: 'background.paper', px: 2, width: 125, borderRadius: 1 }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.onGoing}
                          onChange={formik.handleChange}
                          id="onGoing"
                          name="onGoing"
                          color="success"
                          size="small"
                        />
                      }
                      label={<Typography variant="caption2">On Going</Typography>}
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between">
              <Button sx={{ mr: 2 }} size="small" color="error" onClick={() => formik.resetForm()}>
                Reset
              </Button>
              <Stack direction="row" spacing={2} alignItems="center">
                <Button
                  variant="dashed"
                  size="small"
                  color="error"
                  onClick={() => {
                    formik.resetForm();
                    toggleExperience();
                  }}
                >
                  Cancel
                </Button>
                <Button variant="contained" size="small" disableElevation disabled={formik.isSubmitting} type="submit">
                  Submit
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </LocalizationProvider>
  );
};

export default AddExperience;
