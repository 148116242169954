// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';

// types
import { SocketsStateProps } from 'types/sockets';

const initialState: SocketsStateProps = {
  isEstablishingConnection: false,
  isConnected: false
};

// ==============================|| SLICE - NOTIFICATIONS ||============================== //

const slice = createSlice({
  name: 'sockets',
  initialState,
  reducers: {
    startConnecting(state) {
      state.isEstablishingConnection = true;
    },

    connectionEstablished(state) {
      state.isConnected = true;
      state.isEstablishingConnection = true;
    }
  }
});

export default slice.reducer;

export const socketsActions = slice.actions;

export function startConnecting() {
  return () => {
    dispatch(slice.actions.startConnecting());
  };
}
