import { CVExperienceProps, CVQualificationProps } from './cv-builder';
import { JobsProps } from './jobs';
import { UserProfile } from './user';

export enum EmployeeStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  APPLIED = 'APPLIED',
  MATCH = 'MATCH',
  REVIEW = 'REVIEW',
  CONTRACTED = 'CONTRACTED',
  CONCLUDED = 'CONCLUDED',
  DELETED = 'DELETED'
}

export enum ReviewApplicantStatus {
  NEW = 'NEW',
  REVIEW = 'REVIEW',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  INVITED = 'INVITED',
  PROJECT = 'PROJECT',
  CANCELLED = 'CANCELLED'
}

export type MatchesProps = {
  id: string;
  matchId: string;
  cvId: string;
  jdId: string;
  status: ReviewApplicantStatus;
  acceptedAt: string | null;
  createdAt: string;
  updatedAt: string;
  jd: JobsProps;
  cv: EmployeesProps;
  cvProject: boolean;
  jdProject: boolean;
};

// emplyee details list
export type EmployeesProps = {
  id: string;
  image?: string;
  firstName: string;
  lastName: string;
  jobTitle: string;
  jobCompany: string;
  email?: string;
  biography?: string;
  capacity: string;
  workLocation: string;
  location: string;
  startDate?: string;
  endDate?: string;
  rate: string;
  totalExperienceDays?: string;
  timeSlot: string;
  jobTags: string[];
  essentialTags?: string[];
  desirableTags?: string[];
  culturalTags?: string[];
  status: EmployeeStatus;
  underReview?: boolean;
  userId?: string;
  createdAt: string;
  updatedAt?: string;
  daysAvailable?: string;
  experiences?: CVExperienceProps[];
  qualifications?: CVQualificationProps[];
  experience?: string | number | null;
  workTime?: string | number | null;
  matches?: MatchesProps[];
  appliedAt?: string;
  favourite?: boolean;
  user?: UserProfile;
  companyLocation?: string;
  logo?: string;
  companyEmail?: string;
};

export type EmployeeFilterProps = {
  limit?: number;
  offset?: number;
  search?: string;
  sortField?: string;
  sortOrder?: string;
  capacity?: string;
  rate?: string;
  workLocation?: string;
  experience?: string;
  daysAvailable?: string;
};

export interface EmployeeStateProps {
  employees: EmployeesProps[];
  applicationEmployees: EmployeesProps[];
  matchEmployees: EmployeesProps[];
  projectEmployees: EmployeesProps[];
  employee: EmployeesProps | null;
  searchedEmployees: EmployeesProps[];
  initialEmployees: EmployeesProps[];
  filteredEmployees: EmployeesProps[];
  error: object | string | null;
  filter: EmployeeFilterProps;
}
