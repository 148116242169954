// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
// import { BE_URL } from 'config';
// import { Axios } from 'utils/axios';
import axios from 'utils/axios';
import { dispatch } from '../index';

// types
import { DocumentsStateProps } from 'types/documents';

const initialState: DocumentsStateProps = {
  error: null,
  documents: []
};

// ==============================|| SLICE - DOCUMENTS ||============================== //

const slice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    // get documents
    getDocuments(state, action) {
      state.documents = action.payload;
    }
  }
});

// reducer
export default slice.reducer;

export function getDocuments() {
  return async () => {
    try {
      // const response = await Axios.get(`${BE_URL}/cv/${id}`);
      const response = await axios.get('/api/documents/list');
      dispatch(slice.actions.getDocuments(response.data.documents));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
