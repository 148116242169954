import { useCallback, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';

// third-party
import { format } from 'date-fns';
import CurrencyFormat from 'react-currency-format';
import { PDFDownloadLink } from '@react-pdf/renderer';

// project imports
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import ReadMore from 'components/@extended/ReamMore';
import Dot from 'components/@extended/Dot';
import { setModal } from 'store/reducers/master';
import { dispatch, useSelector } from 'store';

// aseets
import { Add, DocumentDownload, ExportCurve, Heart, Global, Award, Calendar, ArrowDown2, Tag, Element3, Profile } from 'iconsax-react';
import { ModalType } from 'types/master';
import ExportPDFView from 'sections/job-board/export-pdf-view';

import { JobsProps, JobStatus } from 'types/jobs';
import { jobApply, setFavouritesJob } from 'store/reducers/jobs';
import debounce from 'utils/debounce';
import { EmployeesProps } from 'types/employees';
import { UserRole } from 'types/user';
import useAuth from 'hooks/useAuth';
import parseMonths from 'utils/parseMonths';

// function getColor(match: number) {
//   if (match >= 80) return 'success.main';
//   if (match >= 50) return 'warning.main';
//   return 'error';
// }

interface Props {
  job: JobsProps;
  closeModal: () => void;
  setFailed: (faild: boolean) => void;
  setConfirm: (confirm: boolean) => void;
}

const FindPreviewPopup = ({ job, closeModal, setFailed, setConfirm }: Props) => {
  const theme = useTheme();
  const { user } = useAuth();

  const { matchEmployees } = useSelector((state) => state.employees);

  const [isFavourite, setIsFavourite] = useState<boolean>(job && job?.favourite !== undefined ? job.favourite : false);
  const [iconHover, setIconHover] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<string | null>(null);

  const isCreator = user?.id === job.userId || user?.role === UserRole.admin;
  const isAccess = isCreator || job.status === JobStatus.CONTRACTED;

  const handleChange = (isFavourite: boolean, id: string) => {
    dispatch(setFavouritesJob(id, isFavourite));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(handleChange), []);

  const handleJobApply = () => {
    if (!selectedEmployee && !job.id) return;
    if (job.id) {
      dispatch(jobApply(job.id!, selectedEmployee!)).then((response) => {
        setFailed(!response);
        setConfirm(true);
      });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack
          spacing={1}
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          direction={{ xs: 'column', sm: 'row' }}
        >
          <List sx={{ width: 1, p: 0 }}>
            <ListItem disablePadding>
              <ListItemAvatar sx={{ mr: 1 }}>
                <Avatar
                  sx={{ width: 56, height: 56 }}
                  color="secondary"
                  variant="rounded"
                  {...(isAccess && job?.logo && { alt: job?.jobTitle, src: job?.logo })}
                >
                  <Element3 size={40} variant="Bold" color="black" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="h6" flexWrap="wrap">
                    {job?.jobTitle}
                  </Typography>
                }
                secondary={
                  <>
                    {isAccess ? (
                      <Typography variant="subtitle1" color="secondary" flexWrap="wrap">
                        {job?.jobCompany}
                      </Typography>
                    ) : (
                      <Skeleton height={24} width={120} animation={false} />
                    )}
                  </>
                }
              />
            </ListItem>
          </List>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <IconButton
              color="secondary"
              onMouseEnter={() => setIconHover(!iconHover)}
              onMouseLeave={() => setIconHover(!iconHover)}
              onClick={() => {
                optimizedFn(!isFavourite, job.id);
                setIsFavourite(!isFavourite);
              }}
            >
              <Heart
                variant={isFavourite ? 'Bold' : 'Outline'}
                size={20}
                color={isFavourite || iconHover ? theme.palette.primary.main : theme.palette.text.primary}
              />
            </IconButton>
            <IconButton color="secondary" onClick={() => dispatch(setModal({ type: ModalType.jdShare, id: job.id }))}>
              <ExportCurve size={20} color={theme.palette.text.primary} />
            </IconButton>
            <PDFDownloadLink document={<ExportPDFView job={job} />} fileName={`JD-${job.jobCompany}-${job.jobTitle}.pdf`}>
              <IconButton color="secondary">
                <DocumentDownload size={20} color={theme.palette.text.primary} />
              </IconButton>
            </PDFDownloadLink>
            <IconButton color="secondary" onClick={closeModal}>
              <Add size={20} color={theme.palette.text.primary} style={{ transform: 'rotate(45deg)' }} />
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={7} md={8}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography color="secondary.dark">{job.capacity}</Typography>
              <Dot color="secondary" size={6} />
              <Typography color="secondary.dark">{job.workLocation}</Typography>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <ReadMore variant="subtitle1">{job?.jobDescription!}</ReadMore>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={3} alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <Global size="20" color={theme.palette.grey[700]} />
                <Stack direction="row" alignItems="center" spacing={0.25}>
                  {isAccess ? job.jobCompany : <Skeleton width={50} height={16} animation={false} sx={{ mr: 0.5 }} />} -{' '}
                  <Typography color="secondary.dark">{job.location}</Typography>
                </Stack>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Award size="20" color={theme.palette.grey[700]} />
                <Typography color="secondary.dark" sx={{ textTransform: 'capitalize' }}>
                  {job.experience === 'middle' ? 'Intermediate' : job.experience}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Calendar size="20" color={theme.palette.grey[700]} />
                <Typography color="secondary.dark">
                  {job.startDate && job.endDate ? `>  ${parseMonths(Number(job.daysAvailable))}` : '-'}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <Tag size="20" color={theme.palette.grey[700]} />
                <Typography color="secondary.dark">
                  {job.rate ? (
                    <>
                      <CurrencyFormat
                        fixedDecimalScale
                        value={
                          job.timeSlot === 'Hourly'
                            ? (Math.round(10 * (parseFloat(job.rate) * 7.5)) / 10) % 1 !== 0
                              ? (Math.round(10 * (parseFloat(job.rate) * 7.5)) / 10).toFixed(2)
                              : Math.round(10 * (parseFloat(job.rate) * 7.5)) / 10
                            : job.timeSlot === 'Daily'
                            ? (Math.round(10 * Number(job.rate)) / 10) % 1 !== 0
                              ? (Math.round(10 * Number(job.rate)) / 10).toFixed(2)
                              : Math.round(10 * Number(job.rate)) / 10
                            : job.timeSlot === 'Monthly'
                            ? (Math.round(10 * (parseFloat(job.rate) / 21.5)) / 10) % 1 !== 0
                              ? (Math.round(10 * (parseFloat(job.rate) / 21.5)) / 10).toFixed(2)
                              : Math.round(10 * (parseFloat(job.rate) / 21.5)) / 10
                            : ''
                        }
                        displayType="text"
                        thousandSeparator
                        prefix="£"
                      />{' '}
                      / day
                    </>
                  ) : (
                    '-'
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Starting Date</Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {job.startDate && format(new Date(job.startDate!), 'dd LLL yyyy')}
                    </Typography>
                  }
                  size="small"
                />
                <Typography color="secondary.dark">-</Typography>
                <Chip
                  variant="outlined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {job.endDate ? format(new Date(job.endDate!), 'dd LLL yyyy') : 'Present'}
                    </Typography>
                  }
                  size="small"
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Job Tags</Typography>
              <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
                {job.jobTags &&
                  job.jobTags.map((item: string, index: number) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {item}
                        </Typography>
                      }
                      size="small"
                    />
                  ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Essential Skills</Typography>
              <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
                {job.essentialTags &&
                  job.essentialTags.map((item: string, index: number) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {item}
                        </Typography>
                      }
                      size="small"
                    />
                  ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Desireable Skills</Typography>
              <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
                {job.desirableTags &&
                  job.desirableTags.length > 0 &&
                  job.desirableTags.map((item: string, index: number) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {item}
                        </Typography>
                      }
                      size="small"
                    />
                  ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Cultural Preference Keywords</Typography>
              <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
                {job.culturalTags &&
                  job.culturalTags.map((item: string, index: number) => (
                    <Chip
                      key={index}
                      variant="outlined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {item}
                        </Typography>
                      }
                      size="small"
                    />
                  ))}
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5} md={4}>
        <MainCard content={false} border>
          <Box sx={{ p: 2.5 }}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body3">Date Posted</Typography>
                  <Box>
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {job?.updatedAt && format(new Date(job?.updatedAt), 'dd LLL yyyy')}
                        </Typography>
                      }
                      size="small"
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body3">Project Status</Typography>
                  {isAccess && job.status === JobStatus.DRAFT && (
                    <Box>
                      <Chip color="secondary" sx={{ bgcolor: 'secondary.darker' }} label="Draft" size="small" />
                    </Box>
                  )}
                  {isAccess && job.status === JobStatus.PUBLISHED && (
                    <Box>
                      <Chip color="info" label="Publish" size="small" />
                    </Box>
                  )}
                  {!isAccess && <Typography variant="body2">-</Typography>}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body3">Project ID</Typography>
                  <Box>
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {job?.id}
                        </Typography>
                      }
                      size="small"
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle3">Project Host</Typography>
              </Grid>
              <Grid item xs={12}>
                <List sx={{ width: 1, p: 0 }}>
                  <ListItem disablePadding>
                    <ListItemAvatar>
                      <Avatar
                        color="secondary"
                        {...(isAccess && job?.user?.company?.logo && { alt: job.jobCompany, src: job?.user?.company?.logo as string })}
                      >
                        <Profile size={28} variant="Bold" color="black" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ m: 0 }}
                      primary={
                        <>
                          {isAccess ? (
                            <Typography variant="body3" flexWrap="wrap">
                              {job.user?.company?.companyName}
                            </Typography>
                          ) : (
                            <Skeleton height={24} width={120} animation={false} />
                          )}
                        </>
                      }
                      secondary={
                        <Typography variant="body2" color="secondary.dark" flexWrap="wrap">
                          {job?.user?.company?.country ? (
                            job?.user?.company?.country
                          ) : (
                            <Skeleton height={16} width={80} animation={false} />
                          )}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body3">Email Address</Typography>
                  <Box>
                    <Chip
                      variant="outlined"
                      label={
                        <>
                          {isAccess ? (
                            <Typography variant="body2" color="secondary.dark">
                              {job?.user?.email}
                            </Typography>
                          ) : (
                            <Skeleton height={20} width={150} animation={false} />
                          )}
                        </>
                      }
                      size="small"
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </MainCard>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Autocomplete
          id="employee"
          sx={{
            '& .MuiAutocomplete-endAdornment': {
              top: `calc(50% - 10px)`,
              '& .MuiButtonBase-root': { width: 22, height: 22, ml: 1 }
            }
          }}
          value={matchEmployees.find((option: EmployeesProps) => option.id === selectedEmployee)}
          onChange={(event, value) => {
            setSelectedEmployee(value?.id || null);
          }}
          options={matchEmployees}
          fullWidth
          autoHighlight
          getOptionLabel={(option) => (selectedEmployee === null ? 'Choose your employee' : `${option.firstName} ${option.lastName}`)}
          popupIcon={<ArrowDown2 size={18} />}
          isOptionEqualToValue={(option) => option.id === selectedEmployee}
          renderOption={(props, option) => (
            <Box component="li" sx={{ mx: 1, borderBottom: `1px solid ${theme.palette.divider}` }} {...props}>
              {option.id && option.id !== '' && (
                <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ width: 1, py: 1 }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar size="sm" src={option?.image} />
                    <Typography variant="body3">
                      {option.firstName} {option.lastName}
                    </Typography>
                  </Stack>
                  {/* <Typography color={getColor(option.match)}>{option.match}% Match</Typography> */}
                </Stack>
              )}
            </Box>
          )}
          filterOptions={(options, params) =>
            options.filter(
              (option) => `${option.firstName} ${option.lastName}`.toLowerCase().includes(params.inputValue.toLowerCase())
              // `${option.firstName} ${option.lastName} ${option.match}`.toLowerCase().includes(params.inputValue.toLowerCase())
            )
          }
          renderInput={(params) => {
            const selected = matchEmployees.find((option: EmployeesProps) => option.id === selectedEmployee);

            return (
              <TextField
                {...params}
                name="employess"
                placeholder="Choose your employee"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {selected && selected.image !== '' && <img loading="lazy" width="20" src={selected.image} alt="" />}
                      {!selected && <Avatar color="secondary" size="xs" />}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {/* {selected && <Typography color={getColor(selected.match)}>{selected.match}% Match</Typography>} */}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password' // disable autocomplete and autofill
                }}
              />
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Button variant="contained" fullWidth disabled={!selectedEmployee} onClick={handleJobApply}>
          Apply Job
        </Button>
      </Grid>
    </Grid>
  );
};

export default FindPreviewPopup;
