import { MatchesProps } from './employees';
import { UserProfile } from './user';

export enum JobStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  REQUEST = 'REQUEST',
  MATCH = 'MATCH',
  REVIEW = 'REVIEW',
  CONTRACTED = 'CONTRACTED',
  CONCLUDED = 'CONCLUDED',
  DELETED = 'DELETED'
}

// jobs details list
export type JobsProps = {
  id: string;
  jobTitle: string;
  jobCompany: string;
  jobDescription?: string;
  companyDescription?: string;
  capacity: string;
  location: string;
  workLocation: string;
  startDate?: string;
  endDate?: string;
  rate: string;
  timeSlot: string;
  jobTags: string[];
  essentialTags?: string[];
  desirableTags?: string[];
  culturalTags?: string[];
  underReview?: boolean;
  status: JobStatus;
  experience?: string | number | null;
  userId?: string;
  createdAt?: string;
  updatedAt?: string;
  daysAvailable?: number;
  logo?: string;
  workTime?: string | number | null;
  matches?: MatchesProps[];
  appliedAt?: string;
  favourite?: boolean;
  appliedApplicants?: number;
  user?: UserProfile;
};

export interface ReviewApplicantsProps extends JobsProps {
  jdId: string;
  counter: number;
}

export type JoobFilterProps = {
  limit?: number;
  offset?: number;
  search?: string;
  sortField?: string;
  sortOrder?: string;
  capacity?: string;
  rate?: string;
  workLocation?: string;
  experience?: string;
  location?: string;
  daysAvailable?: string;
};

export interface JobsStateProps {
  jobs: JobsProps[];
  matchJobs: JobsProps[];
  applicants: ReviewApplicantsProps[];
  job: JobsProps | null;
  searchedJobs: JobsProps[] | null;
  initialJobs: JobsProps[];
  filteredJobs: JobsProps[];
  error: object | string | null;
  filter: JoobFilterProps;
}
