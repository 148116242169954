// assets
import { Briefcase, People } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { Briefcase, People };

// ==============================|| MENU ITEMS - OVERVIEW ||============================== //

const collaborate: NavItemType = {
  id: 'group-collaborate',
  type: 'group',
  title: 'Collaborate',
  children: [
    {
      id: 'find-job',
      title: 'Find Jobs',
      type: 'item',
      url: 'find-jobs',
      icon: icons.Briefcase,
      breadcrumbs: false
    },
    {
      id: 'find-candidates',
      title: 'Find Candidates',
      type: 'item',
      url: 'find-candidates',
      icon: icons.People,
      breadcrumbs: false
    }
  ]
};

export default collaborate;
