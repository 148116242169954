import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  Paper,
  Popper,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';

// project import
import { LOGIN_URL, URL_PREFIX } from 'config';
import useAuth from 'hooks/useAuth';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import MainCard from 'components/MainCard';
import SimpleBar from 'components/third-party/SimpleBar';
import Transitions from 'components/@extended/Transitions';

// assets
import { ArrowDown2, ArrowUp2, Building4, CloseCircle, Copy, LogoutCurve, Setting2, TickCircle } from 'iconsax-react';
import { UserStatus } from 'types/user';
import { capitalize } from 'lodash';

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const [copy, setCopy] = useState<string | null | undefined>();

  const handleLogout = async () => {
    try {
      logout();
      navigate(`${URL_PREFIX}/${LOGIN_URL}`, {
        state: {
          from: ''
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const editHandler = () => {
    navigate(`${URL_PREFIX}/account-settings`, { replace: true });
    setOpen(false);
  };

  const iconProps = {
    size: 18,
    color: theme.palette.text.primary
  };

  const companyAddress = user?.company
    ? `${user?.company?.address1} ${user?.company?.address2}, ${user?.company?.city}, ${user?.company?.county}, ${user?.company?.country}`
    : '';

  return (
    <Box sx={{ flexShrink: 0, ml: { xs: 1.5, md: 2.5 } }}>
      <Chip
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        onDelete={handleToggle}
        avatar={<Avatar alt={user?.firstName} src={user?.avatar as string} color="primary" type="filled" />}
        deleteIcon={open ? <ArrowUp2 {...iconProps} /> : <ArrowDown2 {...iconProps} />}
        variant={open ? 'combined' : 'outlined'}
        label={
          <Stack sx={{ display: { xs: 'none', md: 'flex' } }}>
            {user && (user?.firstName || user?.lastName) ? (
              <Typography variant="body2">
                {user?.firstName} {user?.lastName}
              </Typography>
            ) : (
              <Skeleton />
            )}
            {user && user?.company && user?.company?.companyName ? (
              <Typography color="secondary.700">{user?.company?.companyName}</Typography>
            ) : (
              <Skeleton />
            )}
          </Stack>
        }
        sx={{
          height: 48,
          borderRadius: 25,
          borderColor: theme.palette.divider,
          p: { xs: '3px', md: '4px 16px 4px 4px' },
          '& .MuiChip-avatar': { width: 40, height: 40, ml: 0, mr: { xs: 0, md: -0.75 }, color: '#fff', fontSize: '1.25rem' },
          '& .MuiChip-deleteIcon': { display: { xs: 'none', md: 'flex' }, ml: 1.25 },
          '& .MuiChip-label': { px: { xs: 0, md: 1.5 } }
        }}
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.xLarge,
                  width: 430,
                  minWidth: 240,
                  maxWidth: 430,
                  [theme.breakpoints.down('sm')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard content={false} elevation={0} border={false} sx={{ height: 'auto' }}>
                    <SimpleBar
                      sx={{
                        maxHeight: `calc(100vh - 160px)`,
                        '& .simplebar-content': {
                          display: 'flex',
                          flexDirection: 'column'
                        }
                      }}
                    >
                      <Box sx={{ p: 3 }}>
                        <Stack spacing={2.5}>
                          <Stack direction="row" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">Profile</Typography>
                            <Stack direction="row" alignItems="center" spacing={1}>
                              <Button
                                onClick={editHandler}
                                color="primary"
                                size="small"
                                endIcon={<Setting2 size={18} color={theme.palette.secondary.main} />}
                              >
                                Account Settings
                              </Button>
                            </Stack>
                          </Stack>
                          <Divider />
                          <Box>
                            <Grid container rowSpacing={2}>
                              <Grid item xs={12}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                  <Stack direction="row" spacing={1.5}>
                                    <Avatar alt={user?.firstName} src={user?.avatar as string} color="primary" type="filled" />
                                    <Stack>
                                      <Typography variant="body2">
                                        {user?.firstName} {user?.lastName}
                                      </Typography>
                                      <Typography color="secondary.dark">{capitalize(user?.role!)}</Typography>
                                    </Stack>
                                  </Stack>
                                  <Chip
                                    sx={{
                                      height: '24px',
                                      '& .MuiChip-label': {
                                        pl: 1.25,
                                        color: user?.company?.decisionMaker ? 'success.dark' : 'error.dark',
                                        fontWeight: 500
                                      }
                                    }}
                                    color={user?.company?.decisionMaker ? 'success' : 'error'}
                                    variant="light"
                                    label={user?.company?.decisionMaker ? 'Decision Maker' : 'Not a Decision Maker'}
                                    icon={
                                      user?.company?.decisionMaker ? (
                                        <TickCircle size={16} color={theme.palette.success.main} />
                                      ) : (
                                        <CloseCircle size={16} color={theme.palette.error.main} />
                                      )
                                    }
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel>Email Address</InputLabel>
                                <TextField
                                  sx={{ '& .MuiInputBase-root': { pr: 0 } }}
                                  fullWidth
                                  value={user?.email}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip title={copy === user?.email ? 'copied' : 'copy'} placement="left">
                                          <IconButton
                                            color="secondary"
                                            onClick={() => {
                                              setCopy(user?.email);
                                              navigator.clipboard.writeText(
                                                user?.email ? user.email : 'Please update your profile, Email is not availabel'
                                              );
                                            }}
                                          >
                                            <Copy size={18} />
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel>Contact Number</InputLabel>
                                <TextField
                                  sx={{ '& .MuiInputBase-root': { pr: 0 } }}
                                  fullWidth
                                  defaultValue={user?.company ? `${user?.company?.phoneCode} ${user?.company?.phone}` : ''}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip title={copy === user?.company?.phoneCode ? 'copied' : 'copy'} placement="left">
                                          <IconButton
                                            color="secondary"
                                            onClick={() => {
                                              setCopy(user?.company?.phoneCode);
                                              navigator.clipboard.writeText(`${user?.company?.phoneCode} ${user?.company?.phone}`);
                                            }}
                                          >
                                            <Copy size={18} />
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                              {/* <Grid item xs={12}>
                                <InputLabel>My LinkedIn Page</InputLabel>
                                <TextField
                                  sx={{ '& .MuiInputBase-root': { pr: 0 } }}
                                  fullWidth
                                  defaultValue="www.linkedin.com/in/microsoft/"
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          color="secondary"
                                          onClick={() => navigator.clipboard.writeText('www.linkedin.com/in/microsoft/')}
                                        >
                                          <Copy size={18} />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid> */}
                            </Grid>
                          </Box>
                          <Divider />
                          <Box>
                            <Grid container rowSpacing={2}>
                              <Grid item xs={12}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between">
                                  <Stack direction="row" spacing={1.5} alignItems="center">
                                    <Avatar
                                      variant="rounded"
                                      {...(user?.company?.logo && { alt: user?.company?.companyName, src: user?.company?.logo as string })}
                                    >
                                      <Building4 size={28} variant="Bold" color="black" />
                                    </Avatar>
                                    {user?.company?.companyName && (
                                      <Typography variant="body2">
                                        {(user?.company?.companyName).substring(0, 16)}
                                        {user?.company?.companyName.length > 16 && '...'}
                                      </Typography>
                                    )}
                                  </Stack>
                                  <Chip
                                    sx={{
                                      height: '24px',
                                      '& .MuiChip-label': {
                                        pl: 1.25,
                                        color: user?.status === UserStatus.verified ? 'success.dark' : 'error.dark',
                                        fontWeight: 500
                                      }
                                    }}
                                    color={user?.status === UserStatus.verified ? 'success' : 'error'}
                                    variant="light"
                                    label={capitalize(user?.status)}
                                    icon={
                                      <TickCircle
                                        size={16}
                                        color={user?.status === UserStatus.verified ? theme.palette.success.main : theme.palette.error.main}
                                      />
                                    }
                                  />
                                </Stack>
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel>Company Address</InputLabel>
                                <TextField
                                  sx={{ '& .MuiInputBase-root': { pr: 0 } }}
                                  fullWidth
                                  defaultValue={companyAddress.trim()}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip title={copy === companyAddress.trim() ? 'copied' : 'copy'} placement="left">
                                          <IconButton
                                            color="secondary"
                                            onClick={() => {
                                              setCopy(companyAddress.trim());
                                              navigator.clipboard.writeText(companyAddress.trim());
                                            }}
                                          >
                                            <Copy size={18} />
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <InputLabel>Company Website</InputLabel>
                                <TextField
                                  sx={{ '& .MuiInputBase-root': { pr: 0 } }}
                                  fullWidth
                                  defaultValue={user?.company?.website}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <Tooltip title={copy === user?.company?.website ? 'copied' : 'copy'} placement="left">
                                          <IconButton
                                            color="secondary"
                                            onClick={() => {
                                              setCopy(user?.company?.website);
                                              navigator.clipboard.writeText(`${user?.company?.website}`);
                                            }}
                                          >
                                            <Copy size={18} />
                                          </IconButton>
                                        </Tooltip>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid>
                              {/* <Grid item xs={12}>
                                <InputLabel>Company LinkedIn Page</InputLabel>
                                <TextField
                                  sx={{ '& .MuiInputBase-root': { pr: 0 } }}
                                  fullWidth
                                  defaultValue="www.linkedin.com/in/microsoft/"
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <IconButton
                                          color="secondary"
                                          onClick={() => navigator.clipboard.writeText('www.linkedin.com/in/microsoft/')}
                                        >
                                          <Copy size={18} />
                                        </IconButton>
                                      </InputAdornment>
                                    )
                                  }}
                                />
                              </Grid> */}
                            </Grid>
                          </Box>
                          <Divider />

                          <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="flex-end"
                            spacing={{ xs: 2, md: 4 }}
                            sx={{ width: '100%' }}
                          >
                            {/* <Stack spacing={0.25} sx={{ width: '100%' }}>
                              <Box>
                                <Grid container rowSpacing={1} alignItems="center">
                                  <Grid item xs={6}>
                                    <Typography variant="caption1" color="secondary">
                                      Weekly hiring request
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={0.5}>
                                      <LinearProgress sx={{ width: '50%' }} variant="determinate" value={50} color="success" />
                                      <Typography variant="caption2">
                                        <Typography variant="caption2">15</Typography>
                                        <Typography variant="caption2" color="secondary.300">
                                          {' '}
                                          / 30
                                        </Typography>
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Box>
                              <Box>
                                <Grid container rowSpacing={1} alignItems="center">
                                  <Grid item xs={6}>
                                    <Typography variant="caption1" color="secondary">
                                      Weekly loaning request
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={0.5}>
                                      <LinearProgress sx={{ width: '50%' }} variant="determinate" value={20} color="error" />
                                      <Typography variant="caption2">
                                        <Typography variant="caption2">6</Typography>
                                        <Typography variant="caption2" color="secondary.300">
                                          {' '}
                                          / 30
                                        </Typography>
                                      </Typography>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Stack> */}
                            <Button
                              variant="dashed"
                              color="error"
                              fullWidth
                              sx={{ whiteSpace: 'nowrap' }} // width: 140,
                              startIcon={<LogoutCurve size={18} />}
                              onClick={handleLogout}
                            >
                              Sign out
                            </Button>
                          </Stack>
                        </Stack>
                      </Box>
                    </SimpleBar>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
