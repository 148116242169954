// material-ui
import { Theme } from '@mui/material/styles';

// ==============================|| OVERRIDES - ICON BUTTON ||============================== //

export default function IconButton(theme: Theme) {
  return {
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          padding: 8
        },
        sizeLarge: {
          width: theme.spacing(5.5),
          height: theme.spacing(5.5)
        },
        sizeMedium: {
          width: 40.67,
          height: 40.67
        },
        sizeSmall: {
          width: theme.spacing(4),
          height: theme.spacing(4)
        },
        sizeExtraSmall: {
          padding: theme.spacing(0.25),
          width: theme.spacing(3),
          height: theme.spacing(3)
        }
      }
    }
  };
}
