import {
  CVSkillsProps,
  CVExperienceProps,
  CVQualificationProps,
  CVProfileProps,
  CVCalculateProps,
  CVJobProps
} from './../types/cv-builder';

import * as yup from 'yup';

const diffDays = (date: any, otherDate: any) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

// const diffMonths = (d1: any, d2: any) => {
//   d1 = new Date(d1);
//   d2 = new Date(d2);

//   var months;
//   months = (d2.getFullYear() - d1.getFullYear()) * 12;
//   months -= d1.getMonth();
//   months += d2.getMonth();
//   return months <= 0 ? 0 : months;
// };

const profileSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .matches(/^[aA-zZäöüÄÖÜß0\s-]+$/, 'Only alphabets are allowed, with letters (ÄäÖöÜüß)')
    .max(50, 'First Name must be at most 50 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .trim()
    .matches(/^[aA-zZäöüÄÖÜß0\s-]+$/, 'Only alphabets are allowed, with letters (ÄäÖöÜüß)')
    .max(50, 'Last Name must be at most 50 characters')
    .required('Last Name is required'),
  jobTitle: yup.string().trim().required('Current Job Title is required').max(50, 'Current Job Title must be at most 50 characters'),
  jobCompany: yup
    .string()
    .trim()
    .matches(/^[ A-Za-zäöüÄÖÜß0-9_'"/)(?&-.]*$/, `Only alphanumeric are allowed with special characters (_'"/)(?&-.) and letters (ÄäÖöÜüß)`)
    .max(50, 'Company Name must be at most 50 characters')
    .required('Company Name is required'),
  email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
  biography: yup.string().trim().max(1500, 'Biography must be at most 500 characters')
});

const jobInfoSchema = yup.object({
  capacity: yup.string().trim().required('Capacity is required'),
  workLocation: yup.string().trim().required('Work location is required'),
  location: yup
    .string()
    .trim()
    .required('Search location is required')
    .matches(/^[a-z\d\-_\s]+$/i, 'Only alphanumerics are allowed')
    .max(50, 'Search location must be at most 50 characters'),

  endDate: yup
    .date()
    .when('startDate', (start, schema) => start && schema.min(start, 'End date must be later than start date'))
    .test(
      'endDate',
      'End date must be at least 2 weeks after staring date',
      (started, yup) => diffDays(yup.parent.startDate, started) > 13
    ),
  startDate: yup.date().required('Start date is required'),
  jobTags: yup
    .array()
    .of(yup.string().trim().required('Leading spaces found in your tag').max(50, 'Job tag field must be at most 50 characters'))
    .required('Job tag selection is required')
    .min(3, 'Job tags field must have at least 3 items')
});

const skillsSchema = yup.object({
  essentialTags: yup
    .array()
    .of(yup.string().trim().required('Leading spaces found in your tag').max(50, 'Essential tag field must be at most 50 characters'))
    .required('Essential tag selection is required')
    .min(3, 'Essential tags field must have at least 3 items'),
  culturalTags: yup
    .array()
    .of(yup.string().trim().required('Leading spaces found in your tag').max(50, 'Cultural preference field must be at most 50 characters'))
    .required('Cultural preference selection is required')
    .min(3, 'Cultural preference field must have at least 3 items')
});

const experienceSchema = yup.array().of(
  yup.object({
    role: yup
      .string()
      .trim()
      .required('Role is required')
      .matches(/^[a-z\d\-/#_\s]+$/i, 'Only alphanumerics are allowed')
      .max(50, 'Role must be at most 50 characters'),

    company: yup
      .string()
      .trim()
      .matches(
        /^[ A-Za-zäöüÄÖÜß0-9_'"/)(?&-.]*$/,
        `Only alphanumeric are allowed with special characters (_'"/)(?&-.) and letters (ÄäÖöÜüß)`
      )
      .max(50, 'Company Name must be at most 50 characters')
      .required('Company Name is required'),
    startDate: yup.date().required('Start date is required'),
    endDate: yup.date().when('startDate', (start, schema) => start && schema.min(start, 'End date must be later than start date'))
  })
);

const qualificatioSchema = yup.array().of(
  yup.object({
    degree: yup
      .string()
      .trim()
      .required('Degree is required')
      .matches(/^[a-z\d\-/#_\s]+$/i, 'Only alphanumerics are allowed')
      .max(50, 'Degree must be at most 50 characters'),

    institute: yup
      .string()
      .trim()
      .matches(/^[a-z\d\-_\s]+$/i, 'Only alphanumerics are allowed')
      .max(50, 'Institute Name must be at most 50 characters')
      .required('Institute Name is required'),
    endDate: yup.date().required('Start date is required')
  })
);

const rateSchema = yup.object({
  rate: yup
    .number()
    .required('Offered rate is required')
    .typeError('Offered rate must specify a numeric value.')
    // @ts-ignore
    .test('rate', 'Offered rate field must have 2 digits after decimal or less', (number) => /^\d+(\.\d{1,2})?$/.test(number))
    .when('timeSlot', (timeSlot, schema) => {
      let minVal = 100;
      let maxVal = 7500;

      if (timeSlot === 'Hourly') {
        minVal = 12.5;
        maxVal = 667;
      }

      if (timeSlot === 'Monthly') {
        minVal = 2170;
        maxVal = 160000;
      }

      return schema.min(minVal, `${timeSlot} offer rate must be > ${minVal}`).max(maxVal, `${timeSlot} offer rate must be < ${maxVal}`);
    }),
  timeSlot: yup.string().trim().required('Slot is required')
});

export const validateCV = async (
  profile: CVProfileProps,
  calculate: CVCalculateProps,
  jobInfo: CVJobProps,
  skills: CVSkillsProps,
  experiences: CVExperienceProps[],
  qualifications: CVQualificationProps[]
) => {
  const validProfile = await profileSchema.isValid(profile);
  const validJobInfo = await jobInfoSchema.isValid(jobInfo);
  const validSkills = await skillsSchema.isValid(skills);
  const validExperiences = await experienceSchema.isValid(experiences);
  const validQualification = await qualificatioSchema.isValid(qualifications);
  const validRate = await rateSchema.isValid(calculate);

  return validProfile && validJobInfo && validSkills && (validExperiences || validQualification) && validRate;
};
