// project import
import Routes from 'routes';
import ThemeCustomization from 'themes';
import ScrollTop from 'components/ScrollTop';
import Sockets from 'components/Sockets';
import Snackbar from 'components/@extended/Snackbar';
import ModalProvider from 'pages/modal';

// auth provider
import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';

// third-party (use for export pdf)
import { Buffer } from 'buffer';
global.Buffer = Buffer;

// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (
  <AuthProvider>
    <Sockets>
      <ThemeCustomization>
        <ScrollTop>
          <>
            <Routes />
            <ModalProvider />
            <Snackbar />
          </>
        </ScrollTop>
      </ThemeCustomization>
    </Sockets>
  </AuthProvider>
);

export default App;
