// third-party
import { createSlice } from '@reduxjs/toolkit';

// types
import { NotificationStateProps } from 'types/notifications';

const initialState: NotificationStateProps = {
  error: null,
  notifications: [],
  notification: null
};

// ==============================|| SLICE - NOTIFICATIONS ||============================== //

const slice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    // get notifications
    getNotificationsSuccess(state, action) {
      state.notifications = action.payload;
    },

    // get notification
    getNotificationSuccess(state, action) {
      state.notifications.push(action.payload.notification);
    }
  }
});

// reducer
export default slice.reducer;

export const notificationsActions = slice.actions;
