// types
import { PaletteThemeProps } from 'types/theme';

// ==============================|| PRESET THEME - DEFAULT ||============================== //

const Theme1 = (): PaletteThemeProps => {
  const contrastText = '#fff';

  let primaryColors = ['#EEF5FE', '#D4E6FD', '#AACAFC', '#7FAAF8', '#5E8EF1', '#2C64E8', '#204CC7', '#1638A7', '#0E2686', '#081A6F'];
  let secondaryColors = ['#F5F7F9', '#ECEFF4', '#D9E0EA', '#C0CBDB', '#97AAC4', '#6F89AE', '#546F95', '#496081', '#394B65', '#1F2937'];
  let successColors = ['#E9FDEB', '#C7F9CC', '#92F4A4', '#59DF7F', '#30C067', '#009649', '#00814B', '#006C4A', '#005744', '#00483F'];
  let infoColors = ['#EEFBFF', '#D5F4FF', '#ACE5FF', '#82D1FF', '#63BEFF', '#309EFF', '#237BDB', '#185CB7', '#0F4093', '#092D7A'];
  let warningColors = ['#FFFBEB', '#FFF5CC', '#FFE999', '#FFDA66', '#FFCB3F', '#FFB200', '#DB9200', '#B77400', '#935900', '#7A4600'];
  let errorColors = ['#FFF5EF', '#FFE7D6', '#FFCAAD', '#FFA683', '#FF8365', '#FF4A32', '#DB2B24', '#B7191F', '#930F20', '#7A0920'];

  return {
    primary: {
      lighter: primaryColors[0],
      100: primaryColors[1],
      light: primaryColors[2],
      300: primaryColors[3],
      400: primaryColors[4],
      main: primaryColors[5],
      600: primaryColors[6],
      dark: primaryColors[7],
      800: primaryColors[8],
      darker: primaryColors[9],
      contrastText
    },
    secondary: {
      lighter: secondaryColors[0],
      100: secondaryColors[1],
      light: secondaryColors[2],
      300: secondaryColors[3],
      400: secondaryColors[4],
      main: secondaryColors[5],
      600: secondaryColors[6],
      dark: secondaryColors[7],
      800: secondaryColors[8],
      darker: secondaryColors[9],
      contrastText
    },
    success: {
      lighter: successColors[0],
      100: successColors[1],
      light: successColors[2],
      300: successColors[3],
      400: successColors[4],
      main: successColors[5],
      600: successColors[6],
      dark: successColors[7],
      800: successColors[8],
      darker: successColors[9],
      contrastText
    },
    info: {
      lighter: infoColors[0],
      100: infoColors[1],
      light: infoColors[2],
      300: infoColors[3],
      400: infoColors[4],
      main: infoColors[5],
      600: infoColors[6],
      dark: infoColors[7],
      800: infoColors[8],
      darker: infoColors[9],
      contrastText
    },
    warning: {
      lighter: warningColors[0],
      100: warningColors[1],
      light: warningColors[2],
      300: warningColors[3],
      400: warningColors[4],
      main: warningColors[5],
      600: warningColors[6],
      dark: warningColors[7],
      800: warningColors[8],
      darker: warningColors[9],
      contrastText
      // contrastText: secondaryColors[9]
    },
    error: {
      lighter: errorColors[0],
      100: errorColors[1],
      light: errorColors[2],
      300: errorColors[3],
      400: errorColors[4],
      main: errorColors[5],
      600: errorColors[6],
      dark: errorColors[7],
      800: errorColors[8],
      darker: errorColors[9],
      contrastText
    },
    grey: {
      0: contrastText,
      50: secondaryColors[0],
      100: secondaryColors[1],
      200: secondaryColors[2],
      300: secondaryColors[3],
      400: secondaryColors[4],
      500: secondaryColors[5],
      600: secondaryColors[6],
      700: secondaryColors[7],
      800: secondaryColors[8],
      900: secondaryColors[9]
    }
  };
};

export default Theme1;
