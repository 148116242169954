import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';
import useAuth from 'hooks/useAuth';
import { URL_PREFIX } from 'config';

const OverviewPage = Loadable(lazy(() => import('pages/overview')));
const Error404 = Loadable(lazy(() => import('pages/front-404')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  const { isLoggedIn } = useAuth();

  const PublicRoutes = !isLoggedIn
    ? {
        path: `${URL_PREFIX}/`,
        children: [
          {
            path: `${URL_PREFIX}/`,
            element: <Error404 />
          },
          {
            path: `cv-build/m/:id`,
            element: <OverviewPage />
          },
          {
            path: `jd-build/m/:id`,
            element: <OverviewPage />
          }
        ]
      }
    : {};
  return useRoutes([{ path: '*', element: <Error404 /> }, PublicRoutes, LoginRoutes, MainRoutes]);
}
