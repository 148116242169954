import { DocumentProps, DocumentType } from 'types/documents';

export const documents: DocumentProps[] = [
  {
    id: 'docs1',
    title: 'cv1985-jd9652.pdf',
    type: DocumentType.contracts,
    createdAt: '2022-10-08T10:41:04.258Z'
  },
  {
    id: 'docs2',
    title: 'cv1985.pdf',
    type: DocumentType.cv,
    createdAt: '2022-10-03T22:55:46.942Z'
  },
  {
    id: 'docs3',
    title: 'th-hive-project.docs',
    type: DocumentType.contracts,
    createdAt: '2022-10-05T01:13:34.865Z'
  },
  {
    id: 'docs4',
    title: 'jd965.pdf',
    type: DocumentType.jd,
    createdAt: '2022-10-01T04:45:04.788Z'
  }
];
