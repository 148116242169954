import { useSelector } from 'react-redux';

// material-ui
import { Divider, List, Typography } from '@mui/material';

// project import
import NavItem from './NavItem';
import NavCollapse from './NavCollapse';

// types
import { NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

interface Props {
  item: NavItemType;
}

const NavGroup = ({ item }: Props) => {
  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  const navCollapse = item.children?.map((menuItem) => {
    switch (menuItem.type) {
      case 'collapse':
        return <NavCollapse key={menuItem.id} menu={menuItem} level={1} />;
      case 'item':
        return <NavItem key={menuItem.id} item={menuItem} level={1} />;
      default:
        return (
          <Typography key={menuItem.id} variant="h6" color="error" align="center">
            Fix - Group Collapse or Items
          </Typography>
        );
    }
  });

  return (
    <List
      disablePadding
      subheader={
        item.title && (
          <Typography id={`lit-title-${item.id}`} variant="overline" color="secondary" sx={{ px: 2.5, mb: 0.5 }}>
            {drawerOpen ? item.title : ''}
          </Typography>
        )
      }
      sx={{
        '&:last-of-type > .MuiDivider-root': { display: 'none' },
        '& >.MuiListItemButton-root:last-of-type': { mb: 0 },
        ...(item.title && {
          '& >.MuiListItemButton-root:first-of-type': { mt: 0.5 }
        })
      }}
    >
      {navCollapse}
      <Divider sx={{ mx: 2.5, my: 3 }} />
    </List>
  );
};

export default NavGroup;
