import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import {
  Box,
  Divider,
  FormControl,
  ClickAwayListener,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Popper,
  InputAdornment,
  OutlinedInput,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useTheme
} from '@mui/material';

// project imports
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import { URL_PREFIX } from 'config';

// assets
import { ArrowDown2, ArrowUp2, SearchNormal1, ExportSquare } from 'iconsax-react';
import useConfig from 'hooks/useConfig';
import { dispatch, useSelector } from 'store';

// types
import { EmployeesProps } from 'types/employees';
import { JobsProps } from 'types/jobs';
import { searchJobs, setFilter as setJobFilter } from 'store/reducers/jobs';
import { searchEmployees, setFilter as setCandidateFilter } from 'store/reducers/employees';

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { borderRadius } = useConfig();
  const { searchedEmployees } = useSelector((state) => state.employees);
  const { searchedJobs } = useSelector((state) => state.jobs);

  const [query, setQuery] = useState<string>('');

  const [anchorFilterEl, setanchorFilterEl] = useState<null | HTMLElement>(null);
  const [selectedFilter, setSelectedFilter] = useState('Jobs');
  const openFilter = Boolean(anchorFilterEl);
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setanchorFilterEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: string) => {
    setSelectedFilter(index);
    if (index === 'Jobs') {
      dispatch(searchJobs(query));
    } else {
      dispatch(searchEmployees(query));
    }
    setanchorFilterEl(null);
  };

  const handleFilterClose = () => {
    setanchorFilterEl(null);
  };

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(true);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    dispatch(searchJobs('Jobs'));
  }, []);

  const uniqJobsTitle =
    searchedJobs &&
    searchedJobs.filter((value, index, self) => {
      return self.findIndex((v) => v.jobTitle === value.jobTitle) === index;
    });
  const uniqCandidatesTitle =
    searchedEmployees &&
    searchedEmployees.filter((value, index, self) => {
      return self.findIndex((v) => v.jobTitle === value.jobTitle) === index;
    });

  const handleJobSearchClick = (jobTitle: string) => {
    dispatch(setJobFilter({ search: jobTitle }));
    navigate(`${URL_PREFIX}/find-jobs`);
    setOpen(false);
    setQuery('');
  };

  const handleCandidateSearchClick = (jobTitle: string) => {
    dispatch(setCandidateFilter({ search: jobTitle }));
    navigate(`${URL_PREFIX}/find-candidates`);
    setOpen(false);
    setQuery('');
  };

  return (
    <Box
      sx={{
        flex: 1,
        marginRight: 'auto',
        position: 'relative',
        ml: { xs: 0, sm: 1 }
      }}
    >
      <FormControl sx={{ width: { xs: '100%', xl: 532 }, maxWidth: { xs: '100%', sm: 532 } }}>
        <OutlinedInput
          id="header-search"
          aria-label="open profile"
          ref={anchorRef}
          aria-controls={open ? 'profile-grow' : undefined}
          aria-haspopup="true"
          onFocus={handleToggle}
          onBlur={handleToggle}
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            if (selectedFilter === 'Jobs') {
              dispatch(searchJobs(e.target.value));
            } else {
              dispatch(searchEmployees(e.target.value));
            }
          }}
          startAdornment={
            <InputAdornment position="start" sx={{ mr: 0.25, mt: -0.25, color: 'text.secondary' }}>
              <SearchNormal1 size="16" />
            </InputAdornment>
          }
          endAdornment={
            <>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                  bgcolor: 'background.paper',
                  cursor: 'pointer',
                  '& svg': {
                    m: 1.5
                  },
                  '& hr': {
                    mx: 0.5
                  }
                }}
                id="lock-button"
                aria-haspopup="listbox"
                aria-controls="lock-menu"
                aria-label="when device is locked"
                aria-expanded={openFilter ? 'true' : undefined}
                onClick={handleClickListItem}
              >
                <Divider orientation="vertical" variant="middle" flexItem />
                <Typography sx={{ ml: 2 }}>{selectedFilter}</Typography>
                {openFilter ? <ArrowUp2 size="16" /> : <ArrowDown2 size="16" />}
              </Box>
              <Menu
                id="lock-menu"
                anchorEl={anchorFilterEl}
                open={openFilter}
                onClose={handleFilterClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                MenuListProps={{
                  'aria-labelledby': 'lock-button',
                  role: 'listbox'
                }}
              >
                <MenuItem selected={'Jobs' === selectedFilter} onClick={(event) => handleMenuItemClick(event, 'Jobs')}>
                  Jobs
                </MenuItem>
                <MenuItem selected={'Candidates' === selectedFilter} onClick={(event) => handleMenuItemClick(event, 'Candidates')}>
                  Candidates
                </MenuItem>
              </Menu>
            </>
          }
          aria-describedby="header-search-text"
          inputProps={{
            'aria-label': 'weight',
            sx: { py: '13.95px' }
          }}
          placeholder="Search jobs or candidates"
          sx={{ borderRadius: 16 }}
        />
      </FormControl>
      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{
          width: 1
        }}
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 8]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  boxShadow: theme.customShadows.large,
                  width: 1,
                  maxWidth: { xs: '100%', sm: 532 }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard elevation={0} border={false}>
                    <>
                      <Stack spacing={2}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center">
                          <Typography>{selectedFilter}</Typography>
                        </Stack>
                        {selectedFilter === 'Jobs'
                          ? uniqJobsTitle &&
                            uniqJobsTitle.length > 0 &&
                            uniqJobsTitle.map((searchedJob: JobsProps, i: number) => (
                              <List disablePadding key={i} onClick={() => handleJobSearchClick(searchedJob.jobTitle)}>
                                <ListItemButton sx={{ borderRadius: `${borderRadius}px`, mb: -1, mx: -2 }}>
                                  <ListItemText primary={<Typography color="grey.400">{searchedJob.jobTitle}</Typography>} />
                                  <ExportSquare size={18} style={{ color: theme.palette.grey[500] }} />
                                </ListItemButton>
                              </List>
                            ))
                          : uniqCandidatesTitle &&
                            uniqCandidatesTitle.length > 0 &&
                            uniqCandidatesTitle.map((searchedEmployee: EmployeesProps, i: number) => (
                              <List disablePadding key={i} onClick={() => handleCandidateSearchClick(searchedEmployee.jobTitle)}>
                                <ListItemButton sx={{ borderRadius: `${borderRadius}px`, mb: -1, mx: -2 }}>
                                  <ListItemText primary={<Typography color="grey.400">{searchedEmployee.jobTitle}</Typography>} />
                                  <ExportSquare size={18} style={{ color: theme.palette.grey[500] }} />
                                </ListItemButton>
                              </List>
                            ))}
                        <Divider />
                        {query && query.length > 0 && (
                          <Stack direction="row" spacing={1} sx={{ pt: 1.25, pb: 0.25 }}>
                            <SearchNormal1 size="18" color={theme.palette.primary.main} />
                            <Typography color="primary" sx={{ cursor: 'pointer' }}>
                              Search for ''{query}'' {selectedFilter === 'Jobs' ? 'Jobs' : 'Candidates'}
                            </Typography>
                          </Stack>
                        )}
                      </Stack>
                    </>
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Search;
