import { forwardRef, useEffect, ForwardRefExoticComponent, RefAttributes } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from 'store/reducers/menu';
import useConfig from 'hooks/useConfig';
import { URL_PREFIX } from 'config';

// types
import { LinkTarget, NavItemType } from 'types/menu';
import { RootStateProps } from 'types/root';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
  item: NavItemType;
  level: number;
}

const NavItem = ({ item, level }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen, openItem } = menu;
  const { borderRadius } = useConfig();

  // set menu item active on page reload
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname && pathname.includes(item.url!)) {
      dispatch(activeItem({ openItem: [item.id] }));
    }

    // eslint-disable-next-line
  }, [pathname]);

  const isSelected = openItem.findIndex((id) => id === item.id) > -1;

  // set icons
  const Icon = item.icon!;
  const itemIcon = item.icon ? <Icon size={20} color={isSelected ? theme.palette.primary.main : theme.palette.secondary.main} /> : false;

  // set routing and redirect link
  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  let listItemProps: {
    component: ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>> | string;
    href?: string;
    target?: LinkTarget;
  } = { component: forwardRef((props, ref) => <Link {...props} to={`${URL_PREFIX}/${item.url!}`} target={itemTarget} ref={ref} />) };
  if (item?.external) {
    listItemProps = { component: 'a', href: item.url, target: itemTarget };
  }
  const textColor = level > 1 ? 'secondary.main' : 'secondary.dark';

  return (
    <>
      {!item.display && item.display !== false && (
        <ListItemButton
          {...listItemProps}
          selected={isSelected}
          sx={{
            height: 44,
            mb: 0.5,
            mx: 2.5,
            pl: `${level > 1 && drawerOpen ? level * 16 + 12 : 16}px`,
            borderRadius: `${borderRadius}px`,
            '&:hover': {
              bgcolor: 'primary.lighter'
            },
            '&.Mui-selected': {
              bgcolor: 'primary.lighter',
              '&:hover': {
                bgcolor: 'primary.100'
              }
            }
          }}
        >
          {/* menu item - icon */}
          <ListItemIcon
            sx={{
              minWidth: 28,
              ...(!drawerOpen && {
                minWidth: 0,
                width: 52,
                alignItems: 'center'
              })
            }}
          >
            {itemIcon}
          </ListItemIcon>

          {/* menu item - text */}
          <ListItemText
            primary={
              drawerOpen && (
                <Typography variant={level > 1 ? 'body1' : 'body2'} color={isSelected ? 'primary.dark' : textColor}>
                  {item.title}
                </Typography>
              )
            }
          />

          {/* menu item - chip / badge */}
          {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
            <Chip
              color={item.chip.color}
              variant={item.chip.variant}
              size={item.chip.size}
              label={item.chip.label}
              avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
            />
          )}
        </ListItemButton>
      )}
    </>
  );
};

export default NavItem;
