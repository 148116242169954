export type RatesCalculateProps = {
  jobTitle: string;
  experience: string;
  jobDuration: string;
};

export enum CalculateType {
  asking = 'hiring',
  paying = 'offering'
}

export type CalculateRecommendationsProps = {
  type: CalculateType;
  calculate: RatesCalculateProps;
};

export type RecommendationsRatesProps = {
  calculate: RatesCalculateProps;
  highestRates: number;
  highestUp: number;
  recommendedRates: number;
  lowestRates: number;
  lowestDown: number;
  savedCost: number;
  time: string;
};

export type PublicJob = {
  id: string;
  title: string;
  average: number;
  max: number;
  min: number;
};

export interface ToolsProps {
  error: object | string | null;
  askingJobs: PublicJob[];
  payingJobs: PublicJob[];
  calculate: RatesCalculateProps | null;
  highestRates: number;
  highestUp: number;
  recommendedRates: number;
  lowestRates: number;
  lowestDown: number;
  savedCost: number;
  time: string;
}
