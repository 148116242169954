import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  createFilterOptions,
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project-import
import UploadAvatar from 'components/third-party/dropzone/Avatar';
import { addProfile, editProfile, resetStore } from 'store/reducers/cv-builder';
import { useDispatch, useSelector } from 'store';
import { addJobs, resetModal } from 'store/reducers/master';
import trimFc from 'utils/trimFc';
import useAuth from 'hooks/useAuth';

// assets
import { ArrowDown2, Trash } from 'iconsax-react';

// types
import { CVProfileProps } from 'types/cv-builder';

// types

const filter = createFilterOptions<string>();

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .matches(/^[aA-zZäöüÄÖÜß0\s-]+$/, 'Only alphabets are allowed, with letters (ÄäÖöÜüß)')
    .max(50, 'First Name must be at most 50 characters')
    .required('First Name is required'),
  lastName: yup
    .string()
    .trim()
    .matches(/^[aA-zZäöüÄÖÜß0\s-]+$/, 'Only alphabets are allowed, with letters (ÄäÖöÜüß)')
    .max(50, 'Last Name must be at most 50 characters')
    .required('Last Name is required'),
  jobTitle: yup.string().trim().required('Current Job Title is required').max(50, 'Current Job Title must be at most 50 characters'),
  jobCompany: yup
    .string()
    .trim()
    .matches(/^[ A-Za-zäöüÄÖÜß0-9_'"/)(?&-.]*$/, `Only alphanumeric are allowed with special characters (_'"/)(?&-.) and letters (ÄäÖöÜüß)`)
    .max(50, 'Company Name must be at most 50 characters')
    .required('Company Name is required'),
  email: yup.string().email('Must be a valid email').max(255).required('Email is required'),
  biography: yup
    .string()
    .trim()
    .min(5, 'Biography field must contain at least a few words. Please expand your text.')
    .max(1500, 'Biography field must be 1500 characters or less. Please shorten your text.')
});

function compare(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}

// ==============================|| CV BUILDER - USER PROFILE ||============================== //

interface Props {
  nextHandler: () => void;
}

const Step1 = ({ nextHandler }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [save, setSave] = useState<boolean>(false);

  const { user } = useAuth();
  const { jobs } = useSelector((state) => state.master);
  const { profile, id } = useSelector((state) => state.cv);

  const formik = useFormik({
    initialValues: {
      avatar: profile.avatar,
      firstName: profile.firstName,
      lastName: profile.lastName,
      email: user?.email ? user?.email : 'no-email@company.com',
      jobTitle: profile.jobTitle,
      jobCompany: user?.company?.companyName ? user?.company?.companyName : 'NO_COMPANY',
      biography: profile.biography
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const castValues = validationSchema.cast(values);

      const edited = compare(values, formik.initialValues);
      let success = false;

      if (!edited) {
        let editData: CVProfileProps = {
          avatar: !values.avatar?.base64 ? { name: '', url: '' } : values.avatar,
          firstName: castValues.firstName!,
          lastName: castValues.lastName!,
          email: castValues.email!,
          jobTitle: castValues.jobTitle!,
          jobCompany: castValues.jobCompany!,
          biography: castValues.biography!
        };

        if (id.length > 0) {
          success = await dispatch(editProfile({ profile: editData, id }));
        } else {
          success = await dispatch(addProfile(values));
        }
      } else {
        success = true;
      }
      setSubmitting(false);
      if (!save && success) {
        nextHandler();
      }

      if (save && success) {
        dispatch(resetStore());
        dispatch(resetModal());
      }
    }
  });

  const handleAddJob = (jobName: string) => {
    dispatch(addJobs(jobName));
  };

  return (
    <form onSubmit={formik.handleSubmit} id="validation-forms">
      <Grid container rowSpacing={{ xs: 2.5, sm: 4 }} columnSpacing={3}>
        <Grid item xs={12}>
          <InputLabel>Profile Avatar</InputLabel>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            spacing={2}
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            justifyContent="space-between"
          >
            <Stack direction="row" spacing={2} alignItems="center">
              <Box sx={{ width: 64, height: 64 }}>
                <UploadAvatar
                  setFieldValue={formik.setFieldValue}
                  file={formik.values.avatar!}
                  error={formik.touched.avatar && !!formik.errors.avatar}
                />
              </Box>
              <Stack spacing={0.5} sx={{ flex: '1 1 auto', width: '1%' }}>
                <Typography color={formik.values.avatar ? 'text.primary' : 'secondary.400'}>
                  {formik.values.avatar ? formik.values.avatar.name : 'No avatar uploaded yet.'}
                </Typography>
                <Typography color="secondary.300">Allowed &lsquo;image/*&rsquo; *.png, *.jpeg, *.jpg, *.gif</Typography>
              </Stack>
            </Stack>
            <Button
              variant="dashed"
              size="small"
              startIcon={<Trash size={18} />}
              color="error"
              onClick={() => formik.setFieldValue('avatar', null)}
            >
              Remove Photo
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel>First Name *</InputLabel>
          <TextField
            id="firstName"
            name="firstName"
            placeholder="First Name"
            value={formik.values.firstName}
            onChange={trimFc(formik)}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel>Last Name *</InputLabel>
          <TextField
            id="lastName"
            name="lastName"
            placeholder="Last Name"
            value={formik.values.lastName}
            onChange={trimFc(formik)}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            fullWidth
          />
        </Grid>
        <Typography variant="caption1" color="secondary.400" sx={{ ml: 3 }}>
          Your name will not be shared with other parties until a match is approved by you
        </Typography>
        <Grid item xs={12}>
          <InputLabel>Current Job Title *</InputLabel>
          <Grid container rowSpacing={4} columnSpacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                value={formik.values.jobTitle}
                disableClearable
                onChange={(event, newValue) => {
                  const jobExist = jobs.includes(newValue);
                  if (!jobExist) {
                    const matchData = newValue.match(/"((?:\\.|[^"\\])*)"/);
                    formik.setFieldValue('jobTitle', matchData && matchData[1]);
                    if (matchData && matchData[1]) handleAddJob(matchData[1]);
                  } else {
                    formik.setFieldValue('jobTitle', newValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);
                  const { inputValue } = params;
                  const isExisting = options.some((option) => inputValue === option);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push(`Add "${inputValue}"`);
                  }
                  return filtered;
                }}
                selectOnFocus
                clearOnBlur
                autoHighlight
                handleHomeEndKeys
                id="free-solo-with-text-demo"
                options={jobs}
                getOptionLabel={(option) => {
                  let value = option;
                  const jobExist = jobs.includes(option);
                  if (!jobExist) {
                    const matchData = option.match(/"((?:\\.|[^"\\])*)"/);
                    if (matchData && matchData[1]) value = matchData && matchData[1];
                  }
                  return value;
                }}
                renderOption={(props, option) => {
                  return (
                    <Box component="li" {...props}>
                      {option}
                    </Box>
                  );
                }}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="jobTitle"
                    error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
                    helperText={formik.touched.jobTitle && formik.errors.jobTitle && formik.errors.jobTitle}
                    placeholder="Select or type in current job title"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <ArrowDown2
                            style={{ pointerEvents: 'none', position: 'absolute', right: '10px' }}
                            size={16}
                            color={theme.palette.secondary.main}
                          />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                id="jobCompany"
                name="jobCompany"
                placeholder="Job Company"
                value={formik.values.jobCompany}
                onChange={trimFc(formik)}
                onBlur={formik.handleBlur}
                error={formik.touched.jobCompany && Boolean(formik.errors.jobCompany)}
                helperText={formik.touched.jobCompany && formik.errors.jobCompany}
                fullWidth
              />
            </Grid> */}
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <InputLabel>Email Address</InputLabel>
          <TextField
            id="email"
            name="email"
            placeholder="Email Address"
            value={formik.values.email}
            onChange={trimFc(formik)}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
          />
          <Typography variant="caption1" color="secondary.400">
            *Your email address will not be shared with other parties until a match is approved by you
          </Typography>
        </Grid> */}
        <Grid item xs={12}>
          <InputLabel>Biography</InputLabel>
          <TextField
            id="biography"
            name="biography"
            multiline
            rows={5}
            placeholder="Enter your Biography"
            value={formik.values.biography}
            onChange={trimFc(formik)}
            onBlur={formik.handleBlur}
            error={formik.touched.biography && Boolean(formik.errors.biography)}
            inputProps={{ maxLength: 1500 }}
            helperText={
              <Stack
                component="span"
                direction="row"
                alignItems="center"
                justifyContent={
                  (formik.touched.biography && formik.errors.biography) || formik.values.biography?.length === 1500
                    ? 'space-between'
                    : 'flex-end'
                }
              >
                {formik.touched.biography && formik.errors.biography && (
                  <Typography component="span" variant="caption1" color="error.main" id="standard-weight-helper-text-password-login">
                    {formik.errors.biography}
                  </Typography>
                )}
                {formik.touched.biography && formik.values.biography?.length === 1500 && (
                  <Typography component="span" variant="caption1" color="warning.main" id="standard-weight-helper-text-password-login">
                    Biography field must be 500 characters or less. Please shorten your text.
                  </Typography>
                )}
                <Typography component="span" align="right" color="secondary.400">
                  {formik.values.biography?.length} / 1500
                </Typography>
              </Stack>
            }
            fullWidth
          />
        </Grid>

        <Typography variant="caption1" color="secondary.400" sx={{ ml: 3 }}>
          Note: Please DO NOT add any company or person identifying or contact details in this section - this includes names, physical and
          email addresses, phone numbers and URLs
        </Typography>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between">
            <Box sx={{ display: 'flex' }}>
              {[1, 2, 3, 4].map((item: number, index: number) => (
                <Skeleton
                  key={index}
                  variant="circular"
                  width={item === 1 ? '80px' : '8px'}
                  height="8px"
                  animation={false}
                  sx={{
                    mr: 1,
                    bgcolor: item === 1 ? 'primary.main' : 'secondary.light',
                    borderRadius: item === 1 ? '6px' : '50%'
                  }}
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                size="small"
                disabled={formik.isSubmitting}
                type="submit"
                onClick={() => {
                  setSave(true);
                  formik.handleSubmit();
                }}
              >
                Save Changes & Close
              </Button>
              <Button variant="contained" size="small" disabled={formik.isSubmitting} type="submit" onClick={() => setSave(false)}>
                Next
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default Step1;
