import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, Grid, Modal, Stack, Typography } from '@mui/material';

// project import
import Step1 from 'sections/cv-builder/Step1';
import Step2 from 'sections/cv-builder/Step2';
import Step3 from 'sections/cv-builder/Step3';
import Step4 from 'sections/cv-builder/step-4';

import MainCard from 'components/MainCard';
import IconButton from 'components/@extended/IconButton';
import WarningPopup from 'components/cards/WarningPopup';
import SimpleBar from 'components/third-party/SimpleBar';

import { dispatch } from 'store';
import { updateStep, resetStore } from 'store/reducers/cv-builder';
import {
  resetModal,
  setJobs,
  setJobTags,
  setEssentialSkills,
  setDesirableSkills,
  setCulturalPeferences,
  setRoles,
  setDegrees
} from 'store/reducers/master';

// assets
import { ArrowLeft2, Add } from 'iconsax-react';

// types
import { RootStateProps } from 'types/root';
import { openSnackbar } from 'store/reducers/snackbar';

// ==============================|| CV BUILDER ||============================== //

const CVBuilderManually = () => {
  const theme = useTheme();

  const { step, error } = useSelector((state: RootStateProps) => state.cv);
  const [warning, setWarning] = useState(false);

  const handleWarning = () => {
    setWarning(true);
  };

  const handleClose = () => {
    setWarning(false);
    dispatch(resetStore());
    dispatch(resetModal());
  };

  const handleUpdate = () => {
    setWarning(false);
    handleClose();
  };

  const nextHandler = () => {
    dispatch(updateStep({ step: step + 1 }));
  };

  const backHandler = () => {
    dispatch(updateStep({ step: step - 1 }));
  };

  useEffect(() => {
    dispatch(updateStep({ step: 1 }));
    dispatch(setJobs());
    dispatch(setJobTags());
    dispatch(setEssentialSkills());
    dispatch(setDesirableSkills());
    dispatch(setCulturalPeferences());
    dispatch(setRoles());
    dispatch(setDegrees());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const err: any = error;
    if (err)
      dispatch(
        openSnackbar({
          open: true,
          message: err.response ? `Server error: ${err.response.data.error} ` : `Server error: ${err.message}`,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
    // eslint-disable-next-line
  }, [error, dispatch]);

  return (
    <>
      <Modal
        open={true}
        onClose={handleWarning}
        aria-labelledby="modal-cv-title"
        aria-describedby="modal-cv-description"
        sx={{
          '& .MuiPaper-root:focus': {
            outline: 'none'
          }
        }}
      >
        <MainCard
          sx={{ width: `calc(100% - 48px)`, minWidth: 340, maxWidth: 880, height: 'auto', maxHeight: 'calc(100vh - 48px)' }}
          modal
          content={false}
        >
          <SimpleBar
            sx={{
              maxHeight: `calc(100vh - 48px)`,
              '& .simplebar-content': {
                display: 'flex',
                flexDirection: 'column'
              }
            }}
          >
            <Box sx={{ p: { xs: 3, sm: 5 } }}>
              <Grid container rowSpacing={{ xs: 2.5, sm: 4 }}>
                <Grid item xs={12}>
                  <Stack spacing={1} alignItems="center" justifyContent="center">
                    <Stack direction="row" alignItems="flex-start" justifyContent="space-between" sx={{ width: 1 }}>
                      {/* need box for put title in center */}
                      <Box>
                        {step !== 1 && (
                          <IconButton disableRipple color="primary" size="small" onClick={backHandler} variant="light">
                            <ArrowLeft2 size={18} />
                          </IconButton>
                        )}
                      </Box>
                      <Typography align="center" variant="h5">
                        Build a CV Card
                      </Typography>
                      <IconButton color="secondary" size="extraSmall" onClick={handleWarning} sx={{ p: 0 }}>
                        <Add color={theme.palette.text.primary} style={{ transform: 'rotate(45deg)' }} />
                      </IconButton>
                    </Stack>
                    {step === 1 && (
                      <Typography align="center" color="secondary">
                        Please go through the different sections of the CV to ensure information captured is as accurate as possible
                      </Typography>
                    )}
                    {step !== 1 && (
                      <Typography align="center" color="secondary">
                        Please go through the different sections of the CV to ensure information captured is as accurate as possible
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  {step === 1 && <Step1 nextHandler={nextHandler} />}
                  {step === 2 && <Step2 nextHandler={nextHandler} />}
                  {step === 3 && <Step3 nextHandler={nextHandler} />}
                  {step === 4 && <Step4 />}
                </Grid>
              </Grid>
            </Box>
          </SimpleBar>
        </MainCard>
      </Modal>
      <WarningPopup open={warning} handleSave={handleUpdate} handleClose={handleClose} silentClose={() => setWarning(false)} />
    </>
  );
};

export default CVBuilderManually;
