// project import
import Default from './default';

// types
import { PaletteThemeProps } from 'types/theme';

// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //

const Theme = (): PaletteThemeProps => {
  return Default();
};

export default Theme;
