// assets
import { Briefcase, ProfileAdd, DocumentText1, ClipboardText, CopySuccess, Layer, Profile } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { Briefcase, ProfileAdd, DocumentText1, ClipboardText, CopySuccess, Layer, Profile };

// ==============================|| MENU ITEMS - OVERVIEW ||============================== //

const manage: NavItemType = {
  id: 'group-manage',
  type: 'group',
  title: 'Manage',
  children: [
    {
      id: 'my-job-board',
      title: 'My Jobs Board',
      type: 'collapse',
      url: 'jobboard',
      icon: icons.Briefcase,
      children: [
        {
          id: 'review-applicants',
          title: 'Review Applicants',
          type: 'item',
          url: 'applicants',
          icon: icons.Profile
        }
      ]
    },
    {
      id: 'my-employees',
      title: 'My Employees',
      type: 'collapse',
      url: 'employees',
      icon: icons.ProfileAdd,
      children: [
        {
          id: 'my-applications',
          title: 'My Applications',
          type: 'item',
          url: 'employees/applications',
          icon: icons.ClipboardText,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'my-matches',
      title: 'My Matches',
      type: 'item',
      url: 'employees/matches',
      icon: icons.CopySuccess,
      breadcrumbs: false
    },
    {
      id: 'my-projects',
      title: 'My Projects',
      type: 'item',
      url: 'employees/projects',
      icon: icons.Layer,
      breadcrumbs: false
    },
    {
      id: 'my-documents',
      title: 'My Documents',
      type: 'item',
      url: 'documents',
      icon: icons.DocumentText1
    }
  ]
};

export default manage;
