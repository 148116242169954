import { useCallback, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';

// third-party
import { formatDistance, format } from 'date-fns';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CurrencyFormat from 'react-currency-format';

// project import
import ExportPDFView from './export-pdf-view';
import MainCard from 'components/MainCard';
import Dot from 'components/@extended/Dot';
import Avatar from 'components/@extended/Avatar';
import ReadMore from 'components/@extended/ReamMore';
import IconButton from 'components/@extended/IconButton';
import debounce from 'utils/debounce';

import {
  acceptApplicationRequest,
  employeeInvite,
  rejectApplication,
  resetEmployee,
  setFavouritesEmployee
} from 'store/reducers/employees';
import { setModal } from 'store/reducers/master';
import { openSnackbar } from 'store/reducers/snackbar';
import { dispatch, useSelector } from 'store';
import useAuth from 'hooks/useAuth';

// types
import { ModalType } from 'types/master';
import { JobsProps } from 'types/jobs';
import { EmployeesProps, EmployeeStatus, MatchesProps, ReviewApplicantStatus } from 'types/employees';

// assets
import { Add, ArrowDown2, Building4, DocumentDownload, Element3, ExportCurve, Heart, Profile, Teacher } from 'iconsax-react';
import { ColorProps } from 'types/extended';
import { UserRole } from 'types/user';
import { EXP_END_DATE } from 'config';

// @ts-ignore
const diffDates = (d1: Date, d2) => {
  d1 = new Date(d1);
  d2 = new Date(d2);
  return formatDistance(d1, d2);
};

// function getColor(match: number) {
//   if (match >= 80) return 'success.main';
//   if (match >= 50) return 'warning.main';
//   return 'error';
// }

interface Props {
  employee: EmployeesProps;
  match?: false | MatchesProps;
  closeModal: () => void;
  setFailed: (faild: boolean) => void;
  setConfirm: (confirm: boolean) => void;
}

const FindPreviewPopup = ({ employee, match, closeModal, setFailed, setConfirm }: Props) => {
  const theme = useTheme();
  const { user } = useAuth();

  const { matchJobs } = useSelector((state) => state.jobs);
  const { modal } = useSelector((state) => state.master);

  const [isFavourite, setIsFavourite] = useState<boolean>(employee && employee?.favourite !== undefined ? employee.favourite : false);
  const [iconHover, setIconHover] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<string | null>(null);

  const isCreator = user?.id === employee.userId || user?.role === UserRole.admin;
  const isAccess = isCreator || employee.status === EmployeeStatus.CONTRACTED;

  const handleChange = (isFavourite: boolean, id: string) => {
    dispatch(setFavouritesEmployee(id, isFavourite));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(handleChange), []);

  const handleEmployeeInvite = () => {
    if (!selectedJob && !employee.id) return;
    if (employee.id) {
      dispatch(employeeInvite(employee.id!, selectedJob!)).then((response) => {
        setFailed(!response);
        setConfirm(true);
      });
    }
  };

  let statusLabel;
  let color: ColorProps;
  switch (employee.status) {
    case EmployeeStatus.CONTRACTED:
      statusLabel = 'Contracted';
      color = 'success';
      break;
    case EmployeeStatus.APPLIED:
      statusLabel = 'Applied';
      color = 'warning';
      break;
    case EmployeeStatus.MATCH:
      statusLabel = 'Match';
      color = 'primary';
      break;
    case EmployeeStatus.PUBLISHED:
      statusLabel = 'Published';
      color = 'info';
      break;
    case EmployeeStatus.DRAFT:
    default:
      statusLabel = 'Draft';
      color = 'secondary';
      break;
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Stack
          spacing={1}
          justifyContent="space-between"
          alignItems={{ xs: 'flex-start', sm: 'center' }}
          direction={{ xs: 'column', sm: 'row' }}
        >
          <List sx={{ width: 1, p: 0 }}>
            <ListItem disablePadding>
              <ListItemAvatar sx={{ mr: 2 }}>
                <Avatar
                  sx={{ width: 56, height: 56 }}
                  color="secondary"
                  {...(isAccess && employee?.image && { alt: employee.firstName, src: employee?.image })}
                >
                  <Profile size={40} color="black" variant="Bold" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={
                  <>
                    {isAccess ? (
                      <Typography variant="h6" flexWrap="wrap">
                        {employee.firstName} {employee.lastName}
                      </Typography>
                    ) : (
                      <Skeleton height={28} width={180} animation={false} />
                    )}
                  </>
                }
                secondary={
                  <Typography variant="subtitle1" color="secondary" flexWrap="wrap">
                    {employee?.jobTitle}
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Stack direction="row" spacing={0.5} alignItems="center">
            <IconButton
              color="secondary"
              onMouseEnter={() => setIconHover(!iconHover)}
              onMouseLeave={() => setIconHover(!iconHover)}
              onClick={() => {
                optimizedFn(!isFavourite, employee.id);
                setIsFavourite(!isFavourite);
              }}
              sx={{ padding: 0, width: 32, height: 32 }}
            >
              <Heart
                variant={isFavourite ? 'Bold' : 'Outline'}
                size={20}
                color={isFavourite || iconHover ? theme.palette.primary.main : theme.palette.text.primary}
              />
            </IconButton>
            <IconButton
              sx={{ padding: 0, width: 32, height: 32 }}
              color="secondary"
              onClick={() => {
                dispatch(setModal({ type: ModalType.cvShare, id: employee.id }));
                dispatch(resetEmployee());
              }}
            >
              <ExportCurve size={20} color={theme.palette.text.primary} />
            </IconButton>
            <PDFDownloadLink
              document={<ExportPDFView employee={employee} />}
              fileName={`CV-${employee.jobCompany}-${employee.firstName}-${employee.lastName}.pdf`}
            >
              <IconButton sx={{ padding: 0, width: 32, height: 32 }} color="secondary">
                <DocumentDownload size={20} color={theme.palette.text.primary} />
              </IconButton>
            </PDFDownloadLink>
            <IconButton sx={{ padding: 0, width: 32, height: 32 }} color="secondary" onClick={closeModal}>
              <Add size={20} color={theme.palette.text.primary} style={{ transform: 'rotate(45deg)' }} />
            </IconButton>
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12} sm={7} md={8}>
        <Grid container rowSpacing={3}>
          <Grid item xs={12}>
            <Stack spacing={3}>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography color="secondary.dark">{employee.capacity}</Typography>
                <Dot color="secondary" size={6} />
                <Typography color="secondary.dark">{employee.workLocation}</Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            {employee?.biography && <ReadMore variant="subtitle1">{employee?.biography}</ReadMore>}
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Availability</Typography>
              <Stack direction="row" spacing={1} alignItems="center">
                <Chip
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {employee.startDate && format(new Date(employee.startDate!), 'dd LLL yyyy')}
                    </Typography>
                  }
                  size="small"
                />
                <Typography color="secondary.dark">-</Typography>
                <Chip
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {employee.endDate ? format(new Date(employee.endDate!), 'dd LLL yyyy') : 'Present'}
                    </Typography>
                  }
                  size="small"
                />
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Rates</Typography>
              <Box>
                <Chip
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {employee.rate ? (
                        <>
                          <CurrencyFormat
                            fixedDecimalScale
                            value={
                              employee.timeSlot === 'Hourly'
                                ? (Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10) % 1 !== 0
                                  ? (Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10).toFixed(2)
                                  : Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10
                                : employee.timeSlot === 'Daily'
                                ? (Math.round(10 * Number(employee.rate)) / 10) % 1 !== 0
                                  ? (Math.round(10 * Number(employee.rate)) / 10).toFixed(2)
                                  : Math.round(10 * Number(employee.rate)) / 10
                                : employee.timeSlot === 'Monthly'
                                ? (Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10) % 1 !== 0
                                  ? (Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10).toFixed(2)
                                  : Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10
                                : ''
                            }
                            displayType="text"
                            thousandSeparator
                            prefix="£"
                          />{' '}
                          / day
                        </>
                      ) : (
                        '-'
                      )}
                    </Typography>
                  }
                  size="small"
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Work Location</Typography>
              <Box>
                <Chip
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark" sx={{ display: 'flex', alignItems: 'center' }}>
                      {isAccess ? employee.jobCompany : <Skeleton width={50} height={16} animation={false} sx={{ mr: 0.5 }} />} -{' '}
                      {employee.location}
                    </Typography>
                  }
                  size="small"
                />
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Job Tags</Typography>
              <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
                {employee.jobTags &&
                  employee.jobTags.map((item: string, index: number) => (
                    <Chip
                      key={index}
                      variant="combined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {item}
                        </Typography>
                      }
                      size="small"
                    />
                  ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Essential Skills</Typography>
              <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
                {employee?.essentialTags &&
                  employee.essentialTags.map((item: string, index: number) => (
                    <Chip
                      key={index}
                      variant="combined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {item}
                        </Typography>
                      }
                      size="small"
                    />
                  ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Desireable Skills</Typography>
              <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
                {employee.desirableTags &&
                  employee.desirableTags.length > 0 &&
                  employee.desirableTags.map((item: string, index: number) => (
                    <Chip
                      key={index}
                      variant="combined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {item}
                        </Typography>
                      }
                      size="small"
                    />
                  ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Cultural Preference Keywords</Typography>
              <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
                {employee.culturalTags &&
                  employee.culturalTags.map((item: string, index: number) => (
                    <Chip
                      key={index}
                      variant="combined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {item}
                        </Typography>
                      }
                      size="small"
                    />
                  ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Experiences</Typography>
              <Timeline
                sx={{
                  p: 0,
                  m: 0,
                  '& .MuiTimelineItem-root': {
                    minHeight: 64,
                    '&::before': { flex: 0, p: 0 },
                    '&:last-of-type': { minHeight: 'auto', '& .MuiTimelineContent-root': { mb: 0 } }
                  },
                  '& .MuiTimelineDot-root': {
                    width: 40,
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 1.25,
                    boxShadow: 'none',
                    margin: 0,
                    mr: 1.25
                  },
                  '& .MuiTimelineContent-root': { height: '100%', mb: 1.75 },
                  '& .MuiTimelineConnector-root': {
                    mr: 1.5,
                    border: '1px solid',
                    borderColor: 'secondary.300',
                    bgcolor: 'transparent'
                  }
                }}
              >
                {employee.experiences &&
                  employee.experiences.length > 0 &&
                  employee.experiences.map((item, index) => (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineDot sx={{ color: 'text.primary', bgcolor: 'secondary.lighter' }}>
                          <Element3 size={28} variant="Bold" />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: 0 }}>
                        <Stack>
                          <Typography variant="body2">
                            {item.role}{' '}
                            {item.endDate &&
                              format(new Date(item.endDate), 'yyyy-MM') === format(new Date(EXP_END_DATE), 'yyyy-MM') &&
                              '(On-Going)'}
                          </Typography>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Typography color="secondary.400">{item.company}</Typography>
                            <Dot size={6} color="secondary" />
                            <Typography color="secondary.400">
                              {diffDates(
                                new Date(item.startDate!),
                                item.endDate && format(new Date(item.endDate!), 'yyyy-MM') === format(new Date(EXP_END_DATE), 'yyyy-MM')
                                  ? format(new Date(), 'yyyy-MM-dd')
                                  : item.endDate!
                              )}
                            </Typography>
                          </Stack>
                        </Stack>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
              </Timeline>
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack spacing={1}>
              <Typography variant="body3">Qualifications</Typography>
              <Timeline
                sx={{
                  p: 0,
                  m: 0,
                  '& .MuiTimelineItem-root': {
                    minHeight: 64,
                    '&::before': { flex: 0, p: 0 },
                    '&:last-of-type': { minHeight: 'auto', '& .MuiTimelineContent-root': { mb: 0 } }
                  },
                  '& .MuiTimelineDot-root': {
                    width: 40,
                    height: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 1.25,
                    boxShadow: 'none',
                    margin: 0,
                    mr: 1.25
                  },
                  '& .MuiTimelineContent-root': { height: '100%', mb: 1.75 },
                  '& .MuiTimelineConnector-root': {
                    mr: 1.5,
                    border: '1px solid',
                    borderColor: 'secondary.300',
                    bgcolor: 'transparent'
                  }
                }}
              >
                {employee.qualifications &&
                  employee.qualifications.length > 0 &&
                  employee.qualifications.map((item, index) => (
                    <TimelineItem key={index}>
                      <TimelineSeparator>
                        <TimelineDot sx={{ color: 'text.primary', bgcolor: 'secondary.lighter' }}>
                          <Teacher size={28} />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: 0 }}>
                        <Stack>
                          <Typography variant="body2">{item.degree}</Typography>
                          <Stack direction="row" spacing={1} alignItems="center">
                            <Typography color="secondary.400">{item.institute}</Typography>
                            <Dot size={6} color="secondary" />
                            <Typography color="secondary.400">{format(new Date(item.endDate!), 'LLL yyyy')}</Typography>
                          </Stack>
                        </Stack>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
              </Timeline>
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={5} md={4}>
        <MainCard content={false} border>
          <Box sx={{ p: 2.5 }}>
            <Grid container rowSpacing={3}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body3">Profile Updated</Typography>
                  <Box>
                    <Chip
                      variant="outlined"
                      label={
                        <Typography variant="body2" color="secondary.dark">
                          {employee.updatedAt && format(new Date(employee.updatedAt), 'dd LLL yyyy')}
                        </Typography>
                      }
                      size="small"
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body3">Applicant’s Status</Typography>
                  <Chip
                    label={statusLabel}
                    sx={{
                      height: 24,
                      width: 100,
                      ...(employee.status === EmployeeStatus.DRAFT && {
                        bgcolor: 'secondary.darker'
                      })
                    }}
                    color={color}
                  />
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body3">Applicant’s Email</Typography>
                  <Box>
                    <Chip
                      variant="outlined"
                      label={
                        <>
                          {isAccess ? (
                            <Typography variant="body2" color="secondary.dark">
                              {employee.email}
                            </Typography>
                          ) : (
                            <Skeleton height={20} width={150} animation={false} />
                          )}
                        </>
                      }
                      size="small"
                    />
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle3">Current Company</Typography>
              </Grid>
              <Grid item xs={12}>
                <List sx={{ width: 1, p: 0 }}>
                  <ListItem disablePadding>
                    <ListItemAvatar>
                      <Avatar
                        color="secondary"
                        variant="rounded"
                        sx={{ borderRadius: '10px' }}
                        {...(isAccess &&
                          employee?.user?.company?.logo && { alt: employee.jobCompany, src: employee?.user?.company?.logo as string })}
                      >
                        <Element3 size={28} variant="Bold" color="black" />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ m: 0 }}
                      primary={
                        <>
                          {isAccess ? (
                            <Typography variant="body3" flexWrap="wrap">
                              {employee.user?.company?.companyName}
                            </Typography>
                          ) : (
                            <Skeleton height={24} width={120} animation={false} />
                          )}
                        </>
                      }
                      secondary={
                        <Typography variant="body2" color="secondary.dark" flexWrap="wrap">
                          {employee?.user?.company?.country ? (
                            employee?.user?.company?.country
                          ) : (
                            <Skeleton height={16} width={80} animation={false} />
                          )}
                        </Typography>
                      }
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <Typography variant="body3">Company Email</Typography>
                  <Box>
                    <Chip
                      variant="outlined"
                      label={
                        <>
                          {isAccess ? (
                            <Typography variant="body2" color="secondary.dark">
                              {employee?.user?.email}
                            </Typography>
                          ) : (
                            <Skeleton height={20} width={150} animation={false} />
                          )}
                        </>
                      }
                      size="small"
                    />
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </MainCard>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      {modal && modal.type === ModalType.cvFindPreview && (
        <>
          <Grid item xs={12} sm={8}>
            <Autocomplete
              id="employee"
              sx={{
                '& .MuiAutocomplete-endAdornment': {
                  top: `calc(50% - 10px)`,
                  '& .MuiButtonBase-root': { width: 22, height: 22, ml: 1 }
                }
              }}
              value={matchJobs.find((option: JobsProps) => option.id === selectedJob)}
              onChange={(event, value) => {
                setSelectedJob(value?.id || null);
              }}
              options={matchJobs}
              fullWidth
              autoHighlight
              getOptionLabel={(option) => (selectedJob === null ? 'Choose job title' : option.jobTitle)}
              popupIcon={<ArrowDown2 size={18} />}
              isOptionEqualToValue={(option) => option.id === selectedJob}
              renderOption={(props, option) => (
                <Box component="li" sx={{ mx: 1, borderBottom: `1px solid ${theme.palette.divider}` }} {...props}>
                  {option.id && option.id !== '' && (
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between" sx={{ width: 1, py: 1 }}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Avatar size="sm" src={option?.logo} />
                        <Typography variant="body3">{option.jobTitle}</Typography>
                      </Stack>
                      {/* <Typography color={getColor(option.match)}>{option.match}% Match</Typography> */}
                    </Stack>
                  )}
                </Box>
              )}
              filterOptions={(options, params) =>
                options.filter(
                  (option) => `${option.jobTitle}`.toLowerCase().includes(params.inputValue.toLowerCase())
                  // `${option.jobTitle} ${option.match}`.toLowerCase().includes(params.inputValue.toLowerCase())
                )
              }
              renderInput={(params) => {
                const selected = matchJobs.find((option: JobsProps) => option.id === selectedJob);

                return (
                  <TextField
                    {...params}
                    name="employess"
                    placeholder="Choose job title"
                    InputProps={{
                      ...params.InputProps,
                      startAdornment: (
                        <>
                          {selected && selected.logo !== '' && <img loading="lazy" width="20" src={selected.logo} alt="" />}
                          {!selected && (
                            <Avatar color="secondary" size="xs">
                              <Building4 size={16} variant="Bold" color={theme.palette.secondary.dark} />
                            </Avatar>
                          )}
                        </>
                      ),
                      endAdornment: (
                        <>
                          {/* {selected && <Typography color={getColor(selected.match)}>{selected.match}% Match</Typography>} */}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password' // disable autocomplete and autofill
                    }}
                  />
                );
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Button variant="contained" fullWidth disabled={!selectedJob} onClick={handleEmployeeInvite}>
              Invite to Apply
            </Button>
          </Grid>
        </>
      )}
      {modal && modal.type === ModalType.cvAppPreview && employee.id && (
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
            <Button color="secondary" variant="contained" onClick={closeModal}>
              Cancel
            </Button>
            {match &&
              ((employee.userId === user?.id && match.status === ReviewApplicantStatus.INVITED) ||
                (employee.userId !== user?.id && match.status === ReviewApplicantStatus.NEW)) && (
                <>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() =>
                      // @ts-ignore
                      dispatch(rejectApplication(match.id)).then(() => {
                        dispatch(
                          openSnackbar({
                            open: true,
                            message: 'Application rejected successfully',
                            variant: 'alert',
                            alert: {
                              color: 'success'
                            },
                            close: false
                          })
                        );
                        closeModal();
                      })
                    }
                  >
                    Decline Request
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() =>
                      // @ts-ignore
                      dispatch(acceptApplicationRequest(match.id)).then(() => {
                        dispatch(
                          openSnackbar({
                            open: true,
                            message: 'Application request accepted successfully',
                            variant: 'alert',
                            alert: {
                              color: 'success'
                            },
                            close: false
                          })
                        );
                        closeModal();
                      })
                    }
                  >
                    Accept Request
                  </Button>
                </>
              )}
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default FindPreviewPopup;
