// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  Skeleton,
  Stack,
  TextField,
  Typography,
  Alert,
  AlertTitle
} from '@mui/material';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project imports
import MainCard from 'components/MainCard';

import trimFc from 'utils/trimFc';
import { setModal } from 'store/reducers/master';
import { dispatch, useSelector } from 'store';
import { editCalulate } from 'store/reducers/cv-builder';
// import { editCalulate, getCalculateRate } from 'store/reducers/cv-builder';
// import { resetCalculatedRate } from 'store/reducers/cv-builder';

// assets
import { ArrowDown2 } from 'iconsax-react';

// types
import { ModalType } from 'types/master';

const validationSchema = yup.object({
  rate: yup
    .number()
    .required('Please enter the asking rate')
    .typeError('Please enter a valid asking rate value.')
    // @ts-ignore
    .test('rate', 'Asking rate must have 2 digits after decimal or less', (number) => /^\d+(\.\d{1,2})?$/.test(number))
    .when('timeSlot', (timeSlot, schema) => {
      let minVal = 1;
      let maxVal = 9500;

      if (timeSlot === 'Hourly') {
        minVal = 1;
        maxVal = 9667;
      }

      if (timeSlot === 'Monthly') {
        minVal = 1;
        maxVal = 960000;
      }

      return schema.min(minVal, `The ${timeSlot} asking rate can't be free`).max(maxVal, `The asking rate must below ${maxVal}`);
    }),
  timeSlot: yup.string().trim().required('Slot is required')
});

const RateCalculator = () => {
  const theme = useTheme();

  const { workSlots } = useSelector((state) => state.master);
  const { calculate, experiences, qualifications, id, calculatedRate } = useSelector((state) => state.cv);

  const mergeHideValue = [...experiences, ...qualifications];

  const formik = useFormik({
    initialValues: {
      rate: calculate.rate,
      timeSlot: calculate.timeSlot,
      submit: null
    },
    validationSchema,
    onSubmit: (values, { setErrors, setStatus, setSubmitting }) => {
      const castValues = validationSchema.cast(values);

      if (mergeHideValue.length > 0) {
        dispatch(editCalulate({ calculate: { rate: castValues.rate!, timeSlot: castValues.timeSlot! }, id })).then(() => {
          setSubmitting(false);
          dispatch(setModal({ type: ModalType.cvPreview, id }));
        });
      } else {
        setStatus({ success: false });
        setErrors({ submit: 'At least 1 experience or 1 qualification is required.' });
        setSubmitting(false);
      }
    }
  });

  // const handleCalculateBtn = () => {
  //   dispatch(getCalculateRate(id));
  // };
  // const handleResetCalculateBtn = () => {
  //   dispatch(resetCalculatedRate());
  // };

  return (
    <form onSubmit={formik.handleSubmit} id="validation-forms">
      <Grid container rowSpacing={{ xs: 2.5, sm: 4 }} columnSpacing={3}>
        {formik.errors.submit && mergeHideValue.length <= 0 && (
          <Grid item xs={12}>
            <Alert color="error" variant="border">
              <AlertTitle sx={{ color: 'error.main', mt: 0.25 }}>{formik.errors.submit}</AlertTitle>
            </Alert>
          </Grid>
        )}

        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <MainCard content={false} sx={{ bgcolor: 'secondary.lighter', borderRadius: '6px', overflow: 'visible' }}>
            <Box sx={{ px: 1.5, py: 1.25 }}>
              <Stack spacing={1}>
                <Typography variant="subtitle2" color="secondary.dark">
                  Use TheHIVE calculator to ensure you set best rates
                </Typography>
                {calculatedRate.recommended === 0 && (
                  <Typography variant="body1" color="error.main">
                    TheHIVE calculator only supports the set of most popular roles on TheHIVE.
                  </Typography>
                )}
                {/* <Stack direction="row" alignItems="center" spacing={1.25}>
                  <Button variant="contained" size="small" sx={{ py: 0.75 }} onClick={() => handleCalculateBtn()}>
                    Calculate
                  </Button>
                  <Button variant="outlined" color="secondary" size="small" sx={{ py: 0.75 }} onClick={() => handleResetCalculateBtn()}>
                    Reset
                  </Button>
                </Stack> */}
              </Stack>
            </Box>
            <Divider />
            <Box sx={{ p: 1.5 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <Stack spacing={{ xs: 0, sm: 1 }}>
                    <Typography variant="body2">Recommended Rates</Typography>
                    {calculatedRate.recommended !== 0 ? (
                      <Typography variant="h5">
                        £{calculatedRate.recommended}/
                        <Typography component="span" color="secondary">
                          day
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography variant="h5" sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
                        £<Skeleton width={50} sx={{ mx: 0.5 }} animation={false} />/
                        <Typography component="span" color="secondary">
                          day
                        </Typography>
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack spacing={{ xs: 0, sm: 1 }}>
                    <Typography variant="body2">Lowest Rates</Typography>
                    {calculatedRate.lowest !== 0 ? (
                      <Typography variant="h5">
                        £{calculatedRate.lowest}/
                        <Typography component="span" color="secondary">
                          day
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography variant="h5" sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
                        £<Skeleton width={50} sx={{ mx: 0.5 }} animation={false} />/
                        <Typography component="span" color="secondary">
                          day
                        </Typography>
                      </Typography>
                    )}
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Stack spacing={{ xs: 0, sm: 1 }}>
                    <Typography variant="body2">Highest Rates</Typography>
                    {calculatedRate.highest !== 0 ? (
                      <Typography variant="h5">
                        £{calculatedRate.highest}/
                        <Typography component="span" color="secondary">
                          day
                        </Typography>
                      </Typography>
                    ) : (
                      <Typography variant="h5" sx={{ display: 'inline-flex', alignItems: 'flex-end' }}>
                        £<Skeleton width={50} sx={{ mx: 0.5 }} animation={false} />/
                        <Typography component="span" color="secondary">
                          day
                        </Typography>
                      </Typography>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Box>
          </MainCard>
        </Grid>
        <input
          id="hideValidation"
          name="hideValidation"
          type="hidden"
          value={mergeHideValue.length}
          onChange={trimFc(formik)}
          onBlur={formik.handleBlur}
        />
        <Grid item xs={12}>
          <Grid container rowSpacing={{ xs: 2.5, sm: 4 }} columnSpacing={3}>
            <Grid item xs={12} sm={6}>
              <InputLabel>
                Asking Rates *{' '}
                <Typography component="span" variant="body2" color="secondary.400">
                  (in pounds)
                </Typography>
              </InputLabel>
              <TextField
                id="rate"
                name="rate"
                placeholder="Asking Rate"
                value={formik.values.rate}
                onChange={trimFc(formik)}
                onBlur={formik.handleBlur}
                error={formik.touched.rate && Boolean(formik.errors.rate)}
                helperText={formik.touched.rate && formik.errors.rate}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputLabel>Rate Type *</InputLabel>
              <Autocomplete
                id="timeSlot"
                value={formik.values.timeSlot}
                onChange={(event: any, newValue: string | null) => {
                  formik.setFieldValue('timeSlot', newValue);
                }}
                options={workSlots}
                disableClearable
                freeSolo
                autoHighlight
                clearOnBlur
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    name="timeSlot"
                    placeholder="Select Slot"
                    error={formik.touched.timeSlot && Boolean(formik.errors.timeSlot)}
                    helperText={formik.touched.timeSlot && formik.errors.timeSlot}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment style={{ pointerEvents: 'none', position: 'absolute', right: '10px' }} position="end">
                          <ArrowDown2 size={16} color={theme.palette.secondary.main} />
                        </InputAdornment>
                      )
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between">
            <Box sx={{ display: 'flex' }}>
              {[1, 2, 3, 4].map((item: number, index: number) => (
                <Skeleton
                  key={index}
                  variant="circular"
                  width={item === 4 ? '80px' : '8px'}
                  height="8px"
                  animation={false}
                  sx={{
                    mr: 1,
                    bgcolor: item === 4 ? 'primary.main' : 'secondary.light',
                    borderRadius: item === 4 ? '6px' : '50%'
                  }}
                />
              ))}
            </Box>
            <Button variant="contained" size="small" type="submit">
              Go to summary
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default RateCalculator;
