// ==============================|| OVERRIDES - FORM HELPER TEXT ||============================== //

export default function FormHelperText() {
  return {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          marginTop: 8,
          fontSize: '12px'
        }
      }
    }
  };
}
