const parseDays = (totalDays: number) => {
  let year: number;
  let months: number;
  let week: number;
  let days: number;

  year = totalDays >= 365 ? Math.floor(totalDays / 365) : 0;
  totalDays = year ? totalDays - year * 365 : totalDays;

  months = totalDays >= 30 ? Math.floor((totalDays % 365) / 30) : 0;
  totalDays = months ? totalDays - months * 30 : totalDays;

  week = totalDays >= 7 ? Math.floor((totalDays % 365) / 7) : 0;
  totalDays = week ? totalDays - week * 7 : totalDays;

  days = totalDays < 7 ? Math.floor((totalDays % 365) % 7) : 0;

  if (year > 0) {
    const years = year > 1 ? 's' : '';
    if (months > 0) {
      const month = months > 1 ? 's' : '';
      return `${year} year${years} ${months} month${month}`;
    }
    return `${year} year${years}`;
  }

  if (months > 0) {
    const month = months > 1 ? 's' : '';
    if (week > 0) {
      const weeks = week > 1 ? 's' : '';
      return `${months} month${month} ${week} week${weeks}`;
    }
    return `${months} month${month}`;
  }

  if (week > 0) {
    const weeks = week > 1 ? 's' : '';
    if (days > 0) {
      const day = days > 1 ? 's' : '';
      return `${week} week${weeks} ${days} day${day}`;
    }
    return `${week} week${weeks}`;
  }

  return `${totalDays} days`;
};

export default parseDays;
