// assets
import { Element3 } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { Element3 };

// ==============================|| MENU ITEMS - OVERVIEW ||============================== //

const overview: NavItemType = {
  id: 'group-dashboard',
  type: 'group',
  children: [
    {
      id: 'overview',
      title: 'Overview',
      type: 'item',
      url: 'overview',
      icon: icons.Element3,
      breadcrumbs: false
    },
    {
      id: 'help',
      title: 'Help',
      type: 'item',
      url: 'help',
      display: false
    },
    {
      id: 'account-settings',
      title: 'Account Settings',
      type: 'item',
      url: 'account-settings',
      display: false
    },
    {
      id: 'notifications',
      title: 'Notifications',
      type: 'item',
      url: 'notifications',
      display: false
    }
  ]
};

export default overview;
