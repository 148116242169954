// material-ui
import { Box, CircularProgress, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { dispatch, useSelector } from 'store';
import { getCalculateRate } from 'store/reducers/jd-builder';

// project import
import RateCalculator from './RateCalculator';

// ==============================|| CV BUILDER - EXPERIENCES & QUALIFICATION ||============================== //

const Step4 = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useSelector((state) => state.jd);

  useEffect(() => {
    dispatch(getCalculateRate(id)).then(() => setLoading(true));
    // eslint-disable-next-line
  }, []);

  return (
    <Grid container rowSpacing={{ xs: 2.5, sm: 4 }} columnSpacing={3}>
      <Grid item xs={12}>
        {loading ? (
          <RateCalculator />
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default Step4;
