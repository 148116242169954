/* eslint-disable react/no-array-index-key */
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import { JobsProps } from 'types/jobs';

// third-party
import { format } from 'date-fns';

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 16,
    paddingLeft: 15,
    '@media max-width: 400': {
      paddingTop: 10,
      paddingLeft: 0
    }
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 24
  },
  subRow: {
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  column: {
    flexDirection: 'column'
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: '#496081',
    marginLeft: 8,
    marginRight: 8
  },
  title: {
    color: '#1F2937',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: 6
  },
  subTitle: {
    color: '#496081',
    fontSize: '10px'
  },
  chipTitle: {
    color: '#496081',
    fontSize: '10px',
    paddingLeft: 8,
    paddingRight: 8,
    paddingBottom: 4,
    paddingTop: 4
  },
  paragraph: {
    color: '#1F2937',
    fontSize: '12px'
  },
  card: {
    backgroundColor: '#F5F7F9',
    borderRadius: '12px',
    padding: 12
  },
  dash: {
    marginLeft: 4,
    marginRight: 4
  },
  chip: {
    border: '1px solid #D9E0EA',
    backgroundColor: '#F5F7F9',
    alignItems: 'center',
    borderRadius: '16px',
    marginLeft: 4,
    marginRight: 4,
    marginBottom: 8
  }
});

interface Props {
  job: JobsProps;
}

const Content = ({ job }: Props) => (
  <View style={styles.container}>
    <View style={styles.row}>
      <Text style={styles.subTitle}>{job.capacity}</Text>
      <View style={styles.dot} />
      <Text style={styles.subTitle}>{job.workLocation}</Text>
    </View>
    <View style={styles.row}>
      <Text style={styles.paragraph}>{job.jobDescription}</Text>
    </View>
    <View style={styles.row}>
      <View style={styles.card}>
        <Text style={styles.title}>Company Description</Text>
        <Text style={styles.paragraph}>{job.companyDescription}</Text>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Availability</Text>
        <View style={styles.subRow}>
          <View style={styles.chip}>
            <Text style={styles.chipTitle}>{job.startDate && format(new Date(job.startDate!), 'dd LLL yyyy')}</Text>
          </View>
          <Text style={styles.dash}>-</Text>
          <View style={styles.chip}>
            <Text style={styles.chipTitle}>{job.endDate ? format(new Date(job.endDate!), 'dd LLL yyyy') : 'Present'}</Text>
          </View>
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Rates</Text>
        <View style={styles.chip}>
          <Text style={styles.chipTitle}>
            £
            {job.timeSlot === 'Hourly'
              ? (Math.round(10 * (parseFloat(job.rate) * 7.5)) / 10) % 1 !== 0
                ? (Math.round(10 * (parseFloat(job.rate) * 7.5)) / 10).toFixed(2)
                : Math.round(10 * (parseFloat(job.rate) * 7.5)) / 10
              : job.timeSlot === 'Daily'
              ? (Math.round(10 * Number(job.rate)) / 10) % 1 !== 0
                ? (Math.round(10 * Number(job.rate)) / 10).toFixed(2)
                : Math.round(10 * Number(job.rate)) / 10
              : job.timeSlot === 'Monthly'
              ? (Math.round(10 * (parseFloat(job.rate) / 21.5)) / 10) % 1 !== 0
                ? (Math.round(10 * (parseFloat(job.rate) / 21.5)) / 10).toFixed(2)
                : Math.round(10 * (parseFloat(job.rate) / 21.5)) / 10
              : ''}{' '}
            / day
          </Text>
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Work Location</Text>
        <View style={styles.chip}>
          <Text style={styles.chipTitle}>{job.location}</Text>
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Job Tags</Text>
        <View style={styles.subRow}>
          {job.jobTags &&
            job.jobTags.map((item: string, index: number) => (
              <View style={styles.chip} key={index}>
                <Text style={styles.chipTitle}>{item}</Text>
              </View>
            ))}
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Essential Skills</Text>
        <View style={styles.subRow}>
          {job.essentialTags &&
            job.essentialTags.map((item: string, index: number) => (
              <View style={styles.chip} key={index}>
                <Text style={styles.chipTitle}>{item}</Text>
              </View>
            ))}
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Desireable Skills</Text>
        <View style={styles.subRow}>
          {job.desirableTags &&
            job.desirableTags.length > 0 &&
            job.desirableTags.map((item: string, index: number) => (
              <View style={styles.chip} key={index}>
                <Text style={styles.chipTitle}>{item}</Text>
              </View>
            ))}
        </View>
      </View>
    </View>
    <View style={styles.row}>
      <View style={styles.column}>
        <Text style={styles.title}>Cultural Preference Keywords</Text>
        <View style={styles.subRow}>
          {job.culturalTags &&
            job.culturalTags.map((item: string, index: number) => (
              <View style={styles.chip} key={index}>
                <Text style={styles.chipTitle}>{item}</Text>
              </View>
            ))}
        </View>
      </View>
    </View>
  </View>
);

export default Content;
