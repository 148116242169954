import { Font, Page, View, Document, StyleSheet } from '@react-pdf/renderer';

import Header from './Header';
import Content from './Content';
import { EmployeesProps } from 'types/employees';

const styles = StyleSheet.create({
  page: {
    padding: 30
  },
  container: {
    flex: 1,
    flexDirection: 'row',
    '@media max-width: 400': {
      flexDirection: 'column'
    }
  }
});

Font.register({
  family: 'Open Sans',
  src: `https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0e.ttf`
});

Font.register({
  family: 'Lato',
  src: `https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wWw.ttf`
});

Font.register({
  family: 'Lato Italic',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u8w4BMUTPHjxsAXC-v.ttf`
});

Font.register({
  family: 'Lato Bold',
  src: `https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPHA.ttf`
});

interface Props {
  employee: EmployeesProps;
}

const ExportPDFView = ({ employee }: Props) => (
  <Document title={`${employee.firstName} ${employee.lastName}`}>
    <Page size="A4" style={styles.page}>
      <Header employee={employee} />
      <View style={styles.container}>
        <Content employee={employee} />
      </View>
    </Page>
  </Document>
);

export default ExportPDFView;
