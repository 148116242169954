import { createSlice } from '@reduxjs/toolkit';
import { BE_URL, SAVING_FACTOR } from 'config';
import { dispatch } from 'store';
import { CalculateRecommendationsProps, CalculateType, ToolsProps } from 'types/tools';
import { Axios } from 'utils/axios';

const initialState: ToolsProps = {
  error: null,
  askingJobs: [],
  payingJobs: [],
  calculate: null,
  highestRates: 0,
  highestUp: 0,
  recommendedRates: 0,
  lowestRates: 0,
  lowestDown: 0,
  savedCost: 0,
  time: ''
};

const tools = createSlice({
  name: 'tools',
  initialState,
  reducers: {
    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    calculateRecommendationsSuccess(state, action) {
      const { type, data, calculate } = action.payload;
      let nationalAvg = 0;
      if (type === CalculateType.asking) {
        nationalAvg = state.askingJobs.filter((job) => job.title === calculate.jobTitle)[0].average;
      } else {
        nationalAvg = state.payingJobs.filter((job) => job.title === calculate.jobTitle)[0].average;
      }

      state.calculate = calculate;
      state.lowestRates = data.lowest;
      state.highestRates = data.highest;
      state.recommendedRates = data.recommended;
      state.savedCost = Math.floor(SAVING_FACTOR * (nationalAvg - Number(data.recommended)));
    },

    setAskingPublicJobsSuccess(state, action) {
      state.askingJobs = action.payload;
    },

    setPayingPublicJobsSuccess(state, action) {
      state.payingJobs = action.payload;
    },

    resetTools: () => initialState
  }
});

export default tools.reducer;

export const { resetTools } = tools.actions;

export function setPublicJobs(data: CalculateRecommendationsProps) {}

export function getAskingPublicJobs() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/public/jobs?type=hiring`);
      dispatch(tools.actions.setAskingPublicJobsSuccess(response.data.data.data));
    } catch (error) {
      dispatch(tools.actions.hasError(error));
    }
  };
}

export function getPayingPublicJobs() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/public/jobs?type=offering`);
      dispatch(tools.actions.setPayingPublicJobsSuccess(response.data.data.data));
    } catch (error) {
      dispatch(tools.actions.hasError(error));
    }
  };
}

export function calculateRecommendations(data: CalculateRecommendationsProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/public/jobs/calculate?type=${data.type}`, data.calculate);
      dispatch(tools.actions.calculateRecommendationsSuccess({ data: response.data.data, type: data.type, calculate: data.calculate }));
    } catch (error) {
      dispatch(tools.actions.hasError(error));
    }
  };
}
