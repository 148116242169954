import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Collapse, Stack, Tooltip, Typography } from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';

// third-party
import { format, formatDistance } from 'date-fns';

// project import
import AddExperience from './AddExperience';
import MainCard from 'components/MainCard';
import Dot from 'components/@extended/Dot';
import IconButton from 'components/@extended/IconButton';
import { deleteExperience } from 'store/reducers/cv-builder';
import { useDispatch, useSelector } from 'store';

// assets
import { Add, Edit, Element3, InfoCircle, Trash } from 'iconsax-react';
import { CVExperienceProps } from 'types/cv-builder';
import { EXP_END_DATE } from 'config';

// @ts-ignore
const diffDates = (d1: Date, d2) => {
  d1 = new Date(d1);
  d2 = new Date(d2);

  return formatDistance(d1, d2);
};

export const initialExperience: CVExperienceProps = {
  role: '',
  company: '',
  startDate: '',
  endDate: ''
};

// ==============================|| STEP 4 - EXPERIENCES ||============================== //

export default function Experiences() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [auto, setAuto] = useState<boolean>(true);
  const [collapse, setCollapse] = useState<boolean>(true);
  const [experience, setExperience] = useState<CVExperienceProps>(initialExperience);
  const toggleExperience = () => setCollapse(!collapse);

  const { profile, experiences } = useSelector((state) => state.cv);

  useEffect(() => {
    if (experiences) {
      const matchExp = experiences.filter((item) => item.role === profile.jobTitle && item.company === profile.jobCompany);
      if (matchExp.length > 0) {
        setAuto(false);
      }
    }
    // eslint-disable-next-line
  }, [experiences]);

  return (
    <Timeline
      sx={{
        p: 0,
        m: 0,
        '& .MuiTimelineItem-root': {
          minHeight: 64,
          '&::before': { flex: 0, p: 0 },
          '&:last-of-type': { minHeight: 'auto', '& .MuiTimelineContent-root': { mb: 0 } }
        },
        '& .MuiTimelineDot-root': {
          width: 40,
          height: 40,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 1.25,
          boxShadow: 'none',
          margin: 0,
          mr: 1.25
        },
        '& .MuiTimelineContent-root': { height: '100%', mb: 1.75 },
        '& .MuiTimelineConnector-root': { mr: 1.5, border: '1px solid', borderColor: 'secondary.300', bgcolor: 'transparent' }
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{ color: 'primary.main', bgcolor: 'primary.lighter', cursor: 'pointer' }}
            onClick={() => {
              setExperience({ ...initialExperience });
              setCollapse(true);
            }}
          >
            <Add size={20} />
          </TimelineDot>
          {experiences && experiences.length > 0 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Stack spacing={2.5}>
            <Box sx={{ ml: -1.5 }}>
              <Stack direction="row" alignItems="center">
                <Button
                  size="small"
                  onClick={() => {
                    setExperience({ ...initialExperience });
                    setCollapse(true);
                  }}
                >
                  Add Job Experiences
                </Button>
                <Tooltip title="Please provide the candidate’s previous job experiences. This determines the CV card’s level of experience and ensures correct recommended rates are provided using our calculator (if candidate role is supported)">
                  <InfoCircle size={20} color={theme.palette.primary.main} />
                </Tooltip>
              </Stack>
            </Box>
            <Collapse in={collapse}>
              <MainCard border content={false} sx={{ bgcolor: 'secondary.lighter', borderRadius: '6px', overflow: 'visible' }}>
                <Box sx={{ p: 2 }}>
                  <AddExperience toggleExperience={toggleExperience} experience={experience} auto={auto} />
                </Box>
              </MainCard>
            </Collapse>
          </Stack>
        </TimelineContent>
      </TimelineItem>
      {experiences &&
        experiences.length > 0 &&
        experiences.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot sx={{ color: 'text.primary', bgcolor: 'secondary.lighter' }}>
                <Element3 size={28} variant="Bold" />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: 0 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack>
                  <Typography variant="body2">
                    {item.role}{' '}
                    {item.endDate &&
                      format(new Date(item.endDate!), 'yyyy-MM') === format(new Date(EXP_END_DATE), 'yyyy-MM') &&
                      '(On-Going)'}
                  </Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography color="secondary.400">{item.company}</Typography>
                    <Dot size={6} color="secondary" />
                    <Typography color="secondary.400">
                      {diffDates(
                        new Date(item.startDate!),
                        item.endDate && format(new Date(item.endDate!), 'yyyy-MM') === format(new Date(EXP_END_DATE), 'yyyy-MM')
                          ? format(new Date(), 'yyyy-MM-dd')
                          : item.endDate!
                      )}
                    </Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <IconButton
                    size="small"
                    color="secondary"
                    sx={{ color: 'text.primary', width: 34, height: 34 }}
                    onClick={() => {
                      setExperience(item);
                      setCollapse(true);
                    }}
                  >
                    <Edit size={18} />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="secondary"
                    sx={{ color: 'text.primary', width: 34, height: 34 }}
                    onClick={() => dispatch(deleteExperience(item.id!))}
                  >
                    <Trash size={18} />
                  </IconButton>
                </Stack>
              </Stack>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}
