import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import { EmployeesProps } from 'types/employees';

// third-party
import { format } from 'date-fns';

import logo from 'assets/images/icons/qual.png';

const styles = StyleSheet.create({
  column: {
    flexDirection: 'column'
  },
  container: {
    flexDirection: 'row',
    marginTop: '10px',
    borderBottomWidth: 1,
    borderBottomColor: '#D9E0EA',
    borderBottomStyle: 'solid',
    alignItems: 'stretch'
  },
  detailColumn: {
    marginBottom: '10px',
    flexDirection: 'column',
    flexGrow: 9
  },
  name: {
    fontSize: '10px',
    fontFamily: 'Open Sans',
    marginBottom: '2px'
  },
  subtitle: {
    fontSize: '9px',
    color: '#496081',
    justifySelf: 'flex-end',
    fontFamily: 'Open Sans'
  },
  leftColumn: {
    flexDirection: 'column',
    width: 32,
    marginRight: 10,
    marginTop: 0
  },
  image: {
    width: 32,
    height: 32
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: '50%',
    backgroundColor: '#496081',
    marginLeft: 8,
    marginRight: 8
  }
});

// // @ts-ignore
// const diffDates = (d1: Date, d2) => {
//   d1 = new Date(d1);
//   d2 = new Date(d2);

//   return formatDistance(d1, d2);
// };

interface Props {
  employee: EmployeesProps;
}

const Qualifications = ({ employee }: Props) => {
  const qualifications = employee.qualifications ? employee.qualifications : false;

  return (
    <View style={styles.column}>
      {qualifications ? (
        <View style={styles.column}>
          {qualifications.map((qualification, i) => (
            <View style={styles.container} key={i}>
              <View style={styles.leftColumn}>
                <Image src={logo} style={styles.image} />
              </View>
              <View style={styles.detailColumn}>
                <Text style={styles.name}>
                  {qualification.degree} {qualification.endDate === null && '(Now)'}
                </Text>
                <View style={styles.row}>
                  <Text style={styles.subtitle}>{qualification.institute}</Text>
                  <View style={styles.dot} />
                  <Text style={styles.subtitle}>{format(new Date(qualification.endDate!), 'LLL yyyy')}</Text>
                </View>
              </View>
            </View>
          ))}
        </View>
      ) : (
        <Text style={styles.name}>-</Text>
      )}
    </View>
  );
};

export default Qualifications;
