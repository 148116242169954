import { Link } from 'react-router-dom';

// material-ui
import { Box } from '@mui/material';

// project import
import { URL_PREFIX } from 'config';
import IconButton from 'components/@extended/IconButton';

// assets
import { MessageQuestion } from 'iconsax-react';

// ==============================|| HEADER CONTENT - SUPPORT ||============================== //

const Support = () => (
  <Box sx={{ flexShrink: 0, ml: { xs: 1.25, md: 2.5 } }}>
    <Link to={`${URL_PREFIX}/help`} replace>
      <IconButton
        color="primary"
        variant="outlined"
        shape="rounded"
        size="large"
        aria-label="open support"
        aria-controls={'support-grow'}
        aria-haspopup="true"
      >
        <MessageQuestion size={20} />
      </IconButton>
    </Link>
  </Box>
);

export default Support;
