// project import
import overview from './overview';
import collaborate from './collaborate';
import manage from './manage';
import other from './other';
import { NavItemType } from 'types/menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [overview, collaborate, manage, other]
};

export default menuItems;
