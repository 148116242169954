import { Box, CircularProgress } from '@mui/material';
import { useSelector } from 'store';
import { ModalType } from 'types/master';
import CVBuilderManually from './cv-builder-manually';
import CVFindPreviewPopup from './cv-find-preview-popup';
import CVMatchPreviewPopup from './cv-match-preview-popup';
import CVPreviewPopup from './cv-preview-popup';
import CVShareModal from './cv-share-modal';
import JDBuilderManually from './jd-builder-manually';
import JDFindPreviewPopup from './jd-find-preview-popup';
import JDPreviewPopup from './jd-preview-popup';
import JDShareModal from './jd-share-modal';
import Publish from './publish';

const Modal = () => {
  const { modal } = useSelector((state) => state.master);

  let modalView = <></>;
  if (modal) {
    switch (modal.type) {
      case ModalType.publish:
        modalView = <Publish />;
        break;
      case ModalType.cvBuild:
        modalView = <CVBuilderManually />;
        break;
      case ModalType.jdBuild:
        modalView = <JDBuilderManually />;
        break;
      case ModalType.cvPreview:
        modalView = <CVPreviewPopup />;
        break;
      case ModalType.cvFindPreview:
        modalView = <CVFindPreviewPopup />;
        break;
      case ModalType.cvAppPreview:
        modalView = <CVFindPreviewPopup />;
        break;
      case ModalType.cvMatchPreview:
        modalView = <CVMatchPreviewPopup />;
        break;
      case ModalType.jdPreview:
        modalView = <JDPreviewPopup />;
        break;
      case ModalType.jdFindPreview:
        modalView = <JDFindPreviewPopup />;
        break;
      case ModalType.cvShare:
        modalView = <CVShareModal />;
        break;
      case ModalType.jdShare:
        modalView = <JDShareModal />;
        break;
      default:
        modalView = (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        );
    }
  }
  return modalView;
};

export default Modal;
