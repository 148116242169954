// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { CardMedia, Stack } from '@mui/material';

// third-party
import { useDropzone } from 'react-dropzone';

// project import
import RejectionFiles from './RejectionFiles';

// assets
import { Building4, GalleryEdit, Profile } from 'iconsax-react';

// types
import { UploadProps } from 'types/dropzone';

const RootWrapper = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: theme.palette.primary.lighter
}));

const DropzoneWrapper = styled('div')({
  zIndex: 0,
  width: '100%',
  height: '100%',
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  '& > *': { width: '100%', height: '100%' },
  '&:hover': {
    cursor: 'pointer',
    '& .placeholder': {
      zIndex: 9
    }
  }
});

const PlaceholderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.secondary,
  backgroundColor: theme.palette.primary.lighter,
  transition: theme.transitions.create('opacity', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&:hover': {
    opacity: 1,
    backgroundColor: 'transparent',
    '& svg path': {
      fill: theme.palette.grey[0]
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.8,
      backgroundColor: theme.palette.text.primary
    }
  }
}));

// ==============================|| UPLOAD - AVATAR ||============================== //

const AvatarUpload = ({ error, file, setFieldValue, sx, logo, ...other }: UploadProps) => {
  const theme = useTheme();

  // @ts-ignore
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    accept: {
      'image/*': []
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file: File) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (logo) {
            setFieldValue('logo', { name: file.name, base64: event?.target?.result });
          } else {
            setFieldValue('avatar', { name: file.name, base64: event?.target?.result });
          }
        };
        reader.readAsDataURL(file);
        return reader;
      });
    }
  });

  // @ts-ignore
  const thumbs = file && <CardMedia key="image" component="img" src={file?.base64 ? file?.base64 : file?.url} />;

  return (
    <>
      <RootWrapper
        sx={{
          ...((isDragReject || error) && {
            borderColor: 'error.light'
          }),
          borderRadius: logo ? 2.5 : '50%',
          ...sx
        }}
      >
        <DropzoneWrapper {...getRootProps()} sx={{ ...(isDragActive && { opacity: 0.6 }), borderRadius: logo ? 2.5 : '50%' }}>
          <input {...getInputProps()} multiple={false} />
          {thumbs}
          <PlaceholderWrapper
            className="placeholder"
            sx={{
              ...(thumbs && {
                opacity: 0,
                color: 'common.white'
              }),
              ...((isDragReject || error) && {
                bgcolor: 'error.lighter'
              })
            }}
          >
            <Stack spacing={0.5} alignItems="center" sx={{ zIndex: 9 }}>
              {file && <GalleryEdit color={theme.palette.grey[0]} variant="Bold" />}
              {!file && !logo && <Profile size={32} color={theme.palette.text.primary} variant="Bold" />}
              {!file && logo && <Building4 size={32} color={theme.palette.text.primary} variant="Bold" />}
            </Stack>
          </PlaceholderWrapper>
        </DropzoneWrapper>
      </RootWrapper>
      {fileRejections.length > 0 && <RejectionFiles fileRejections={fileRejections} />}
    </>
  );
};

export default AvatarUpload;
