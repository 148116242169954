import { useCallback, useState } from 'react';
import moment from 'moment';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Badge,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Skeleton,
  Typography
} from '@mui/material';

// project import
import { dispatch } from 'store';
import { setModal, setReload } from 'store/reducers/master';
import { setStore } from 'store/reducers/jd-builder';
import { removeJob, setFavouritesJob } from 'store/reducers/jobs';
import { openSnackbar } from 'store/reducers/snackbar';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import ReadMore from 'components/@extended/ReamMore';
import ChipMore from 'components/@extended/ChipMore';
import MainCard from 'components/MainCard';
import WarningPopup from './WarningPopup';
import debounce from 'utils/debounce';

// third-party
import CurrencyFormat from 'react-currency-format';

// types
import { JobsProps, JobStatus } from 'types/jobs';
import { ColorProps } from 'types/extended';

// assets
import { ArrowDown2, Award, Calendar, Global, Heart, ExportCurve, More, Tag, Link1, Profile2User, Element3 } from 'iconsax-react';
import { ModalType } from 'types/master';
import { MatchesProps, ReviewApplicantStatus } from 'types/employees';
import useAuth from 'hooks/useAuth';
import { UserRole } from 'types/user';
import parseMonths from 'utils/parseMonths';

interface Props {
  jobsData: JobsProps;
  isFindCard?: boolean;
  isExportCard?: boolean;
  isFavourites?: boolean;
  isCollapsed?: boolean;
  isCardView?: boolean;
  isApplication?: boolean;
  isMatch?: boolean;
  isProject?: boolean;
}

// ==============================|| JOBS CARD ||============================== //

function JobsCard({ jobsData, isFindCard, isExportCard, isFavourites, isCollapsed, isCardView, isApplication, isMatch, isProject }: Props) {
  const theme = useTheme();
  const { user } = useAuth();

  const {
    id,
    logo,
    jobTitle,
    capacity,
    jobDescription,
    jobCompany,
    workLocation,
    location,
    experience,
    rate,
    timeSlot,
    jobTags,
    createdAt,
    status,
    favourite,
    matches,
    daysAvailable,
    userId
  } = jobsData;

  const isCreator = user?.id === userId || user?.role === UserRole.admin;
  const isAccess = isCreator || status === JobStatus.CONTRACTED;

  const favouriteCollapsed = isCollapsed === undefined ? false : isCollapsed;
  const [collapsed, setCollapsed] = useState<boolean>(isFavourites ? favouriteCollapsed : true);
  const [openRemove, setOpenRemove] = useState<boolean>(false);
  const [isFavourite, setIsFavourite] = useState<boolean>(favourite === undefined ? false : favourite);
  const [iconHover, setIconHover] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
  const openMenu = Boolean(anchorEl);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
    if (!isFindCard) {
      setAnchorEl(event?.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const removeJobHandler = () => {
    setOpenRemove(true);
  };

  // remove job hanlder
  const handleRemove = (jobId: string) => {
    dispatch(removeJob(jobId));
    dispatch(
      openSnackbar({
        open: true,
        message: 'Job removed successfully',
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    setAnchorEl(null);
    setOpenRemove(false);
  };

  const handleCancel = () => {
    setOpenRemove(false);
  };

  const editHandler = () => {
    dispatch(setStore(id)).then(() => {
      dispatch(setModal({ type: ModalType.jdBuild }));
    });
  };

  let match: MatchesProps | false = matches?.length === 1 ? matches[0] : false;
  if (status === JobStatus.CONTRACTED && matches && matches?.length > 1) {
    match = matches?.filter((item) => item.status === ReviewApplicantStatus.PROJECT)[0];
  }

  let statusLabel;
  let color: ColorProps;
  switch (status) {
    case JobStatus.CONTRACTED:
      statusLabel = 'On Going';
      color = 'success';
      break;
    case JobStatus.MATCH:
    case JobStatus.REVIEW:
      statusLabel = 'On Review';
      color = 'primary';
      break;
    case JobStatus.REQUEST:
      statusLabel = 'Open Jobs';
      color = 'warning';
      break;
    case JobStatus.PUBLISHED:
      statusLabel = 'Published';
      color = 'info';
      break;
    case JobStatus.DRAFT:
    default:
      statusLabel = 'Draft';
      color = 'secondary';
      break;
  }

  const handleChange = (isFavourite: boolean) => {
    dispatch(setFavouritesJob(id, isFavourite)).then(() => dispatch(setReload(true)));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(handleChange), []);

  return (
    <MainCard border sx={{ height: 1, '.MuiCardContent-root': { height: 1, p: isCardView ? 2 : 3 } }}>
      <Grid container spacing={3} sx={{ height: 1 }}>
        <Grid item xs={12}>
          <Stack
            spacing={1}
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <List sx={{ width: 1, p: 0 }}>
              <ListItem disablePadding>
                <ListItemAvatar sx={{ mr: 1, minWidth: isCardView ? 40 : 56 }}>
                  <Avatar
                    sx={{ borderRadius: '10px', width: isCardView ? 32 : 48, height: isCardView ? 32 : 48 }}
                    color="secondary"
                    variant="square"
                    {...(logo && isAccess && { alt: jobTitle, src: logo })}
                  >
                    <Element3 size={34} variant="Bold" color="black" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography variant={isCardView ? 'body3' : 'subtitle3'} flexWrap="wrap">
                      {jobTitle}
                    </Typography>
                  }
                  secondary={
                    <>
                      {isAccess ? (
                        <Typography component="div" variant={isCardView ? 'caption1' : 'body1'} color="secondary" flexWrap="wrap">
                          {jobCompany}
                        </Typography>
                      ) : (
                        <Skeleton height={20} width={100} animation={false} />
                      )}
                    </>
                  }
                />
              </ListItem>
            </List>
            {!isExportCard && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                {(isFindCard || isFavourites || isCardView) && (
                  <IconButton
                    color="secondary"
                    onMouseEnter={() => setIconHover(!iconHover)}
                    onMouseLeave={() => setIconHover(!iconHover)}
                    onClick={() => {
                      optimizedFn(!isFavourite);
                      setIsFavourite(!isFavourite);
                    }}
                  >
                    <Heart
                      variant={isFavourite ? 'Bold' : 'Outline'}
                      size={20}
                      color={isFavourite || iconHover ? theme.palette.primary.main : theme.palette.text.primary}
                    />
                  </IconButton>
                )}
                <IconButton color="secondary" onClick={() => dispatch(setModal({ type: ModalType.jdShare, id }))}>
                  <ExportCurve color={theme.palette.text.primary} />
                </IconButton>
                {!isFindCard && !isFavourites && (
                  <IconButton color="secondary" onClick={handleClickOpen}>
                    <More color={theme.palette.text.primary} style={{ transform: 'rotate(90deg)' }} />
                  </IconButton>
                )}
                {isFavourites && (
                  <IconButton color="secondary" onClick={() => setCollapsed(!collapsed)}>
                    <ArrowDown2 color={theme.palette.text.primary} {...(collapsed && { style: { transform: 'rotate(-180deg)' } })} />
                  </IconButton>
                )}
              </Stack>
            )}

            {/* menu for edit/remove JD */}
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button'
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem onClick={editHandler}>Edit</MenuItem>
              {(status === JobStatus.DRAFT || status === JobStatus.PUBLISHED) && <MenuItem onClick={removeJobHandler}>Remove</MenuItem>}
            </Menu>

            {/* warning popup on removing JD */}
            <WarningPopup
              title={
                <Typography variant="title2" sx={{ pt: 1.5 }}>
                  Are you sure to remove?
                </Typography>
              }
              message={
                <Typography color="textSecondary" sx={{ pt: 1.5 }}>
                  Job will be removed
                </Typography>
              }
              open={openRemove}
              silentClose={handleCancel}
              saveButton={
                <Button fullWidth variant="contained" onClick={() => handleRemove(id)}>
                  Remove
                </Button>
              }
              closeButton={
                <Button fullWidth variant="outlined" color="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
              }
            />
          </Stack>
        </Grid>
        {!isCardView && (
          <Collapse
            in={collapsed}
            sx={{ width: 1, pl: 3 }}
            {...((collapsed || isCollapsed === undefined) &&
              !isExportCard && { sx: { width: 1, pl: 3, height: { xs: 'auto', md: 'calc(100% - 140px)' } } })}
          >
            <Grid item xs={12} sx={{ mt: 0 }}>
              <Grid container spacing={3} sx={{ mt: 0 }}>
                <Grid item xs={12} sx={{ pt: '0 !important', mt: 3 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1.25}>
                    {!isFindCard && (
                      <Chip
                        label={statusLabel}
                        sx={{
                          height: 24,
                          ...(status === JobStatus.DRAFT && {
                            bgcolor: 'secondary.darker'
                          })
                        }}
                        color={color}
                      />
                    )}
                    <Stack direction="row" spacing={1.25} alignItems="center">
                      {!isFindCard && <Badge variant="dot" sx={{ '& .MuiBadge-badge': { bgcolor: theme.palette.grey[300] } }} />}
                      <Typography sx={{ color: theme.palette.grey[700] }}>{capacity}</Typography>
                      <Badge variant="dot" sx={{ '& .MuiBadge-badge': { bgcolor: theme.palette.grey[300] } }} />
                      <Typography sx={{ color: theme.palette.grey[700] }}>{workLocation}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
                {jobDescription && (
                  <Grid item xs={12}>
                    <ReadMore>{jobDescription}</ReadMore>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <List sx={{ p: 0, overflow: 'hidden', '& .MuiListItem-root': { px: 0, py: 0.5 } }}>
                        <ListItem>
                          <ListItemIcon>
                            <Global size="20" style={{ color: theme.palette.grey[700] }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <>
                                {isAccess ? (
                                  <Typography sx={{ color: theme.palette.grey[700] }}>
                                    {jobCompany ? `${jobCompany} - ${location}` : '-'}
                                  </Typography>
                                ) : (
                                  <Stack direction="row" alignItems="center" spacing={0.25}>
                                    <Skeleton width={65} height={20} animation={false} />
                                    <Typography sx={{ color: theme.palette.grey[700] }}> - {location}</Typography>
                                  </Stack>
                                )}
                              </>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Calendar size="20" style={{ color: theme.palette.grey[700] }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography sx={{ color: theme.palette.grey[700] }}>
                                {daysAvailable ? parseMonths(Number(daysAvailable)) : '-'}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <List sx={{ p: 0, overflow: 'hidden', '& .MuiListItem-root': { px: 0, py: 0.5 } }}>
                        <ListItem>
                          <ListItemIcon>
                            <Award size="20" style={{ color: theme.palette.grey[700] }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography sx={{ color: theme.palette.grey[700], textTransform: 'capitalize' }}>
                                {experience === 'middle' ? 'Intermediate' : experience}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Tag size="20" style={{ color: theme.palette.grey[700] }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography sx={{ color: theme.palette.grey[700] }}>
                                {rate && (
                                  <>
                                    <CurrencyFormat
                                      fixedDecimalScale
                                      value={
                                        timeSlot === 'Hourly'
                                          ? (Math.round(10 * (parseFloat(rate) * 7.5)) / 10) % 1 !== 0
                                            ? (Math.round(10 * (parseFloat(rate) * 7.5)) / 10).toFixed(2)
                                            : Math.round(10 * (parseFloat(rate) * 7.5)) / 10
                                          : timeSlot === 'Daily'
                                          ? (Math.round(10 * Number(rate)) / 10) % 1 !== 0
                                            ? (Math.round(10 * Number(rate)) / 10).toFixed(2)
                                            : Math.round(10 * Number(rate)) / 10
                                          : timeSlot === 'Monthly'
                                          ? (Math.round(10 * (parseFloat(rate) / 21.5)) / 10) % 1 !== 0
                                            ? (Math.round(10 * (parseFloat(rate) / 21.5)) / 10).toFixed(2)
                                            : Math.round(10 * (parseFloat(rate) / 21.5)) / 10
                                          : ''
                                      }
                                      displayType="text"
                                      thousandSeparator
                                      prefix="£"
                                    />{' '}
                                    / day
                                  </>
                                )}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {jobTags && (
                    <Grid item xs={12}>
                      <ChipMore chips={jobTags} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        )}
        {!isExportCard && (
          <Collapse in={collapsed} sx={{ width: 1, pl: 3 }}>
            <Grid item xs={12}>
              <Grid container spacing={3} sx={{ mt: 0 }}>
                {!isCardView && (
                  <Grid item xs={12} sx={{ pt: '0 !important', mt: 3 }}>
                    <Divider />
                  </Grid>
                )}
                {isCardView &&
                  matches &&
                  (status === JobStatus.MATCH ||
                    status === JobStatus.REQUEST ||
                    status === JobStatus.REVIEW ||
                    status === JobStatus.CONTRACTED) && (
                    <Grid item xs={12}>
                      <MainCard
                        border
                        content={false}
                        sx={{
                          borderRadius: 2,
                          cursor: 'pointer',
                          ...{ ...(isCardView && status === JobStatus.MATCH && { boxShadow: theme.customShadows.xLarge }) }
                        }}
                        onClick={() => {
                          dispatch(
                            setModal({
                              type: isProject && match ? ModalType.cvPreview : ModalType.jdPreview,
                              id: isProject && match ? match.cv.id : id,
                              matches
                            })
                          );
                        }}
                      >
                        <Box sx={{ p: 1 }}>
                          <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                            {isApplication && (
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar size="xs" color="secondary" variant="rounded">
                                  <Profile2User size={18} variant="Bold" color={theme.palette.primary.main} />
                                </Avatar>
                                <Typography variant="caption2" color="secondary.dark">
                                  {matches.length} Applicants
                                </Typography>
                              </Stack>
                            )}
                            {isMatch && (
                              <Chip
                                sx={{ height: '22px' }}
                                clickable
                                color="success"
                                variant="light"
                                label={<Typography variant="caption2">You have {matches.length} matches applicants</Typography>}
                              />
                            )}
                            {isProject && match && (
                              <Stack direction="row" alignItems="center" spacing={1}>
                                <Avatar
                                  size="xs"
                                  alt={`${match.cv.firstName} ${match.cv.lastName}`}
                                  src={match.cv?.image as string}
                                  color="primary"
                                  type="filled"
                                />
                                <Typography variant="caption2" color="secondary.dark">
                                  {match.cv.firstName} {match.cv.lastName}
                                </Typography>
                              </Stack>
                            )}
                            <Link1 size={18} />
                          </Stack>
                        </Box>
                      </MainCard>
                    </Grid>
                  )}
                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography color="textSecondary">{moment(createdAt).fromNow()}</Typography>
                    {!isFavourites && !isProject && (
                      <Button
                        variant="dashed"
                        size="small"
                        onClick={() => {
                          dispatch(setModal({ type: isFindCard ? ModalType.jdFindPreview : ModalType.jdPreview, id, matches }));
                        }}
                      >
                        Preview
                      </Button>
                    )}
                    {match && isProject && (
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => {
                          // @ts-ignore
                          dispatch(setModal({ type: ModalType.cvMatchPreview, id: match.cv.id, match }));
                        }}
                      >
                        Track
                      </Button>
                    )}
                    {isFavourites && (
                      <Button
                        variant="dashed"
                        size="small"
                        onClick={() => {
                          setCollapsed(false);
                        }}
                      >
                        See less
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        )}
      </Grid>
    </MainCard>
  );
}

export default JobsCard;
