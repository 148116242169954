import { ReactElement, useEffect } from 'react';
import { dispatch } from 'store';
import useAuth from 'hooks/useAuth';
import { startConnecting } from 'store/reducers/sockets';

// ==============================|| Connect sockets ||============================== //

const Sockets = ({ children }: { children: ReactElement | null }) => {
  const { user } = useAuth();
  useEffect(() => {
    if (user) {
      dispatch(startConnecting());
    }
  }, [user]);

  return children || null;
};

export default Sockets;
