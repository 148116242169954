// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Stack } from '@mui/material';

// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import DrawerToggler from '../../DrawerToggler';
import Logo from 'components/logo';

// ==============================|| DRAWER HEADER ||============================== //

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const DrawerHeader = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();

  return (
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" alignItems="center" justifyContent={open ? 'space-between' : 'center'} sx={{ width: 1 }}>
        <Logo isIcon={!open} />
        <Box sx={{ display: { xs: 'none', lg: open ? 'flex' : 'none' }, mr: 2.5 }}>
          <DrawerToggler open={open} handleDrawerToggle={handleDrawerToggle} />
        </Box>
      </Stack>
    </DrawerHeaderStyled>
  );
};

export default DrawerHeader;
