import { MatchesProps } from './employees';

export interface JobsProps {
  inputValue?: string;
  title: string;
  id?: number;
}

export interface CityProps {
  inputValue?: string;
  name: string;
  id?: number;
}

export interface CountyProps {
  inputValue?: string;
  name: string;
  id?: number;
}

export interface JobTagProps {
  inputValue?: string;
  label: string;
  id?: number;
}

export interface EssentialSkillsProps {
  inputValue?: string;
  label: string;
  id?: number;
}

// export interface EssentialSkillsProps {
//   inputValue?: string;
//   label: string;
//   id?: number;
// }

// export interface DesirableSkillsProps {
//   inputValue?: string;
//   label: string;
//   id?: number;
// }

// export interface CulturalPeferencesProps {
//   inputValue?: string;
//   label: string;
//   id?: number;
// }

// export interface CulturalPeferencesProps {
//   inputValue?: string;
//   label: string;
//   id?: number;
// }

export interface RoleProps {
  inputValue?: string;
  title: string;
  id?: number;
}

// export interface DegreeProps {
//   inputValue?: string;
//   title: string;
//   id?: number;
// }

export enum ModalType {
  publish = 'PUBLISH',
  cvBuild = 'CV-BUILD',
  jdBuild = 'JD-BUILD',
  cvPreview = 'CV-PREVIEW',
  cvFindPreview = 'CV-FIND-PREVIEW',
  cvAppPreview = 'CV-APPLICATION-PREVIEW',
  cvMatchPreview = 'CV-MATCH-PREVIEW',
  jdPreview = 'JD-PREVIEW',
  jdFindPreview = 'JD-FIND-PREVIEW',
  cvShare = 'CV-SHARE',
  jdShare = 'JD-SHARE'
}

export type MasterModalProps = {
  open: boolean;
  type: ModalType;
  id?: string;
  match?: MatchesProps;
  matches?: MatchesProps[];
  closeNavigate?: string;
};

export type FilterRatesProps = {
  label: string;
  value: string;
};

export type DurationProps = {
  label: string;
  value: string;
};

export type ExperiencesProps = {
  label: string;
  value: string;
  level: string;
};

export type BusinessTypeCoverProps = {
  type: string;
  label: string;
  text?: string;
  checked: boolean;
  cover: number;
};

export interface MasterProps {
  error: object | string | null;
  modal: MasterModalProps | false;
  reload: boolean;
  jobs: string[];
  capacities: string[];
  experienceLevels: string[];
  experiences: ExperiencesProps[];
  durations: DurationProps[];
  workSlots: string[];
  locations: string[];
  jobTags: string[];
  essentialSkills: string[];
  desirableSkills: string[];
  culturalPeferences: string[];
  roles: string[];
  degrees: string[];
  rates: FilterRatesProps[];
  businessCover: BusinessTypeCoverProps[];
}
