import { useState } from 'react';

// material-ui
import { Box, Button, Collapse, Stack, Typography } from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';

// third-party
// import { formatDistance, format } from 'date-fns';
import { format } from 'date-fns';

// project import
import AddQualification from './AddQualification';
import MainCard from 'components/MainCard';
import Dot from 'components/@extended/Dot';
import IconButton from 'components/@extended/IconButton';
import { deleteQualification } from 'store/reducers/cv-builder';
import { useDispatch, useSelector } from 'store';

// assets
import { Add, Teacher, Edit, Trash } from 'iconsax-react';
import { CVQualificationProps } from 'types/cv-builder';

// // @ts-ignore
// const diffDates = (d1: Date, d2) => {
//   d1 = new Date(d1);
//   d2 = new Date(d2);

//   return formatDistance(d1, d2);
// };

export const initialQualification: CVQualificationProps = {
  id: '',
  degree: '',
  institute: '',
  startDate: '',
  endDate: ''
};

// ==============================|| STEP 4 - QUALIFICATIONS ||============================== //

export default function Qualifications() {
  const dispatch = useDispatch();

  const [collapse, setCollapse] = useState(false);
  const [qualification, setQualifications] = useState<CVQualificationProps>(initialQualification);
  const toggleQualification = () => setCollapse(!collapse);

  const { qualifications } = useSelector((state) => state.cv);

  return (
    <Timeline
      sx={{
        p: 0,
        m: 0,
        '& .MuiTimelineItem-root': {
          minHeight: 64,
          '&::before': { flex: 0, p: 0 },
          '&:last-of-type': { minHeight: 'auto', '& .MuiTimelineContent-root': { mb: 0 } }
        },
        '& .MuiTimelineDot-root': {
          width: 40,
          height: 40,
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: 1.25,
          boxShadow: 'none',
          margin: 0,
          mr: 1.25
        },
        '& .MuiTimelineContent-root': { height: '100%', mb: 1.75 },
        '& .MuiTimelineConnector-root': { mr: 1.5, border: '1px solid', borderColor: 'secondary.300', bgcolor: 'transparent' }
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            sx={{ color: 'primary.main', bgcolor: 'primary.lighter', cursor: 'pointer' }}
            onClick={() => {
              setQualifications({ ...initialQualification });
              setCollapse(true);
            }}
          >
            <Add size={20} />
          </TimelineDot>
          {qualifications && qualifications.length > 0 && <TimelineConnector />}
        </TimelineSeparator>
        <TimelineContent>
          <Stack spacing={2.5}>
            <Box sx={{ ml: -1.5 }}>
              <Button
                size="small"
                onClick={() => {
                  setQualifications({ ...initialQualification });
                  setCollapse(true);
                }}
              >
                Add Qualifications
              </Button>
            </Box>
            <Collapse in={collapse}>
              <MainCard border content={false} sx={{ bgcolor: 'secondary.lighter', borderRadius: '6px', overflow: 'visible' }}>
                <Box sx={{ p: 2 }}>
                  <AddQualification toggleQualification={toggleQualification} qualification={qualification} />
                </Box>
              </MainCard>
            </Collapse>
          </Stack>
        </TimelineContent>
      </TimelineItem>
      {qualifications &&
        qualifications.length > 0 &&
        qualifications.map((item, index) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineDot sx={{ color: 'text.primary', bgcolor: 'secondary.lighter' }}>
                <Teacher size={28} />
              </TimelineDot>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent sx={{ py: 0 }}>
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack>
                  <Typography variant="body2">{item.degree}</Typography>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography color="secondary.400">{item.institute}</Typography>
                    <Dot size={6} color="secondary" />
                    <Typography color="secondary.400">{item.endDate && format(new Date(item.endDate!), 'LLL yyyy')}</Typography>
                  </Stack>
                </Stack>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <IconButton
                    size="small"
                    color="secondary"
                    sx={{ color: 'text.primary', width: 34, height: 34 }}
                    onClick={() => {
                      setQualifications(item);
                      setCollapse(true);
                    }}
                  >
                    <Edit size={18} />
                  </IconButton>
                  <IconButton
                    size="small"
                    color="secondary"
                    sx={{ color: 'text.primary', width: 34, height: 34 }}
                    onClick={() => dispatch(deleteQualification(item.id!))}
                  >
                    <Trash size={18} />
                  </IconButton>
                </Stack>
              </Stack>
            </TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
}
