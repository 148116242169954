import { ButtonProps } from '@mui/material';

export type NotificationsAction = {
  title: string;
  button: ButtonProps;
  action?: string | Function;
};

// notifications list
export type NotificationsProps = {
  id: string;
  avatar?: string;
  logo?: string;
  badge?: boolean;
  title: string;
  createdAt: string;
  actions?: NotificationsAction[];
};

export interface NotificationStateProps {
  notifications: NotificationsProps[];
  notification: NotificationsProps | null;
  error: object | string | null;
}

export enum NotificationEvent {
  SendNotification = 'send_notification',
  ReceiveNotification = 'receive_notification',
  RequestAllNotifications = 'request_all_notifications',
  ReceiveAllNotifications = 'receive_all_notifications',
  AddNewUser = 'add_new_user'
}

export interface NotificationMessage {
  id: number;
  content: string;
  author: {
    email: string;
  };
}
