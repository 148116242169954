import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { useMediaQuery, Box, Container } from '@mui/material';

// project import
import Drawer from './Drawer';
import Header from './Header';
import navigation from 'menu-items';
import useConfig from 'hooks/useConfig';
import { openDrawer } from 'store/reducers/menu';
import Breadcrumbs from 'components/@extended/Breadcrumbs';

// types
import { RootStateProps } from 'types/root';
import LeaveFeedback from './LeaveFeedback';

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const matchDownLG = useMediaQuery(theme.breakpoints.down('xl'));

  const { container, miniDrawer, borderRadius } = useConfig();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const menu = useSelector((state: RootStateProps) => state.menu);
  const { drawerOpen } = menu;

  const findCandidatesPadding = menu.openItem.filter((item) => item === 'find-job' || item === 'find-candidates').length === 1;

  // drawer toggler
  const [error404, setError404] = useState(false);
  const [open, setOpen] = useState(!miniDrawer || drawerOpen);
  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer({ drawerOpen: !open }));
  };

  // set media wise responsive drawer
  useEffect(() => {
    if (!miniDrawer) {
      setOpen(!matchDownLG);
      dispatch(openDrawer({ drawerOpen: !matchDownLG }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownLG]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  useEffect(() => {
    if (error404) setError404(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <Box sx={{ display: 'flex', width: '100%' }}>
      <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          width: 1,
          flexGrow: 1,
          mt: 10,
          pl: { xs: 2, sm: 2.5, lg: 0 },
          pr: { xs: 2, sm: 2.5 },
          pb: { xs: 2, sm: 2.5 }
        }}
      >
        <Container
          maxWidth={container ? 'lg' : false}
          sx={{
            p: { xs: findCandidatesPadding ? 0 : 2.5, sm: findCandidatesPadding ? 0 : 3 },
            bgcolor: 'grey.100',
            borderRadius: `${borderRadius}px`,
            minHeight: `calc(100vh - 100px)`
          }}
        >
          {!error404 && <Breadcrumbs navigation={navigation} titleBottom card={false} divider={false} />}
          <Outlet context={{ setError404 }} />
        </Container>
      </Box>
      <LeaveFeedback />
    </Box>
  );
};

export default MainLayout;
