// project import
import IconButton from 'components/@extended/IconButton';

// assets
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react';

interface Props {
  open: boolean;
  handleDrawerToggle?: () => void;
}

const DrawerToggler = ({ open, handleDrawerToggle }: Props) => (
  <IconButton aria-label="open drawer" onClick={handleDrawerToggle} edge="start" color="primary" variant="light" size="small">
    {!open ? <ArrowRight2 size={18} /> : <ArrowLeft2 size={18} />}
  </IconButton>
);

export default DrawerToggler;
