const debounce = (func: any, timeout?: number) => {
  let timer: any;
  return function (...args: any[]) {
    // @ts-ignore
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, timeout || 1000);
  };
};

export default debounce;
