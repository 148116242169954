// material-ui
import { Theme } from '@mui/material/styles';
import useConfig from 'hooks/useConfig';

// ==============================|| OVERRIDES - DIALOG CONTENT TEXT ||============================== //

export default function Paper(theme: Theme) {
  const { borderRadius } = useConfig();

  return {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: `${borderRadius}px`
        }
      }
    }
  };
}
