import { useCallback, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';

// third-party
import { formatDistance, format } from 'date-fns';
import { PDFDownloadLink } from '@react-pdf/renderer';

// project import
import ExportPDFView from './export-pdf-view';
import Dot from 'components/@extended/Dot';
import Avatar from 'components/@extended/Avatar';
import ReadMore from 'components/@extended/ReamMore';
import IconButton from 'components/@extended/IconButton';
import debounce from 'utils/debounce';

import { changeStatus } from 'store/reducers/cv-builder';
import { resetEmployee, setFavouritesEmployee } from 'store/reducers/employees';
import { setModal } from 'store/reducers/master';
import { dispatch } from 'store';

// types
import { ModalType } from 'types/master';
import { EmployeesProps, EmployeeStatus } from 'types/employees';
import { CVBuilderProps } from 'types/cv-builder';

// assets
import { Add, DocumentDownload, Edit, Element3, ExportCurve, Heart, Profile, Teacher } from 'iconsax-react';
import { validateCV } from 'utils/validateCV';
import useAuth from 'hooks/useAuth';
import CurrencyFormat from 'react-currency-format';
import { UserRole } from 'types/user';
import { openSnackbar } from 'store/reducers/snackbar';
import { EXP_END_DATE } from 'config';

// @ts-ignore
const diffDates = (d1: Date, d2) => {
  d1 = new Date(d1);
  d2 = new Date(d2);
  return formatDistance(d1, d2);
};

interface Props {
  employee: EmployeesProps;
  cv: CVBuilderProps;
  closeModal: () => void;
}

const PreviewPopup = ({ employee, cv, closeModal }: Props) => {
  const theme = useTheme();

  const { user } = useAuth();
  const [valid, setValid] = useState<boolean>(false);
  const [isSubmited, setIsSubmited] = useState<boolean>(false);
  const [isFavourite, setIsFavourite] = useState<boolean>(employee && employee?.favourite !== undefined ? employee.favourite : false);
  const [iconHover, setIconHover] = useState<boolean>(false);

  const { profile, calculate, jobInfo, skills, experiences, qualifications } = cv;
  if (jobInfo.endDate && jobInfo.startDate) {
    validateCV(profile, calculate, jobInfo, skills, experiences, qualifications).then((res) => {
      setValid(res);
    });
  }

  const handleChange = (isFavourite: boolean, id: string) => {
    dispatch(setFavouritesEmployee(id, isFavourite));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(handleChange), []);

  const handlePublishBtn = async () => {
    if (employee.id) {
      setIsSubmited(true);
      await dispatch(changeStatus(EmployeeStatus.PUBLISHED, employee.id)).then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Your CV card is now published and searchable',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        setIsSubmited(false);
        closeModal();
      });
    }
  };

  const handleDraftBtn = async () => {
    if (employee) {
      setIsSubmited(true);
      await dispatch(changeStatus(EmployeeStatus.DRAFT, employee.id)).then(() => {
        dispatch(
          openSnackbar({
            open: true,
            message: 'Your CV card is now moved to Drafts (not published and searchable anymore)',
            variant: 'alert',
            alert: {
              color: 'success'
            },
            close: false
          })
        );
        setIsSubmited(false);
        closeModal();
      });
    }
  };

  const isCreator = user?.id === employee.userId || user?.role === UserRole.admin;
  const isAccess = isCreator || employee.status === EmployeeStatus.CONTRACTED;

  return (
    <Grid container rowSpacing={4}>
      <Grid item xs={12}>
        <Stack spacing={1} alignItems="center" justifyContent="center">
          {isAccess ? (
            <Typography align="center" variant="h5">
              {employee.firstName} {employee.lastName}
            </Typography>
          ) : (
            <Skeleton animation={false} width="35%" height={32} />
          )}
          <Box sx={{ position: 'absolute', top: 24, right: 24 }}>
            <Stack direction="row" spacing={0.5} alignItems="center">
              <IconButton
                color="secondary"
                onMouseEnter={() => setIconHover(!iconHover)}
                onMouseLeave={() => setIconHover(!iconHover)}
                onClick={() => {
                  optimizedFn(!isFavourite, employee.id);
                  setIsFavourite(!isFavourite);
                }}
                sx={{ padding: 0, width: 32, height: 32 }}
              >
                <Heart
                  variant={isFavourite ? 'Bold' : 'Outline'}
                  size={20}
                  color={isFavourite || iconHover ? theme.palette.primary.main : theme.palette.text.primary}
                />
              </IconButton>
              <IconButton
                sx={{ padding: 0, width: 32, height: 32 }}
                color="secondary"
                onClick={() => {
                  dispatch(setModal({ type: ModalType.cvShare, id: employee.id }));
                  dispatch(resetEmployee());
                }}
              >
                <ExportCurve size={20} color={theme.palette.text.primary} />
              </IconButton>
              <PDFDownloadLink
                document={<ExportPDFView employee={employee} />}
                fileName={`CV-${employee.jobCompany}-${employee.firstName}-${employee.lastName}.pdf`}
              >
                <IconButton sx={{ padding: 0, width: 32, height: 32 }} color="secondary">
                  <DocumentDownload size={20} color={theme.palette.text.primary} />
                </IconButton>
              </PDFDownloadLink>
              <IconButton sx={{ padding: 0, width: 32, height: 32 }} color="secondary" onClick={closeModal}>
                <Add size={20} color={theme.palette.text.primary} style={{ transform: 'rotate(45deg)' }} />
              </IconButton>
            </Stack>
          </Box>
          <Typography align="center" color="secondary">
            Make sure your information is clear and easy to understand for the project host
          </Typography>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
          <List disablePadding>
            <ListItem sx={{ p: 0 }}>
              <ListItemAvatar sx={{ mr: 2 }}>
                <Avatar size="lg" {...(isAccess && employee?.image && { alt: employee.firstName, src: employee?.image })}>
                  <Profile size={32} variant="Bold" color="black" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{ m: 0 }}
                primary={
                  <>
                    {isAccess ? (
                      <Typography variant="h6">
                        {employee.firstName} {employee.lastName}
                      </Typography>
                    ) : (
                      <Skeleton animation={false} width={150} height={24} />
                    )}
                  </>
                }
                secondary={
                  <Typography variant="subtitle1" color="secondary">
                    {employee.jobTitle}
                  </Typography>
                }
              />
            </ListItem>
          </List>

          {isCreator && (
            <Button
              variant="dashed"
              size="small"
              startIcon={<Edit size={18} />}
              onClick={() => dispatch(setModal({ type: ModalType.cvBuild }))}
            >
              Edit Fields
            </Button>
          )}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={3}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography color="secondary.dark">{employee.capacity}</Typography>
            <Dot color="secondary" size={6} />
            <Typography color="secondary.dark">{employee.workLocation}</Typography>
          </Stack>
          {employee?.biography && <ReadMore variant="subtitle1">{employee?.biography}</ReadMore>}
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Availability</Typography>
          <Stack direction="row" spacing={1} alignItems="center">
            <Chip
              variant="combined"
              label={
                <Typography variant="body2" color="secondary.dark">
                  {employee.startDate && format(new Date(employee.startDate!), 'dd LLL yyyy')}
                </Typography>
              }
              size="small"
            />
            <Typography color="secondary.dark">-</Typography>
            <Chip
              variant="combined"
              label={
                <Typography variant="body2" color="secondary.dark">
                  {employee.endDate ? format(new Date(employee.endDate!), 'dd LLL yyyy') : 'Present'}
                </Typography>
              }
              size="small"
            />
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Rates</Typography>
          <Box>
            <Chip
              variant="combined"
              label={
                <Typography variant="body2" color="secondary.dark">
                  {employee.rate ? (
                    <>
                      <CurrencyFormat
                        fixedDecimalScale
                        value={
                          employee.timeSlot === 'Hourly'
                            ? (Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10) % 1 !== 0
                              ? (Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10).toFixed(2)
                              : Math.round(10 * (parseFloat(employee.rate) * 7.5)) / 10
                            : employee.timeSlot === 'Daily'
                            ? (Math.round(10 * Number(employee.rate)) / 10) % 1 !== 0
                              ? (Math.round(10 * Number(employee.rate)) / 10).toFixed(2)
                              : Math.round(10 * Number(employee.rate)) / 10
                            : employee.timeSlot === 'Monthly'
                            ? (Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10) % 1 !== 0
                              ? (Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10).toFixed(2)
                              : Math.round(10 * (parseFloat(employee.rate) / 21.5)) / 10
                            : ''
                        }
                        displayType="text"
                        thousandSeparator
                        prefix="£"
                      />{' '}
                      / day
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
              }
              size="small"
            />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Work Location</Typography>
          <Box>
            <Chip
              variant="combined"
              label={
                <Typography variant="body2" color="secondary.dark" sx={{ display: 'flex', alignItems: 'center' }}>
                  {isAccess ? employee.jobCompany : <Skeleton width={50} height={16} animation={false} sx={{ mr: 0.5 }} />} -{' '}
                  {employee.location}
                </Typography>
              }
              size="small"
            />
          </Box>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Job Tags</Typography>
          <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
            {employee.jobTags &&
              employee.jobTags.map((item: string, index: number) => (
                <Chip
                  key={index}
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {item}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Essential Skills</Typography>
          <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
            {employee?.essentialTags &&
              employee.essentialTags.map((item: string, index: number) => (
                <Chip
                  key={index}
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {item}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Desireable Skills</Typography>
          <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
            {employee.desirableTags &&
              employee.desirableTags.length > 0 &&
              employee.desirableTags.map((item: string, index: number) => (
                <Chip
                  key={index}
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {item}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Cultural Preference Keywords</Typography>
          <Stack direction="row" alignItems="center" columnGap={1} rowGap={1} sx={{ flexWrap: 'wrap' }}>
            {employee.culturalTags &&
              employee.culturalTags.map((item: string, index: number) => (
                <Chip
                  key={index}
                  variant="combined"
                  label={
                    <Typography variant="body2" color="secondary.dark">
                      {item}
                    </Typography>
                  }
                  size="small"
                />
              ))}
          </Stack>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Experiences</Typography>
          <Timeline
            sx={{
              p: 0,
              m: 0,
              '& .MuiTimelineItem-root': {
                minHeight: 64,
                '&::before': { flex: 0, p: 0 },
                '&:last-of-type': { minHeight: 'auto', '& .MuiTimelineContent-root': { mb: 0 } }
              },
              '& .MuiTimelineDot-root': {
                width: 40,
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 1.25,
                boxShadow: 'none',
                margin: 0,
                mr: 1.25
              },
              '& .MuiTimelineContent-root': { height: '100%', mb: 1.75 },
              '& .MuiTimelineConnector-root': {
                mr: 1.5,
                border: '1px solid',
                borderColor: 'secondary.300',
                bgcolor: 'transparent'
              }
            }}
          >
            {employee.experiences &&
              employee.experiences.length > 0 &&
              employee.experiences.map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot sx={{ color: 'text.primary', bgcolor: 'secondary.lighter' }}>
                      <Element3 size={28} variant="Bold" />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 0 }}>
                    <Stack>
                      <Typography variant="body2">
                        {item.role}{' '}
                        {item.endDate &&
                          format(new Date(item.endDate), 'yyyy-MM') === format(new Date(EXP_END_DATE), 'yyyy-MM') &&
                          '(On-Going)'}
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography color="secondary.400">{item.company}</Typography>
                        <Dot size={6} color="secondary" />
                        <Typography color="secondary.400">
                          {diffDates(
                            new Date(item.startDate!),
                            item.endDate && format(new Date(item.endDate!), 'yyyy-MM') === format(new Date(EXP_END_DATE), 'yyyy-MM')
                              ? format(new Date(), 'yyyy-MM-dd')
                              : item.endDate!
                          )}
                        </Typography>
                      </Stack>
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Stack spacing={1}>
          <Typography variant="body3">Qualifications</Typography>
          <Timeline
            sx={{
              p: 0,
              m: 0,
              '& .MuiTimelineItem-root': {
                minHeight: 64,
                '&::before': { flex: 0, p: 0 },
                '&:last-of-type': { minHeight: 'auto', '& .MuiTimelineContent-root': { mb: 0 } }
              },
              '& .MuiTimelineDot-root': {
                width: 40,
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: 1.25,
                boxShadow: 'none',
                margin: 0,
                mr: 1.25
              },
              '& .MuiTimelineContent-root': { height: '100%', mb: 1.75 },
              '& .MuiTimelineConnector-root': {
                mr: 1.5,
                border: '1px solid',
                borderColor: 'secondary.300',
                bgcolor: 'transparent'
              }
            }}
          >
            {employee.qualifications &&
              employee.qualifications.length > 0 &&
              employee.qualifications.map((item, index) => (
                <TimelineItem key={index}>
                  <TimelineSeparator>
                    <TimelineDot sx={{ color: 'text.primary', bgcolor: 'secondary.lighter' }}>
                      <Teacher size={28} />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: 0 }}>
                    <Stack>
                      <Typography variant="body2">{item.degree}</Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <Typography color="secondary.400">{item.institute}</Typography>
                        <Dot size={6} color="secondary" />
                        <Typography color="secondary.400">{format(new Date(item.endDate!), 'LLL yyyy')}</Typography>
                      </Stack>
                    </Stack>
                  </TimelineContent>
                </TimelineItem>
              ))}
          </Timeline>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        <Stack direction="row" alignItems="center" justifyContent={!valid && isCreator ? 'space-between' : 'flex-end'}>
          {!valid && isCreator && (
            <Alert color="error" variant="border" icon={false} sx={{ color: 'error.main' }}>
              Please fill out the CV to Publish
            </Alert>
          )}
          <Stack direction="row" spacing={2} alignItems="center" justifyContent="flex-end">
            {employee.status === EmployeeStatus.PUBLISHED && isCreator && (
              <Button variant="outlined" color="secondary" onClick={() => handleDraftBtn()} disabled={!valid || isSubmited}>
                Move to Draft
              </Button>
            )}
            <Button
              variant={employee.status === EmployeeStatus.PUBLISHED && isCreator ? 'contained' : 'outlined'}
              color={employee.status === EmployeeStatus.PUBLISHED && isCreator ? 'primary' : 'secondary'}
              onClick={closeModal}
            >
              {isCreator ? 'Save Changes' : 'Close'}
            </Button>

            {employee.status === EmployeeStatus.DRAFT && isCreator && (
              <Button variant="contained" onClick={() => handlePublishBtn()} disabled={!valid || isSubmited}>
                Publish This CV
              </Button>
            )}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default PreviewPopup;
