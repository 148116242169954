// types
import { createSlice } from '@reduxjs/toolkit';
import { BusinessTypeCoverProps, MasterProps } from 'types/master';

// project imports
import { Axios } from 'utils/axios';
import { dispatch } from 'store';
import { BE_URL } from 'config';

export const initalBusinessCover: BusinessTypeCoverProps[] = [
  {
    type: 'employeer',
    label: 'Employer`s Liability',
    checked: false,
    cover: 0
  },
  {
    type: 'professional',
    label: 'Professional Indemnity',
    checked: false,
    cover: 0
  },
  {
    type: 'public',
    label: 'Public Liability',
    checked: false,
    cover: 0
  },
  {
    type: 'cyber',
    label: 'Cyber Protection',
    checked: false,
    cover: 0
  },
  {
    type: 'other',
    label: 'Other',
    text: '',
    checked: false,
    cover: 0
  }
];

// ==============================|| Master Props||============================== //
const initialState: MasterProps = {
  error: null,
  modal: false,
  reload: false,
  jobs: [],
  capacities: ['Full Time', 'Part Time', 'Fractional', 'Ad Hoc'],
  workSlots: ['Hourly', 'Daily', 'Monthly'],
  locations: ['100% Remote', 'Semi-Remote', 'Hybrid', '100% On Site'],
  experienceLevels: ['junior', 'middle', 'senior'],
  businessCover: initalBusinessCover,
  jobTags: [],
  essentialSkills: [],
  desirableSkills: [],
  culturalPeferences: [],
  roles: [],
  degrees: [],
  experiences: [
    { label: 'Junior (<2 Years)', value: '0-2', level: '1' },
    { label: 'Intermediate (2-5 Years)', value: '2-5', level: '2' },
    { label: 'Senior (>5 Years)', value: '5-10', level: '10' }
  ],
  durations: [
    { label: '1 Month', value: 'M1' },
    { label: '3 Months', value: 'M3' },
    { label: '6 Months', value: 'M6' },
    { label: '1 year', value: 'Y1' }
  ],
  rates: [
    { label: '<£300', value: '0-300' },
    { label: '£300-£500', value: '300-500' },
    { label: '£500-£1K', value: '500-1000' },
    { label: '>£1000', value: '1000' }
  ]
};

// ==============================|| SLICE - CV BUILDER ||============================== //

const master = createSlice({
  name: 'master',
  initialState,
  reducers: {
    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    // set modal
    setModal(state, action) {
      state.reload = false;
      state.modal = {
        open: true,
        type: action.payload.type,
        id: action.payload.id,
        match: action.payload.match,
        matches: action.payload.matches,
        closeNavigate: action.payload.closeNavigate
      };
    },

    // close modal
    resetModal(state) {
      state.reload = true;
      state.modal = false;
    },

    // set Reload
    setReload(state, action) {
      state.reload = action.payload;
    },

    // set jobs data
    setJobs(state, action) {
      const jobTitles = action.payload.map((e: any) => e.title);
      state.jobs = jobTitles;
    },
    // add jobs data
    addJobs(state, action) {
      state.jobs = [...state.jobs, action.payload];
    },
    // set capacities data
    setCapacities(state, action) {
      const capacitiesTitles = action.payload.map((e: any) => e.title);
      state.capacities = capacitiesTitles;
    },
    // add capacities data
    addCapacities(state, action) {
      state.capacities = [...state.capacities, action.payload];
    },
    // set job tags data
    setJobTags(state, action) {
      const jobTagsTitles = action.payload.map((e: any) => e.title);
      state.jobTags = jobTagsTitles;
    },
    // add job tags  data
    addJobTags(state, action) {
      state.jobTags = [...state.jobTags, action.payload];
    },
    // set essential skills data
    setEssentialSkills(state, action) {
      const essentialSkillTitles = action.payload.map((e: any) => e.title);
      state.essentialSkills = essentialSkillTitles;
    },
    // add essential skills  data
    addEssentialSkills(state, action) {
      state.essentialSkills = [...state.essentialSkills, action.payload];
    },
    // set desirable skills data
    setDesirableSkills(state, action) {
      const desirableSkillTitles = action.payload.map((e: any) => e.title);
      state.desirableSkills = desirableSkillTitles;
    },
    // add desirable skills  data
    addDesirableSkills(state, action) {
      state.desirableSkills = [...state.desirableSkills, action.payload];
    },
    // set cultural peferences data
    setCulturalPeferences(state, action) {
      const culturalPeferencesTitles = action.payload.map((e: any) => e.title);
      state.culturalPeferences = culturalPeferencesTitles;
    },
    // add cultural peferences data
    addCulturalPeferences(state, action) {
      state.culturalPeferences = [...state.culturalPeferences, action.payload];
    },

    // set desirable skills data
    setRoles(state, action) {
      const roles = action.payload.map((e: any) => e.title);
      state.roles = roles;
    },

    // set cultural peferences data
    setDegrees(state, action) {
      const degree = action.payload.map((e: any) => e.title);
      state.degrees = degree;
    },

    addDegrees(state, action) {
      state.degrees = [...state.degrees, action.payload];
    }
  }
});

export default master.reducer;

export const { setModal, setReload, resetModal } = master.actions;

export function setJobs() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jobs`);
      dispatch(master.actions.setJobs(response.data.data.data));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}

export function addJobs(title: string) {
  return async () => {
    try {
      dispatch(master.actions.addJobs(title));
      await Axios.post(`${BE_URL}/jobs`, { title });
    } catch (error: any) {
      dispatch(master.actions.hasError(error));
    }
  };
}

export function setJobTags() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jobtags`);
      dispatch(master.actions.setJobTags(response.data.data.data));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}

export function addJobTags(title: string) {
  return async () => {
    try {
      dispatch(master.actions.addJobTags(title));
      await Axios.post(`${BE_URL}/jobtags`, { title });
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}
export function setEssentialSkills() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/essentialtags`);
      dispatch(master.actions.setEssentialSkills(response.data.data.data));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}

export function addEssentialSkills(title: string) {
  return async () => {
    try {
      dispatch(master.actions.addEssentialSkills(title));
      await Axios.post(`${BE_URL}/essentialtags`, { title });
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}
export function setCapacities() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/capacities`);
      dispatch(master.actions.setCapacities(response.data.data.data));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}

export function addCapacities(title: string) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/capacities`, { title });
      dispatch(master.actions.addCapacities(response.data.data.title));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}
export function setCulturalPeferences() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/culturaltags`);
      dispatch(master.actions.setCulturalPeferences(response.data.data.data));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}

export function addCulturalPeferences(title: string) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/culturaltags`, { title });
      dispatch(master.actions.addCulturalPeferences(response.data.data.title));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}
export function setDesirableSkills() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/desirabletags`);
      dispatch(master.actions.setDesirableSkills(response.data.data.data));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}

export function addDesirableSkills(title: string) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/desirabletags`, { title });
      dispatch(master.actions.addDesirableSkills(response.data.data.title));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}

export function setRoles() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/jobs`);
      dispatch(master.actions.setRoles(response.data.data.data));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}

export function setDegrees() {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/degrees`);
      dispatch(master.actions.setDegrees(response.data.data.data));
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}

export function addDegree(title: string) {
  return async () => {
    try {
      dispatch(master.actions.addDegrees(title));
      await Axios.post(`${BE_URL}/degrees`, { title });
    } catch (error) {
      dispatch(master.actions.hasError(error));
    }
  };
}
