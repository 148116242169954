import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  createFilterOptions,
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  Skeleton,
  Stack,
  TextField,
  Typography
} from '@mui/material';

// third-party
import { useFormik } from 'formik';
import * as yup from 'yup';

// project-import
import useAuth from 'hooks/useAuth';
import trimFc from 'utils/trimFc';

import { addJobs, resetModal } from 'store/reducers/master';
import { resetStore } from 'store/reducers/jd-builder';
import { addProfile, editProfile } from 'store/reducers/jd-builder';
import { useDispatch, useSelector } from 'store';

// assets
import { ArrowDown2 } from 'iconsax-react';

// types
import { JDProfileProps } from 'types/jd-builder';

const filter = createFilterOptions<string>();

const validationSchema = yup.object({
  experience: yup.string().trim().required('Experience level is required'),
  jobTitle: yup.string().trim().required('Job Title is required').max(50, 'Job Title must be at most 50 characters'),

  jobCompany: yup
    .string()
    .trim()
    .matches(/^[ A-Za-zäöüÄÖÜß0-9_'"/)(?&-.]*$/, `Only alphanumeric are allowed with special characters (_'"/)(?&-.) and letters (ÄäÖöÜüß)`)
    .max(50, 'Company Name must be at most 50 characters')
    .required('Company Name is required'),
  jobDescription: yup
    .string()
    .trim()
    .min(50, 'Job Description must be at least 50 characters')
    .max(1500, 'Job Description must be at most 500 characters')
    .required('Job Description is required'),
  companyDescription: yup
    .string()
    .trim()
    .min(5, 'The Company Description must contain at least a few words. Please expand your text.')
    .max(1500, 'The Company Description must be 1500 characters or less. Please shorten your text.')
});

function compare(a: any, b: any) {
  return JSON.stringify(a) === JSON.stringify(b);
}

function getLabel(option: string) {
  switch (option) {
    case 'senior':
      return 'Senior (>5 years experience)';
    case 'middle':
      return 'Intermediate (2-5 years experience)';
    case 'junior':
      return 'Junior (<2 years experience)';
    default:
      return '';
  }
}

// ==============================|| JD BUILDER - USER PROFILE ||============================== //

interface Props {
  nextHandler: () => void;
}

const Step1 = ({ nextHandler }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [save, setSave] = useState<boolean>(false);

  const { user } = useAuth();
  const { jobs, experienceLevels } = useSelector((state) => state.master);
  const { profile, id } = useSelector((state) => state.jd);

  const formik = useFormik({
    initialValues: {
      jobTitle: profile.jobTitle,
      // jobCompany: profile.jobCompany ? profile.jobCompany : user?.company?.companyName!,
      jobCompany: user?.company?.companyName ? user?.company?.companyName : 'NO_COMPANY',
      jobDescription: profile.jobDescription,
      companyDescription: profile.companyDescription,
      experience: profile.experience
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const castValues = validationSchema.cast(values);
      const edited = compare(values, formik.initialValues);

      const formData: JDProfileProps = {
        jobTitle: castValues.jobTitle!,
        jobCompany: castValues.jobCompany!,
        jobDescription: castValues.jobDescription,
        companyDescription: castValues.companyDescription,
        experience: castValues.experience
      };

      let success = false;
      if (!edited) {
        if (id.length > 0) {
          success = await dispatch(editProfile({ profile: formData, id }));
        } else {
          success = await dispatch(addProfile(formData));
        }
      } else {
        success = true;
      }
      setSubmitting(false);
      if (!save && success) {
        nextHandler();
      }

      if (save && success) {
        dispatch(resetStore());
        dispatch(resetModal());
      }
    }
  });

  const handleAddJob = (jobName: string) => {
    dispatch(addJobs(jobName));
  };

  // const capitalize = (s: string) => s && s[0].toUpperCase() + s.slice(1);

  return (
    <form onSubmit={formik.handleSubmit} id="validation-forms">
      <Grid container rowSpacing={{ xs: 2.5, sm: 4 }} columnSpacing={3}>
        <Grid item xs={12} sm={6}>
          <InputLabel>Current Job Title *</InputLabel>
          <Autocomplete
            value={formik.values.jobTitle}
            disableClearable
            onChange={(event, newValue) => {
              const jobExist = jobs.includes(newValue);
              if (!jobExist) {
                const matchData = newValue.match(/"((?:\\.|[^"\\])*)"/);
                formik.setFieldValue('jobTitle', matchData && matchData[1]);
                if (matchData && matchData[1]) handleAddJob(matchData[1]);
              } else {
                formik.setFieldValue('jobTitle', newValue);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              const { inputValue } = params;
              const isExisting = options.some((option) => inputValue === option);
              if (inputValue !== '' && !isExisting) {
                filtered.push(`Add "${inputValue}"`);
              }
              return filtered;
            }}
            selectOnFocus
            clearOnBlur
            autoHighlight
            handleHomeEndKeys
            id="free-solo-with-text-demo"
            options={jobs}
            getOptionLabel={(option) => {
              let value = option;
              const jobExist = jobs.includes(option);
              if (!jobExist) {
                const matchData = option.match(/"((?:\\.|[^"\\])*)"/);
                if (matchData && matchData[1]) value = matchData && matchData[1];
              }
              return value;
            }}
            renderOption={(props, option) => {
              return (
                <Box component="li" {...props}>
                  {option}
                </Box>
              );
            }}
            freeSolo
            renderInput={(params) => (
              <TextField
                {...params}
                name="jobTitle"
                error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
                helperText={formik.touched.jobTitle && formik.errors.jobTitle && formik.errors.jobTitle}
                placeholder="Select or type in current job title"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDown2 size={16} color={theme.palette.secondary.main} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <InputLabel>Experience Level *</InputLabel>
          <Autocomplete
            id="experience"
            value={formik.values.experience}
            onChange={(event: any, newValue: string | null) => {
              formik.setFieldValue('experience', newValue);
            }}
            options={experienceLevels}
            disableClearable
            freeSolo
            autoHighlight
            clearOnBlur
            getOptionLabel={(option) => getLabel(option)}
            renderOption={(props, option) => (
              <Box component="li" {...props} sx={{ textTransform: 'capitalize' }}>
                {getLabel(option)}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label=""
                name="experience"
                placeholder="Select Experience Level"
                error={formik.touched.experience && Boolean(formik.errors.experience)}
                helperText={formik.touched.experience && formik.errors.experience}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDown2 size={16} color={theme.palette.secondary.main} />
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <InputLabel>Company Name</InputLabel>
          <TextField
            id="jobCompany"
            name="jobCompany"
            placeholder="Job Company"
            value={formik.values.jobCompany}
            onChange={trimFc(formik)}
            onBlur={formik.handleBlur}
            error={formik.touched.jobCompany && Boolean(formik.errors.jobCompany)}
            helperText={formik.touched.jobCompany && formik.errors.jobCompany}
            fullWidth
          />
        </Grid> */}
        <Grid item xs={12}>
          <InputLabel>Job Description *</InputLabel>
          <TextField
            id="jobDescription"
            name="jobDescription"
            multiline
            rows={4}
            placeholder="Enter your job description"
            value={formik.values.jobDescription}
            onChange={trimFc(formik)}
            onBlur={formik.handleBlur}
            error={formik.touched.jobDescription && Boolean(formik.errors.jobDescription)}
            inputProps={{ maxLength: 1500 }}
            helperText={
              <Stack
                component="span"
                direction="row"
                alignItems="center"
                justifyContent={
                  (formik.touched.jobDescription && formik.errors.jobDescription) || formik.values.jobDescription?.length === 1500
                    ? 'space-between'
                    : 'flex-end'
                }
              >
                {formik.touched.jobDescription && formik.errors.jobDescription && (
                  <Typography component="span" variant="caption1" color="error.main" id="standard-job">
                    {formik.errors.jobDescription}
                  </Typography>
                )}
                {formik.touched.jobDescription && formik.values.jobDescription?.length === 1500 && (
                  <Typography component="span" variant="caption1" color="warning.main" id="standard-job-max">
                    Job Description must be at most 1500 characters.
                  </Typography>
                )}
                <Typography component="span" align="right" color="secondary.400">
                  {formik.values.jobDescription?.length} / 1500
                </Typography>
              </Stack>
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            Company Description
            <Typography component="span" variant="body2" color="secondary.400">
              {' '}
              (Optional)
            </Typography>
          </InputLabel>
          <TextField
            id="companyDescription"
            name="companyDescription"
            multiline
            rows={5}
            placeholder="Enter your company description"
            value={formik.values.companyDescription}
            onChange={trimFc(formik)}
            onBlur={formik.handleBlur}
            error={formik.touched.companyDescription && Boolean(formik.errors.companyDescription)}
            inputProps={{ maxLength: 1500 }}
            helperText={
              <Stack
                component="span"
                direction="row"
                alignItems="center"
                justifyContent={
                  (formik.touched.companyDescription && formik.errors.companyDescription) ||
                  formik.values.companyDescription?.length === 1500
                    ? 'space-between'
                    : 'flex-end'
                }
              >
                {formik.touched.companyDescription && formik.errors.companyDescription && (
                  <Typography component="span" variant="caption1" color="error.main" id="standard-company">
                    {formik.errors.companyDescription}
                  </Typography>
                )}
                {formik.touched.companyDescription && formik.values.companyDescription?.length === 1500 && (
                  <Typography component="span" variant="caption1" color="warning.main" id="standard-company-max">
                    Job Description must be at most 1500 characters.
                  </Typography>
                )}
                <Typography component="span" align="right" color="secondary.400">
                  {formik.values.companyDescription?.length} / 1500
                </Typography>
              </Stack>
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <Stack direction={{ xs: 'column-reverse', sm: 'row' }} spacing={3} alignItems="center" justifyContent="space-between">
            <Box sx={{ display: 'flex' }}>
              {[1, 2, 3, 4].map((item: number, index: number) => (
                <Skeleton
                  key={index}
                  variant="circular"
                  width={item === 1 ? '80px' : '8px'}
                  height="8px"
                  animation={false}
                  sx={{
                    mr: 1,
                    bgcolor: item === 1 ? 'primary.main' : 'secondary.light',
                    borderRadius: item === 1 ? '6px' : '50%'
                  }}
                />
              ))}
            </Box>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="outlined"
                sx={{ mr: 2 }}
                size="small"
                disabled={formik.isSubmitting}
                onClick={() => {
                  setSave(true);
                  formik.handleSubmit();
                }}
              >
                Save Changes & Close
              </Button>
              <Button variant="contained" size="small" disabled={formik.isSubmitting} type="submit" onClick={() => setSave(false)}>
                Next
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </form>
  );
};

export default Step1;
