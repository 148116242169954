// action - state management
import { REGISTER, LOGIN, LOGOUT, INIT_STATE, SOCIAL, UPDATE_USER } from './actions';

// types
import { AuthProps, AuthActionProps } from 'types/auth';
import { persister } from 'store';

// initial state
export const initialState: AuthProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
  token: null
};

// ==============================|| AUTH REDUCER ||============================== //

const auth = (state = initialState, action: AuthActionProps) => {
  switch (action.type) {
    case UPDATE_USER: {
      const { user } = action.payload!;
      return {
        ...state,
        user
      };
    }
    case REGISTER: {
      const { user, token } = action.payload!;
      return {
        ...state,
        user,
        isInitialized: true,
        token
      };
    }
    case SOCIAL: {
      return {
        ...state,
        socialLogin: true
      };
    }
    case LOGIN: {
      const { user, token } = action.payload!;
      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user,
        token
      };
    }
    case LOGOUT: {
      persister.purge();
      persister.flush();
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
        socialLogin: false
      };
    }
    case INIT_STATE: {
      return {
        ...state,
        isInitialized: true
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default auth;
