import { useRef, useState, ReactElement } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Badge, Box, Button, ClickAwayListener, Divider, Grid, Paper, Popper, Stack, Typography, useMediaQuery } from '@mui/material';

// types
import { NotificationsAction, NotificationsProps } from 'types/notifications';

// project import
import { useSelector } from 'store';
import { URL_PREFIX } from 'config';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import MainCard from 'components/MainCard';
import SimpleBar from 'components/third-party/SimpleBar';
import Transitions from 'components/@extended/Transitions';

// assets
import { Notification } from 'iconsax-react';

// ==============================|| HEADER CONTENT - NOTIFICATION POPUP||============================== //

const NotificationPage = () => {
  const theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
  const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));

  const anchorRef = useRef<any>(null);
  const [open, setOpen] = useState(false);

  const notificationState = useSelector((state) => state.notifications);
  const { notifications } = notificationState;

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  let notificationResult: ReactElement | ReactElement[] = <></>;
  if (notifications && notifications.length > 0) {
    notificationResult = notifications.map((notification: NotificationsProps, index) => (
      <Grid
        key={index}
        item
        xs={12}
        sx={{
          px: 3,
          py: 2,
          '&:nth-of-type(even)': {
            bgcolor: 'secondary.lighter'
          }
        }}
      >
        <Stack direction="row" spacing={1.5} alignItems="flex-start">
          {notification.badge ? (
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={<Avatar alt={notification.title} src={notification.avatar} size="badge" />}
              sx={{ height: 40, width: 40 }}
            >
              <Avatar
                variant={notification.logo ? 'rounded' : 'circular'}
                alt={notification.title}
                src={notification.logo ? notification.logo : notification.avatar}
              />
            </Badge>
          ) : (
            <Avatar
              variant={notification.logo ? 'rounded' : 'circular'}
              alt={notification.title}
              src={notification.logo ? notification.logo : notification.avatar}
            />
          )}
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Typography variant="body2">{notification.title}</Typography>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography sx={{ color: 'secondary.dark' }}>{new Date(notification.createdAt).toLocaleString()}</Typography>
              <Stack direction="row" spacing={2}>
                {notification?.actions?.map((action: NotificationsAction, i: number) => (
                  <Button {...action.button} size="small" key={i}>
                    {action.title}
                  </Button>
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Grid>
    ));
  }

  const offsetPopup = matchesMD ? -140 : 0;

  return (
    <Box sx={{ flexShrink: 0, ml: { xs: 1.25, sm: 2.5 } }}>
      <IconButton
        color="primary"
        variant="outlined"
        shape="rounded"
        size="large"
        sx={{ borderColor: open ? 'inherit' : theme.palette.divider }}
        aria-label="open notification"
        ref={anchorRef}
        aria-controls={open ? 'notification-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Notification size={20} />
      </IconButton>
      <Popper
        placement="bottom"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [matchesSM ? -64 : offsetPopup, 8]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            <Paper
              sx={{
                boxShadow: theme.customShadows.xLarge,
                width: '100%',
                minWidth: 510,
                maxWidth: 510,
                [theme.breakpoints.down('sm')]: {
                  width: '100%',
                  minWidth: 340,
                  maxWidth: 340
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false} sx={{ height: 'auto' }}>
                  <SimpleBar
                    sx={{
                      maxHeight: `calc(100vh - 160px)`,
                      '& .simplebar-content': {
                        display: 'flex',
                        flexDirection: 'column'
                      }
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} sx={{ p: 3 }}>
                        <Stack
                          direction={{ xs: 'column', sm: 'row' }}
                          alignItems={{ xs: 'flex-start', sm: 'center' }}
                          justifyContent="space-between"
                        >
                          <Stack>
                            <Typography variant="h6">Notifications</Typography>
                            <Typography color="secondary" sx={{ pt: 0.75 }}>
                              All my activity notifications
                            </Typography>
                          </Stack>
                          <Link to={`${URL_PREFIX}/notifications`} replace style={{ textDecoration: 'none' }}>
                            <Typography color="primary" variant="subtitle2" onClick={() => setOpen(false)}>
                              See all notifications
                            </Typography>
                          </Link>
                        </Stack>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      {notificationResult}
                    </Grid>
                  </SimpleBar>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default NotificationPage;
