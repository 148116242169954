import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Chip, Grid, Typography, TypographyProps } from '@mui/material';
import { ColorProps } from 'types/extended';

interface LinkProps {
  color?: ColorProps;
  labelMore?: string;
  labelLess?: string;
}

interface Props extends TypographyProps {
  chips: string[];
  isChipMore?: boolean;
  limit?: number;
  color?: ColorProps;
  link?: LinkProps;
}

const initialLink: LinkProps = {
  color: 'primary',
  labelMore: 'See more',
  labelLess: 'See less'
};

const ChipMore = ({ chips, isChipMore = false, limit = 3, link = initialLink, ...others }: Props) => {
  const theme = useTheme();
  const [more, setMore] = useState(isChipMore);

  if (chips.length <= limit) {
    return (
      <Grid container rowGap={1} columnGap={1}>
        {chips.map((chip, i) => (
          <Grid item key={i}>
            <Chip
              label={
                <Typography variant="body2" sx={{ color: theme.palette.grey[700] }}>
                  {chip}
                </Typography>
              }
              sx={{
                height: 24,
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '12px',
                bgcolor: theme.palette.grey[0]
              }}
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  let subChildren = chips;
  if (!more && chips.length > limit) {
    subChildren = subChildren.slice(0, limit);
  }

  return (
    <>
      <Grid container rowGap={1} columnGap={1}>
        {subChildren.map((chip, i) => (
          <Grid item key={i}>
            <Chip
              label={
                <Typography variant="body2" sx={{ color: theme.palette.grey[700] }}>
                  {chip}
                </Typography>
              }
              sx={{
                height: 24,
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '12px',
                bgcolor: theme.palette.grey[0]
              }}
            />
          </Grid>
        ))}
        {more && (
          <Grid item>
            <Chip
              label={
                <Typography variant="body2" sx={{ color: theme.palette.grey[700] }}>
                  -{chips.length - limit} less
                </Typography>
              }
              sx={{
                height: 24,
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '12px',
                bgcolor: theme.palette.grey[0]
              }}
              onClick={() => setMore(false)}
            />
          </Grid>
        )}
        {!more && (
          <Grid item>
            <Chip
              label={
                <Typography variant="body2" sx={{ color: theme.palette.grey[700] }}>
                  +{chips.length - limit} more
                </Typography>
              }
              sx={{
                height: 24,
                border: `1px solid ${theme.palette.grey[300]}`,
                borderRadius: '12px',
                bgcolor: theme.palette.grey[0]
              }}
              onClick={() => setMore(true)}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ChipMore;
