import { useMemo } from 'react';

// material-ui
import { Theme } from '@mui/material/styles';
import { Box, useMediaQuery } from '@mui/material';

// project import
import useConfig from 'hooks/useConfig';
import Search from './Search';
// import Message from './Message';
import Profile from './Profile';
// import Informative from './Informative';
import Support from './Support';
import Notification from './Notification';
import MobileSection from './MobileSection';
import CVJDBuilder from './CVJDBuilder';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const { i18n } = useConfig();

  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const support = useMemo(() => <Support />, [i18n]);

  const cvJdBuilder = useMemo(() => <CVJDBuilder />, []);
  // const informative = useMemo(() => <Informative />, []);

  return (
    <>
      {!matchesXs && <Search />}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
      {cvJdBuilder}
      {/* {informative}
      {<Message />} */}

      <Notification />
      {support}
      {!matchesXs && <Profile />}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
