// assets
import { Save2, RulerPen } from 'iconsax-react';

// type
import { NavItemType } from 'types/menu';

// icons
const icons = { Save2, RulerPen };

// ==============================|| MENU ITEMS - OTHERS ||============================== //

const other: NavItemType = {
  id: 'other',
  title: 'Other',
  type: 'group',
  children: [
    {
      id: 'my-tools',
      title: 'My Tools',
      type: 'item',
      url: 'tools',
      icon: icons.RulerPen
    },
    {
      id: 'my-favourites',
      title: 'My Favourites',
      type: 'item',
      url: 'favourites',
      icon: icons.Save2
    }
  ]
};

export default other;
