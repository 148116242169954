import { useCallback, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Alert,
  Badge,
  Box,
  Button,
  Chip,
  Collapse,
  Divider,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton,
  Stack,
  Typography
} from '@mui/material';

// third-party
import { format } from 'date-fns';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';

// project import
import WarningPopup from './WarningPopup';
import MainCard from 'components/MainCard';
import Avatar from 'components/@extended/Avatar';
import IconButton from 'components/@extended/IconButton';
import ReadMore from 'components/@extended/ReamMore';
import ChipMore from 'components/@extended/ChipMore';

import debounce from 'utils/debounce';
import parseDays from 'utils/parseDays';
import useAuth from 'hooks/useAuth';

import { dispatch } from 'store';
import { setStore } from 'store/reducers/cv-builder';
import { openSnackbar } from 'store/reducers/snackbar';
import { setModal, setReload } from 'store/reducers/master';
import { acceptApplicationRequest, rejectApplication, removeEmployee, setFavouritesEmployee } from 'store/reducers/employees';

// assets
import { ArrowDown2, Award, Calendar, Global, Heart, ExportCurve, Link1, More, Tag, Profile, Element3 } from 'iconsax-react';

// types
import { EmployeesProps, EmployeeStatus, ReviewApplicantStatus } from 'types/employees';
import { ColorProps } from 'types/extended';
import { ModalType } from 'types/master';
import { UserRole } from 'types/user';
import parseMonths from 'utils/parseMonths';
import { EXP_END_DATE } from 'config';

interface Props {
  employeeData: EmployeesProps;
  isFindCard?: boolean;
  isExportCard?: boolean;
  isFavourites?: boolean;
  isCollapsed?: boolean;
  isApplication?: boolean;
  isMatch?: boolean;
  isProject?: boolean;
  isCardView?: boolean;
}

// ==============================|| EMPLOYEE CARD ||============================== //

function EmployeesCard({
  employeeData,
  isFindCard,
  isExportCard,
  isFavourites,
  isCollapsed,
  isApplication,
  isMatch,
  isProject,
  isCardView = false
}: Props) {
  const theme = useTheme();
  const { user } = useAuth();

  const {
    id,
    image,
    firstName,
    lastName,
    jobTitle,
    capacity,
    workLocation,
    biography,
    jobCompany,
    location,
    rate,
    jobTags,
    createdAt,
    status,
    favourite,
    matches,
    timeSlot,
    totalExperienceDays,
    experiences,
    daysAvailable,
    userId
  } = employeeData;

  let totalExperience;
  if (experiences && experiences.length > 0) {
    const present = experiences.filter((item) => format(new Date(item.endDate!), 'yyyy-MM') === format(new Date(EXP_END_DATE), 'yyyy-MM'));
    if (present && present.length > 0) {
      const a = moment(new Date(EXP_END_DATE));
      const b = moment(new Date());

      const splitDay = a.diff(b, 'days');
      totalExperience = Number(totalExperienceDays) - Number(splitDay);
    } else {
      totalExperience = totalExperienceDays;
    }
  } else {
    totalExperience = totalExperienceDays;
  }

  const isCreator = user?.id === userId || user?.role === UserRole.admin;
  const isAccess = isCreator || status === EmployeeStatus.CONTRACTED;

  const emploProfile = image;

  const favouriteCollapsed = isCollapsed === undefined ? false : isCollapsed;
  const [collapsed, setCollapsed] = useState<boolean>(isFavourites ? favouriteCollapsed : true);
  const [openRemove, setOpenRemove] = useState<boolean>(false);
  const [isFavourite, setIsFavourite] = useState<boolean>(favourite === undefined ? false : favourite);
  const [iconHover, setIconHover] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<Element | ((element: Element) => Element) | null | undefined>(null);
  const openMenu = Boolean(anchorEl);

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement> | undefined) => {
    if (!isFindCard) {
      setAnchorEl(event?.currentTarget);
    } else {
      setAnchorEl(null);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const removeEmployeeHandler = () => {
    setOpenRemove(true);
  };

  // remove employee hanlder
  const handleRemove = (id: string) => {
    dispatch(removeEmployee(id));
    dispatch(
      openSnackbar({
        open: true,
        message: isFindCard ? 'Candidate removed successfully' : 'Employee removed successfully',
        variant: 'alert',
        alert: {
          color: 'success'
        },
        close: false
      })
    );
    setAnchorEl(null);
    setOpenRemove(false);
  };

  const handleCancel = () => {
    setOpenRemove(false);
  };

  const editHandler = () => {
    dispatch(setStore(id)).then(() => {
      dispatch(setModal({ type: ModalType.cvBuild }));
    });
  };

  let statusLabel;
  let color: ColorProps;
  switch (status) {
    case EmployeeStatus.CONTRACTED:
      statusLabel = 'Contracted';
      color = 'success';
      break;
    case EmployeeStatus.APPLIED:
      statusLabel = 'Applied';
      color = 'warning';
      break;
    case EmployeeStatus.MATCH:
      statusLabel = 'Match';
      color = 'primary';
      break;
    case EmployeeStatus.PUBLISHED:
      statusLabel = 'Published';
      color = 'info';
      break;
    case EmployeeStatus.DRAFT:
    default:
      statusLabel = 'Draft';
      color = 'secondary';
      break;
  }

  let previewType = ModalType.cvPreview;
  if (isFindCard || isMatch) {
    previewType = ModalType.cvFindPreview;
  }
  if (isApplication) {
    previewType = ModalType.cvAppPreview;
  }

  const handleChange = (isFavourite: boolean) => {
    dispatch(setFavouritesEmployee(id, isFavourite)).then(() => dispatch(setReload(true)));
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(handleChange), []);

  let matchStateLabel: string = '';
  let matchColor: ColorProps = 'primary';

  let match = matches?.length === 1 ? matches[0] : false;

  if (status === EmployeeStatus.CONTRACTED && matches && matches?.length > 1) {
    match = matches?.filter((item) => item.status === ReviewApplicantStatus.PROJECT)[0];
  }

  if (isApplication && match) {
    switch (match.status) {
      case ReviewApplicantStatus.PROJECT:
        matchStateLabel = 'Contracted';
        matchColor = 'success';
        break;
      case ReviewApplicantStatus.CANCELLED:
        matchStateLabel = 'Cancelled';
        matchColor = 'error';
        break;
      case ReviewApplicantStatus.REJECTED:
        matchStateLabel = 'Rejected';
        matchColor = 'error';
        break;
      case ReviewApplicantStatus.ACCEPTED:
        matchStateLabel = 'Accepted';
        matchColor = 'success';
        break;
      case ReviewApplicantStatus.INVITED:
        matchStateLabel = 'Invited';
        matchColor = 'info';
        break;
      case ReviewApplicantStatus.REVIEW:
        matchStateLabel = 'Applied';
        matchColor = 'warning';
        break;
      case ReviewApplicantStatus.NEW:
        matchStateLabel = 'New';
        matchColor = 'primary';
        break;
      default:
        matchStateLabel = 'Available';
        matchColor = 'success';
        break;
    }
  }

  const projectTitle = isProject ? 'Track' : 'Preview';

  return (
    <MainCard border sx={{ height: 1, '.MuiCardContent-root': { height: 1, p: isCardView ? 2 : 3 } }}>
      <Grid container spacing={3} sx={{ height: 1 }}>
        <Grid item xs={12}>
          <Stack
            spacing={1}
            justifyContent="space-between"
            alignItems={{ xs: 'flex-start', sm: 'center' }}
            direction={{ xs: 'column', sm: 'row' }}
          >
            <List sx={{ width: 1, p: 0 }}>
              <ListItem disablePadding>
                <ListItemAvatar sx={{ mr: 1, minWidth: isCardView ? 40 : 56 }}>
                  <Avatar
                    sx={{ width: isCardView ? 32 : 48, height: isCardView ? 32 : 48 }}
                    {...(emploProfile && isAccess && { alt: firstName, src: emploProfile })}
                  >
                    <Profile size={34} variant="Bold" color="black" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      {isAccess ? (
                        <Typography variant={isCardView ? 'body3' : 'subtitle3'} flexWrap="wrap">
                          {firstName} {lastName}
                        </Typography>
                      ) : (
                        <Skeleton height={20} width={180} animation={false} sx={{ mb: 0.25 }} />
                      )}
                    </>
                  }
                  secondary={
                    <Typography component="div" variant={isCardView ? 'caption1' : 'body1'} color="secondary" noWrap>
                      {jobTitle}
                    </Typography>
                  }
                />
              </ListItem>
            </List>
            {!isExportCard && (
              <Stack direction="row" spacing={0.5} alignItems="center">
                {isApplication && match && <Chip label={matchStateLabel} sx={{ height: 24, mr: 1.5 }} color={matchColor} />}
                {(isFindCard || isFavourites || isCardView) && (
                  <IconButton
                    color="secondary"
                    onMouseEnter={() => setIconHover(!iconHover)}
                    onMouseLeave={() => setIconHover(!iconHover)}
                    onClick={() => {
                      optimizedFn(!isFavourite);
                      setIsFavourite(!isFavourite);
                    }}
                  >
                    <Heart
                      variant={isFavourite ? 'Bold' : 'Outline'}
                      size={20}
                      color={isFavourite || iconHover ? theme.palette.primary.main : theme.palette.text.primary}
                    />
                  </IconButton>
                )}
                <IconButton color="secondary" onClick={() => dispatch(setModal({ type: ModalType.cvShare, id }))}>
                  <ExportCurve color={theme.palette.text.primary} />
                </IconButton>
                {!isFindCard && !isFavourites && (
                  <IconButton color="secondary" onClick={handleClickOpen}>
                    <More color={theme.palette.text.primary} style={{ transform: 'rotate(90deg)' }} />
                  </IconButton>
                )}
                {isFavourites && (
                  <IconButton color="secondary" onClick={() => setCollapsed(!collapsed)}>
                    <ArrowDown2 color={theme.palette.text.primary} {...(collapsed && { style: { transform: 'rotate(-180deg)' } })} />
                  </IconButton>
                )}
              </Stack>
            )}

            {/* menu for edit/remove CV */}
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button'
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleMenuClose}
              TransitionComponent={Fade}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
            >
              <MenuItem onClick={editHandler}>Edit</MenuItem>
              {(status === EmployeeStatus.DRAFT || status === EmployeeStatus.PUBLISHED) && (
                <MenuItem onClick={removeEmployeeHandler}>Remove</MenuItem>
              )}
            </Menu>

            {/* warning popup on removing CV */}
            <WarningPopup
              title={
                <Typography variant="title2" sx={{ pt: 1.5 }}>
                  Are you sure to remove?
                </Typography>
              }
              message={
                <Typography color="textSecondary" sx={{ pt: 1.5 }}>
                  {isFindCard ? 'Candidate will be removed' : 'Employee will be removed'}
                </Typography>
              }
              open={openRemove}
              silentClose={handleCancel}
              saveButton={
                <Button fullWidth variant="contained" onClick={() => handleRemove(id)}>
                  Remove
                </Button>
              }
              closeButton={
                <Button fullWidth variant="outlined" color="secondary" onClick={handleCancel}>
                  Cancel
                </Button>
              }
            />
          </Stack>
        </Grid>
        {!isCardView && (
          <Collapse
            in={collapsed}
            sx={{ width: 1, pl: 3 }}
            {...((collapsed || isCollapsed === undefined) &&
              !isExportCard && {
                sx: {
                  width: 1,
                  pl: 3,
                  height: { xs: 'auto', md: isApplication || isMatch || isProject ? 'calc(100% - 200px)' : 'calc(100% - 140px)' }
                }
              })}
          >
            <Grid item xs={12} sx={{ mt: 0 }}>
              <Grid container spacing={3} sx={{ mt: 0 }}>
                <Grid item xs={12} sx={{ pt: '0 !important', mt: 3 }}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Stack direction="row" spacing={1.25}>
                    {!isFindCard && (
                      <Chip
                        label={statusLabel}
                        sx={{
                          height: 24,
                          ...(status === EmployeeStatus.DRAFT && {
                            bgcolor: 'secondary.darker'
                          })
                        }}
                        color={color}
                      />
                    )}
                    <Stack direction="row" spacing={1.25} alignItems="center">
                      {!isFindCard && <Badge variant="dot" sx={{ '& .MuiBadge-badge': { bgcolor: theme.palette.grey[300] } }} />}
                      <Typography sx={{ color: theme.palette.grey[700] }}>{capacity}</Typography>
                      <Badge variant="dot" sx={{ '& .MuiBadge-badge': { bgcolor: theme.palette.grey[300] } }} />
                      <Typography sx={{ color: theme.palette.grey[700] }}>{workLocation}</Typography>
                    </Stack>
                  </Stack>
                </Grid>
                {biography && (
                  <Grid item xs={12}>
                    <ReadMore>{biography}</ReadMore>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                      <List sx={{ p: 0, overflow: 'hidden', '& .MuiListItem-root': { px: 0, py: 0.5 } }}>
                        <ListItem>
                          <ListItemIcon>
                            <Global size="20" style={{ color: theme.palette.grey[700] }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <>
                                {isAccess ? (
                                  <Typography sx={{ color: theme.palette.grey[700] }}>
                                    {jobCompany ? `${jobCompany} - ${location}` : '-'}
                                  </Typography>
                                ) : (
                                  <Stack direction="row" alignItems="center" spacing={0.25}>
                                    <Skeleton width={65} height={20} animation={false} />
                                    <Typography sx={{ color: theme.palette.grey[700] }}> - {location}</Typography>
                                  </Stack>
                                )}
                              </>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Calendar size="20" style={{ color: theme.palette.grey[700] }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography sx={{ color: theme.palette.grey[700] }}>
                                {daysAvailable ? parseMonths(Number(daysAvailable)) : '-'}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <List sx={{ p: 0, overflow: 'hidden', '& .MuiListItem-root': { px: 0, py: 0.5 } }}>
                        <ListItem>
                          <ListItemIcon>
                            <Award size="20" style={{ color: theme.palette.grey[700] }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography sx={{ color: theme.palette.grey[700] }}>
                                {totalExperience ? parseDays(Number(totalExperience)) : '-'}
                              </Typography>
                            }
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemIcon>
                            <Tag size="20" style={{ color: theme.palette.grey[700] }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              // workSlots: ['Hourly', 'Daily', 'Monthly'],
                              <Typography sx={{ color: theme.palette.grey[700] }}>
                                {rate && (
                                  <>
                                    <CurrencyFormat
                                      fixedDecimalScale
                                      value={
                                        timeSlot === 'Hourly'
                                          ? (Math.round(10 * (parseFloat(rate) * 7.5)) / 10) % 1 !== 0
                                            ? (Math.round(10 * (parseFloat(rate) * 7.5)) / 10).toFixed(2)
                                            : Math.round(10 * (parseFloat(rate) * 7.5)) / 10
                                          : timeSlot === 'Daily'
                                          ? (Math.round(10 * Number(rate)) / 10) % 1 !== 0
                                            ? (Math.round(10 * Number(rate)) / 10).toFixed(2)
                                            : Math.round(10 * Number(rate)) / 10
                                          : timeSlot === 'Monthly'
                                          ? (Math.round(10 * (parseFloat(rate) / 21.5)) / 10) % 1 !== 0
                                            ? (Math.round(10 * (parseFloat(rate) / 21.5)) / 10).toFixed(2)
                                            : Math.round(10 * (parseFloat(rate) / 21.5)) / 10
                                          : ''
                                      }
                                      displayType="text"
                                      thousandSeparator
                                      prefix="£"
                                    />{' '}
                                    / day
                                  </>
                                )}
                              </Typography>
                            }
                          />
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
                {jobTags && (
                  <Grid item xs={12}>
                    <ChipMore chips={jobTags} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Collapse>
        )}
        {!isExportCard && (
          <Collapse in={collapsed} sx={{ width: 1, pl: 3 }}>
            <Grid item xs={12}>
              <Grid container spacing={3} sx={{ mt: 0 }}>
                {!isCardView && (
                  <Grid item xs={12} sx={{ pt: '0 !important', mt: 3 }}>
                    <Divider />
                  </Grid>
                )}

                {(isApplication || isMatch || isProject) && match && (
                  <Grid item xs={12}>
                    <MainCard
                      border
                      content={false}
                      sx={{
                        borderRadius: 2,
                        cursor: 'pointer',
                        ...{ ...(isCardView && status === EmployeeStatus.APPLIED && { boxShadow: theme.customShadows.xLarge }) }
                      }}
                      onClick={() => {
                        // @ts-ignore
                        dispatch(setModal({ type: ModalType.jdPreview, id: match.jd.id }));
                      }}
                    >
                      <Box sx={{ p: 1 }}>
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
                          <Stack direction="row" alignItems="center" spacing={1}>
                            {/* {appliedTo?.logo && <Avatar size="xs" color="secondary" variant="rounded" src={appliedTo.logo} />}
                            {!appliedTo?.logo && ( */}
                            <Avatar size="xs" color="secondary" variant="rounded">
                              <Element3 size={18} variant="Bold" color={theme.palette.text.primary} />
                            </Avatar>
                            {/* )} */}
                            <Typography variant="caption2" color="secondary.dark">
                              {match.jd.jobTitle}
                            </Typography>
                          </Stack>
                          <Link1 size={18} />
                        </Stack>
                        {isCardView && match && match.status === ReviewApplicantStatus.INVITED && (
                          <Box sx={{ mt: 1.5 }}>
                            <Stack spacing={1}>
                              <Alert color="success" icon={false} sx={{ color: 'success.main', justifyContent: 'center' }}>
                                Congratilations! You have matched
                              </Alert>
                              <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                                <Button
                                  variant="outlined"
                                  fullWidth
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // @ts-ignore
                                    dispatch(rejectApplication(match.id)).then(() => {
                                      dispatch(
                                        openSnackbar({
                                          open: true,
                                          message: 'Application rejected successfully',
                                          variant: 'alert',
                                          alert: {
                                            color: 'success'
                                          },
                                          close: false
                                        })
                                      );
                                    });
                                  }}
                                >
                                  Decline Request
                                </Button>
                                <Button
                                  variant="contained"
                                  fullWidth
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // @ts-ignore
                                    dispatch(acceptApplicationRequest(match.id)).then(() => {
                                      dispatch(
                                        openSnackbar({
                                          open: true,
                                          message: 'Application request accepted successfully',
                                          variant: 'alert',
                                          alert: {
                                            color: 'success'
                                          },
                                          close: false
                                        })
                                      );
                                      dispatch(setReload(true));
                                    });
                                  }}
                                >
                                  Accept Request
                                </Button>
                              </Stack>
                            </Stack>
                          </Box>
                        )}
                      </Box>
                    </MainCard>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography color="textSecondary">{moment(createdAt).fromNow()}</Typography>
                    {!isFavourites && (
                      <Button
                        variant={isProject ? 'contained' : 'dashed'}
                        size="small"
                        onClick={() => {
                          dispatch(
                            setModal({
                              type: isMatch || isProject ? ModalType.cvMatchPreview : previewType,
                              id,
                              ...((isApplication || isMatch || isProject) && { match })
                            })
                          );
                        }}
                      >
                        {isMatch ? 'Match Card' : projectTitle}
                      </Button>
                    )}
                    {isFavourites && (
                      <Button
                        variant="dashed"
                        size="small"
                        onClick={() => {
                          setCollapsed(false);
                        }}
                      >
                        See less
                      </Button>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Collapse>
        )}
      </Grid>
    </MainCard>
  );
}

export default EmployeesCard;
