// material-ui
import { alpha, Theme } from '@mui/material/styles';

// types
import { CustomShadowProps } from 'types/theme';

// ==============================|| DEFAULT THEME - CUSTOM SHADOWS  ||============================== //

const CustomShadows = (theme: Theme): CustomShadowProps => ({
  xSmall: `0px 1px 2px rgba(16, 24, 40, 0.05)`,
  small: `0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px -1px rgba(16, 24, 40, 0.1)`,
  medium: `0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1)`,
  large: `0px 10px 15px -3px rgba(16, 24, 40, 0.1), 0px 4px 6px -4px rgba(16, 24, 40, 0.1)`,
  xLarge: `0px 20px 25px -5px rgba(16, 24, 40, 0.1), 0px 8px 10px -6px rgba(16, 24, 40, 0.1)`,
  xxLarge: `0px 25px 50px -12px rgba(16, 24, 40, 0.25)`,
  primary: `0 0 0 2px ${alpha(theme.palette.primary.main, 0.2)}`,
  secondary: `0 0 0 2px ${alpha(theme.palette.secondary.main, 0.2)}`,
  error: `0 0 0 2px ${alpha(theme.palette.error.main, 0.2)}`,
  warning: `0 0 0 2px ${alpha(theme.palette.warning.main, 0.2)}`,
  info: `0 0 0 2px ${alpha(theme.palette.info.main, 0.2)}`,
  success: `0 0 0 2px ${alpha(theme.palette.success.main, 0.2)}`,
  grey: `0 0 0 2px ${alpha(theme.palette.grey[500], 0.2)}`
});

export default CustomShadows;
