import { forwardRef, Ref } from 'react';

// material-ui
import { Box, Button, Grid, Modal, Stack, Typography } from '@mui/material';

// project import
import AnimateButton from 'components/@extended/AnimateButton';
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';

// assets
import { CloseCircle } from 'iconsax-react';

interface UploadPopupProps {
  failed?: boolean;
  open: boolean;
  title?: string;
  message?: string;
  handleClose?: () => void;
}

// ==============================|| MODAL - UPLOAD CV/JD POPUP ||============================== //

const UploadSuccessPopup = forwardRef(({ title, message, open, failed, handleClose }: UploadPopupProps, ref: Ref<HTMLDivElement>) => (
  <Modal
    open={open}
    aria-labelledby="modal-upload-title"
    aria-describedby="modal-upload-description"
    ref={ref}
    sx={{
      '& .MuiPaper-root:focus': {
        outline: 'none'
      }
    }}
  >
    <MainCard modal sx={{ width: `calc(100% - 48px)`, maxWidth: 384 }}>
      <Stack spacing={1} alignItems="center" justifyContent="center" sx={{ width: 1 }}>
        {failed ? (
          <Box sx={{ bgcolor: 'error.main', color: 'error.lighter', borderRadius: '50%', width: '48px', height: '48px' }}>
            <CloseCircle size={60} variant="Bold" style={{ margin: '-6px' }} />
          </Box>
        ) : (
          <Box sx={{ borderRadius: '50%', width: '48px', height: '48px' }}>
            <Avatar alt="user" sx={{ width: 48, height: 48, color: 'success.dark', bgcolor: 'success.lighter' }} />
          </Box>
        )}

        {title && (
          <Typography variant="title2" sx={{ pt: 1.5 }}>
            {title}
          </Typography>
        )}

        {message && (
          <Typography color="textSecondary" sx={{ pt: 1.5 }} align="center">
            {message}
          </Typography>
        )}

        <Grid container spacing={1.5} sx={{ pt: 2 }}>
          <Grid item xs={12}>
            <AnimateButton>
              {failed ? (
                <Button fullWidth variant="contained" sx={{ textTransform: 'none' }} onClick={handleClose}>
                  Try again
                </Button>
              ) : (
                <Button fullWidth variant="contained" sx={{ textTransform: 'none' }} onClick={handleClose}>
                  Okay
                </Button>
              )}
            </AnimateButton>
          </Grid>
        </Grid>
      </Stack>
    </MainCard>
  </Modal>
));

export default UploadSuccessPopup;
