import { Middleware } from 'redux';
import { io, Socket } from 'socket.io-client';
import { notificationsActions } from '../reducers/notifications';
import { socketsActions } from '../reducers/sockets';
import { BASE_BE_URL } from 'config';
// types
import { NotificationEvent, NotificationMessage } from 'types/notifications';

const notificationsMiddleware: Middleware = (store) => {
  let socket: Socket;

  return (next) => (action) => {
    if (socketsActions.startConnecting.match(action)) {
      socket = io(BASE_BE_URL, {
        withCredentials: true
      });

      socket.on('connect', () => {
        store.dispatch(socketsActions.connectionEstablished());
        socket.emit(NotificationEvent.RequestAllNotifications);
      });

      socket.on(NotificationEvent.ReceiveAllNotifications, ({ notifications }: { notifications: NotificationMessage[] }) => {
        store.dispatch(notificationsActions.getNotificationsSuccess(notifications));
      });

      socket.on(NotificationEvent.ReceiveNotification, (notification: NotificationMessage) => {
        store.dispatch(notificationsActions.getNotificationSuccess({ notification }));
      });
    }

    next(action);
  };
};

export default notificationsMiddleware;
