import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { Box, CircularProgress, Modal } from '@mui/material';

// project imports
import MainCard from 'components/MainCard';
import SimpleBar from 'components/third-party/SimpleBar';
import { setStore, resetStore } from 'store/reducers/jd-builder';
import { resetModal } from 'store/reducers/master';
import { dispatch, useSelector } from 'store';

// aseets
import { getJob, resetJob } from 'store/reducers/jobs';
import PreviewPopup from 'sections/job-board/PreviewPopup';

const JDPreviewPopup = () => {
  const navigate = useNavigate();

  const { modal } = useSelector((state) => state.master);
  const id = modal && modal?.id;
  const matches = modal && modal?.matches;
  const match = modal && modal?.match;

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (id) {
      dispatch(getJob(id)).then(() => dispatch(setStore(id)).then(() => setLoading(true)));
    }
    // eslint-disable-next-line
  }, []);

  const jd = useSelector((state) => state.jd);
  const { job } = useSelector((state) => state.jobs);

  const closeModal = () => {
    dispatch(resetStore());
    if (modal && modal?.closeNavigate) {
      navigate(modal.closeNavigate);
    }
    dispatch(resetModal());
    dispatch(resetJob());
  };

  return (
    <Modal
      open={true}
      onClose={closeModal}
      aria-labelledby="modal-jd-title"
      aria-describedby="modal-jd-description"
      sx={{
        '& .MuiPaper-root:focus': {
          outline: 'none'
        }
      }}
    >
      <MainCard
        sx={{ width: `calc(100% - 48px)`, minWidth: 340, maxWidth: 880, height: 'auto', maxHeight: 'calc(100vh - 48px)' }}
        modal
        content={false}
      >
        <SimpleBar
          sx={{
            maxHeight: `calc(100vh - 48px)`,
            '& .simplebar-content': {
              display: 'flex',
              flexDirection: 'column'
            }
          }}
        >
          <Box sx={{ p: { xs: 3, sm: 5 } }}>
            {loading && jd && job && Object.keys(job).length !== 0 ? (
              <PreviewPopup jd={jd} job={job} matches={!matches && match ? [match] : matches} closeModal={closeModal} />
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <CircularProgress />
              </Box>
            )}
          </Box>
        </SimpleBar>
      </MainCard>
    </Modal>
  );
};

export default JDPreviewPopup;
