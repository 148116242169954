// types
import { createSlice } from '@reduxjs/toolkit';
import {
  CVBuilderProps,
  CVEditCalculateProps,
  CVEditExperienceProps,
  CVEditJobProps,
  CVEditProfileProps,
  CVEditQualificationProps,
  CVEditSkillsProps,
  CVProfileProps
} from 'types/cv-builder';

// project imports
import { Axios } from 'utils/axios';
import { dispatch } from 'store';
import { BE_URL } from 'config';

const initialState: CVBuilderProps = {
  error: null,
  uploadedCVFile: null,
  step: 0,
  status: '',
  calculatedRate: {
    lowest: 0,
    highest: 0,
    recommended: 0
  },
  id: '',
  profile: {
    avatar: null,
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    jobCompany: '',
    biography: ''
  },
  jobInfo: {
    capacity: '',
    workLocation: '',
    location: '',
    startDate: '',
    endDate: '',
    jobTags: []
  },
  skills: {
    essentialTags: [],
    desirableTags: [],
    culturalTags: []
  },
  experiences: [],
  qualifications: [],
  calculate: {
    rate: 0,
    timeSlot: 'Daily'
  }
};

// ==============================|| SLICE - CV BUILDER ||============================== //

const cv = createSlice({
  name: 'cv-builder',
  initialState,
  reducers: {
    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    // upload cv automatically
    uploadCV(state, action) {
      state.uploadedCVFile = action.payload;
    },

    updateStep(state, action) {
      state.step = action.payload.step;
    },

    setProfile(state, action) {
      const data = action.payload.profile;
      const profile = {
        avatar: data.image ? { name: `${data.firstName}-${data.lastName}`, url: data.image } : null,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        jobTitle: data.jobTitle,
        jobCompany: data.jobCompany,
        biography: data.biography
      };
      state.profile = profile;
      state.id = action.payload.id;
    },

    setJobInfo(state, action) {
      const data = action.payload.jobInfo;
      const jobInfo = {
        capacity: data.capacity,
        workLocation: data.workLocation,
        location: data.location,
        startDate: data.startDate,
        endDate: data.endDate,
        jobTags: data.jobTags
      };
      state.jobInfo = jobInfo;
    },

    setSkills(state, action) {
      const data = action.payload.skills;
      const skills = {
        essentialTags: data.essentialTags,
        desirableTags: data.desirableTags,
        culturalTags: data.culturalTags
      };
      state.skills = skills;
    },

    setExperiences(state, action) {
      if (state.experiences.length === 0) {
        state.experiences = [action.payload];
      } else {
        if (state.experiences.filter((item) => item.id === action.payload.id).length > 0) {
          state.experiences = state.experiences.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.experiences = [...state.experiences, action.payload];
        }
      }
    },

    deleteExperience(state, action) {
      state.experiences = state.experiences.filter((item) => item.id !== action.payload);
    },

    setQualifications(state, action) {
      if (state.qualifications.length === 0) {
        state.qualifications = [action.payload];
      } else {
        if (state.qualifications.filter((item) => item.id === action.payload.id).length > 0) {
          state.qualifications = state.qualifications.map((item) => (item.id === action.payload.id ? action.payload : item));
        } else {
          state.qualifications = [...state.qualifications, action.payload];
        }
      }
    },

    deleteQualification(state, action) {
      state.qualifications = state.qualifications.filter((item) => item.id !== action.payload);
    },

    setCalulate(state, action) {
      const calculateData = action.payload;
      const calculate = {
        rate: calculateData.rate,
        timeSlot: calculateData.timeSlot
      };
      state.calculate = calculate;
    },

    setCalculatedRate(state, action) {
      state.calculatedRate = action.payload;
    },

    resetCalculatedRate(state) {
      state.calculatedRate = initialState.calculatedRate;
    },

    // upload jd automatically
    uploadJD(state, action) {
      state.uploadedCVFile = action.payload;
    },

    setStore(state, action) {
      state.id = action.payload.id;
      state.status = action.payload.status;
      const dataProfile = action.payload;
      const profile = {
        avatar: dataProfile.image ? { name: 'name', url: dataProfile.image } : null,
        firstName: dataProfile.firstName,
        lastName: dataProfile.lastName,
        email: dataProfile.email,
        jobTitle: dataProfile.jobTitle,
        jobCompany: dataProfile.jobCompany,
        biography: dataProfile.biography
      };
      state.profile = profile;
      const jobInfoData = action.payload;
      const jobInfo = {
        capacity: jobInfoData.capacity ? jobInfoData.capacity : state.jobInfo.capacity,
        workLocation: jobInfoData.workLocation ? jobInfoData.workLocation : state.jobInfo.workLocation,
        location: jobInfoData.location ? jobInfoData.location : state.jobInfo.location,
        startDate: jobInfoData.startDate ? jobInfoData.startDate : state.jobInfo.startDate,
        endDate: jobInfoData.endDate ? jobInfoData.endDate : state.jobInfo.endDate,
        jobTags: jobInfoData.jobTags ? jobInfoData.jobTags : []
      };
      state.jobInfo = jobInfo;
      const skillsData = action.payload;
      const skills = {
        essentialTags: skillsData.essentialTags ? skillsData.essentialTags : [],
        desirableTags: skillsData.desirableTags ? skillsData.desirableTags : [],
        culturalTags: skillsData.culturalTags ? skillsData.culturalTags : []
      };
      state.skills = skills;
      state.experiences = action.payload.experiences;
      state.qualifications = action.payload.qualifications;
      const calculateData = action.payload;
      const calculate = {
        rate: calculateData.rate ? calculateData.rate : state.calculate.rate,
        timeSlot: calculateData.timeSlot ? calculateData.timeSlot : state.calculate.timeSlot
      };
      state.calculate = calculate;
    },

    resetStore(state) {
      state.error = initialState.error;
      state.step = initialState.step;
      state.id = initialState.id;
      state.profile = initialState.profile;
      state.jobInfo = initialState.jobInfo;
      state.skills = initialState.skills;
      state.experiences = initialState.experiences;
      state.qualifications = initialState.qualifications;
      state.calculate = initialState.calculate;
    }
  }
});

export default cv.reducer;

export const { updateStep, resetStore, resetCalculatedRate } = cv.actions;

export function setStore(id: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/cv/${id}`);
      dispatch(cv.actions.setStore(response.data.data));
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}

export function uploadCV(file: any) {
  return async () => {
    try {
      const data = new FormData();
      data.append('file', file);
      const response = await Axios.post(`${BE_URL}/upload?type=CV`, data);
      dispatch(cv.actions.uploadJD(response.data.data));
      return response.data.success;
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}

export function addProfile(profile: CVProfileProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/cv`, profile);
      dispatch(cv.actions.setProfile({ profile: response.data.data, id: response.data.data.id }));
      return response.data.success;
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}

export function editProfile(profileData: CVEditProfileProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/cv`, { ...profileData.profile, id: profileData.id });
      dispatch(cv.actions.setProfile({ profile: response.data.data, id: response.data.data.id }));
      return response.data.success;
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}

export function editJobInfo(jobData: CVEditJobProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/cv`, { ...jobData.jobInfo, id: jobData.id });
      dispatch(cv.actions.setJobInfo({ jobInfo: response.data.data }));
      return response.data.success;
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}

export function editSkills(skillsData: CVEditSkillsProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/cv`, { ...skillsData.skills, id: skillsData.id });
      dispatch(cv.actions.setSkills({ skills: response.data.data }));
      return response.data.success;
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}

export function editExperience(experience: CVEditExperienceProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/cv/${experience.id}/experience`, { ...experience.experience });
      dispatch(cv.actions.setExperiences(response.data.data));
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}

export function deleteExperience(id: string) {
  return async () => {
    try {
      await Axios.post(`${BE_URL}/cv/experience/delete`, { id });
      dispatch(cv.actions.deleteExperience(id));
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}

export function editQualification(qualification: CVEditQualificationProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/cv/${qualification.id}/qualification`, { ...qualification.qualification });
      dispatch(cv.actions.setQualifications(response.data.data));
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}

export function deleteQualification(id: string) {
  return async () => {
    try {
      await Axios.post(`${BE_URL}/cv/qualification/delete`, { id });
      dispatch(cv.actions.deleteQualification(id));
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}
export function getCalculateRate(id: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/cv/${id}/calculate`);
      dispatch(cv.actions.setCalculatedRate(response.data.data));
    } catch (error: any) {
      dispatch(cv.actions.setCalculatedRate({ lowest: 'N/A', highest: 'N/A', recommended: 'N/A' }));
    }
  };
}

export function editCalulate(calculate: CVEditCalculateProps) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/cv`, { ...calculate.calculate, id: calculate.id });
      dispatch(cv.actions.setCalulate(response.data.data));
      return response.data.success;
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}

export function changeStatus(status: string, id: string) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/cv`, { status, id });
      dispatch(cv.actions.setStore(response.data.data));
      return response.data.success;
    } catch (error) {
      dispatch(cv.actions.hasError(error));
    }
  };
}
