import { JDProfileProps, JDCalculateProps, JDJobProps, JDSkillsProps } from './../types/jd-builder';
import * as yup from 'yup';

// @ts-ignore
const diffDays = (date: Date, otherDate) => Math.ceil(Math.abs(date - otherDate) / (1000 * 60 * 60 * 24));

const profileSchema = yup.object({
  experience: yup.string().trim().required('Experience level is required'),
  jobTitle: yup.string().trim().required('Job Title is required').max(50, 'Job Title must be at most 50 characters'),

  jobCompany: yup
    .string()
    .trim()
    .matches(/^[ A-Za-zäöüÄÖÜß0-9_'"/)(?&-.]*$/, `Only alphanumeric are allowed with special characters (_'"/)(?&-.) and letters (ÄäÖöÜüß)`)
    .max(50, 'Company Name must be at most 50 characters')
    .required('Company Name is required'),
  jobDescription: yup
    .string()
    .trim()
    .min(50, 'Job Description must be at least 50 characters')
    .max(1500, 'Job Description must be at most 500 characters')
    .required('Job Description is required'),
  companyDescription: yup.string().trim().max(1500, 'Company Description must be at most 500 characters')
});

const jobInfoSchema = yup.object({
  capacity: yup.string().trim().required('Capacity is required'),
  workLocation: yup.string().trim().required('Work location is required'),
  location: yup
    .string()
    .trim()
    .required('Search location is required')
    .matches(/^[a-z\d\-_\s]+$/i, 'Only alphanumerics are allowed')
    .max(50, 'Search location must be at most 50 characters'),
  endDate: yup
    .date()
    .when('startDate', (start, schema) => start && schema.min(start, 'End date must be later than start date'))
    .test(
      'endDate',
      'End date must be at least 2 weeks after staring date',
      (started, yup) => diffDays(yup.parent.startDate, started) > 13
    ),
  startDate: yup.date().required('Start date is required'),
  jobTags: yup
    .array()
    .of(yup.string().trim().required('Leading spaces found in your tag').max(50, 'Job tag field must be at most 50 characters'))
    .required('Job tag selection is required')
    .min(3, 'Job tags field must have at least 3 items')
});

const skillSchema = yup.object({
  essentialTags: yup
    .array()
    .of(yup.string().trim().required('Leading spaces found in your tag').max(50, 'Essential tag field must be at most 50 characters'))
    .required('Essential tag selection is required')
    .min(3, 'Essential tags field must have at least 3 items'),
  culturalTags: yup
    .array()
    .of(yup.string().trim().required('Leading spaces found in your tag').max(50, 'Cultural preference field must be at most 50 characters'))
    .required('Cultural preference selection is required')
    .min(3, 'Cultural preference field must have at least 3 items')
});

const rateSchema = yup.object({
  rate: yup
    .number()
    .required('Offered rate is required')
    .typeError('Offered rate must specify a numeric value.')
    // @ts-ignore
    .test('rate', 'Offered rate field must have 2 digits after decimal or less', (number) => /^\d+(\.\d{1,2})?$/.test(number))
    .when('timeSlot', (timeSlot, schema) => {
      let minVal = 100;
      let maxVal = 7500;

      if (timeSlot === 'Hourly') {
        minVal = 12.5;
        maxVal = 667;
      }

      if (timeSlot === 'Monthly') {
        minVal = 2170;
        maxVal = 160000;
      }

      return schema.min(minVal, `${timeSlot} offer rate must be > ${minVal}`).max(maxVal, `${timeSlot} offer rate must be < ${maxVal}`);
    }),
  timeSlot: yup.string().trim().required('Slot is required')
});

export const validateJD = async (profile: JDProfileProps, calculate: JDCalculateProps, jobInfo: JDJobProps, skills: JDSkillsProps) => {
  const validProfile = await profileSchema.isValid(profile);
  const validJobInfo = await jobInfoSchema.isValid(jobInfo);
  const validSkills = await skillSchema.isValid(skills);
  const validRate = await rateSchema.isValid(calculate);

  return validProfile && validJobInfo && validSkills && validRate;
};
