import { ButtonProps } from '@mui/material';

export enum DocumentType {
  contracts = 'CONTRACTS',
  cv = 'CV',
  jd = 'JD'
}

export type DocumentAction = {
  title: string;
  button: ButtonProps;
  action?: string | Function;
};

// emplyee details list
export type DocumentProps = {
  id: string;
  type: DocumentType;
  title: string;
  createdAt: string;
};

export interface DocumentsStateProps {
  error: object | string | null;
  documents: DocumentProps[];
}
