// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { Axios } from 'utils/axios';
import { dispatch } from '../index';

//types
import { UserProps, UserData, UserDataCompany } from 'types/waiting-list';
import { BE_URL } from 'config';
import { openSnackbar } from './snackbar';

const initialState: UserProps = {
  user: null,
  error: null
};

// ==============================|| SLICE - waiting-list ||============================== //

const slice = createSlice({
  name: 'waitingList',
  initialState,
  reducers: {
    // has error
    hasError(state, action) {
      state.error = action.payload;
    },

    setUser(state, action) {
      const user = action.payload;
      state.user = user;
    }
  }
});

// reducer
export default slice.reducer;

export function createWaitingListUser(userData: UserData) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/users/waiting-list/?signup=1`, userData);
      dispatch(slice.actions.hasError(null));
      return response.data.success;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      const errResponse = error && error.response;
      if (!errResponse) {
        errResponse.data.error = 'Unexpected error';
      }
      dispatch(
        openSnackbar({
          open: true,
          message: errResponse.data.error,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
    }
  };
}

export function createWaitingListUserCompany(companyData: UserDataCompany) {
  return async () => {
    try {
      if (companyData?.isConfirmTCPP) {
        delete companyData.isConfirmTCPP;
      }
      const response = await Axios.post(`${BE_URL}/users/company/waiting-list/?signup=1`, companyData);
      dispatch(slice.actions.hasError(null));
      return response.data.success;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      const errResponse = error && error.response;
      if (!errResponse) {
        errResponse.data.error = 'Unexpected error';
      }
      dispatch(
        openSnackbar({
          open: true,
          message: errResponse.data.error,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
    }
  };
}

export function inviteNewUser(emails: string[], name: string) {
  return async () => {
    try {
      const response = await Axios.post(`${BE_URL}/users/waiting-list/invite/?signup=1`, { emails, name });
      dispatch(slice.actions.hasError(null));
      return response.data.success;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      const errResponse = error && error.response;
      if (!errResponse) {
        errResponse.data.error = 'Unexpected error';
      }
      dispatch(
        openSnackbar({
          open: true,
          message: errResponse.data.error,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
    }
  };
}

export function checkUserEmail(email: string) {
  return async () => {
    try {
      const response = await Axios.get(`${BE_URL}/users/waiting-list/${email}/?signup=1`);
      dispatch(slice.actions.hasError(null));
      return response.data.data;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      const errResponse = error && error.response;
      if (!errResponse) {
        errResponse.data.error = 'Unexpected error';
      }
      dispatch(
        openSnackbar({
          open: true,
          message: errResponse.data.error,
          variant: 'alert',
          alert: {
            color: 'error'
          },
          close: false
        })
      );
    }
  };
}
